import axios from 'axios'

class AjaxRequest {
  constructor () {
    this.baseURL = ''
    this.timeout = 3000
  }

  merge (options) {
    return { ...options, baseURL: this.baseURL, timeout: this.timeout }
  }

  setInterceptor (instance) {
    instance.interceptors.request.use((config) => {
      config.headers.timestamp = parseInt(new Date().getTime() / 1000)
      config.headers.token = localStorage.getItem('express_token') || ''
      return config
    })
    instance.interceptors.response.use((res) => {
      return res.data
    })
  }

  request (options) {
    const instance = axios.create()
    this.setInterceptor(instance)
    const config = this.merge(options)
    return instance(config)
  }
}

export default new AjaxRequest()
