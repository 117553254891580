import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import animated from 'animate.css'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import '../theme/index.css'
import '@/assets/icons'
import 'swiper/js/swiper.js'
import 'swiper/css/swiper.css'
import lottie from 'vue-lottie'
Vue.component('lottie', lottie)
Vue.config.productionTip = false
Vue.use(Element)
Vue.use(animated)
Vue.use(VueAnimateOnScroll)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
