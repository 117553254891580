<template>
  <div class="success-case">
    <swiper class="swiper" :options="swiperOption" ref="mySwiper" @transitionStart="onTransitionEnd">
      <swiper-slide v-for="(item, index) in successCaseList" :key="index">
        <div class="vedio-item" v-if="index === 0" :style="{ backgroundImage: 'url(' + item.bg + ')'}">
          <div class="video-container">
            <div :style="fixStyle" class="filter">
              <div class="success-title">成功案例</div>
              <div class="success-desc">优化运力成本，为客户提供快速、便捷及时、安全可靠的服务</div>
              <div class="play-btn">
                <img :src="btnIcon" alt="" @click="playToggle">
                <span>{{btnText}}</span>
              </div>
            </div>
            <video :style="fixStyle" loop muted class="fillWidth" id="videoPlayer">
              <!-- <source src="https://media.w3.org/2010/05/sintel/trailer.mp4" type="video/mp4"/> -->
              <source src="../../assets/images/successCase/video.mp4" type="video/mp4"/>
            </video>
          </div>
        </div>
        <div class="case-item text-intro" v-else :style="{ backgroundImage: 'url(' + item.bg + ')'}">
          <div class="case-intro" :class="{'fadeIn': activeIdx === index}">
            <div class="text-wrap" :class="{'fadeInText': activeIdx === index}">
              <div class="intro-title">{{item.title}}</div>
              <div class="intro-desc">{{item.desc}}</div>
              <div class="intro-detail" v-html="item.detail"></div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import caseBg1 from '@/assets/images/successCase/case_bg1.jpg'
import caseBg2 from '@/assets/images/successCase/case_bg2.jpg'
import caseBg3 from '@/assets/images/successCase/case_bg3.jpg'
import caseBg4 from '@/assets/images/successCase/case_bg4.jpg'
import playIcon from '@/assets/images/successCase/play_icon.png'
import pauseIcon from '@/assets/images/successCase/pause_icon.png'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'successCase',
  components: {
    swiper: Swiper,
    swiperSlide: SwiperSlide
  },
  data () {
    return {
      successCaseList: [
        { title: '', desc: '', detail: '', bg: caseBg1 },
        { title: '山东省共配网点', desc: '多家物流资源整合，合作共创', detail: '<p>偏远乡镇针对多家快递网点进行整合，抱团发展。几家快递网点整合，建立了一家物流共配中心，共同探索解决末端配送问题的新路径，有效完善了乡镇地域快递末端网络布局。<br/>农村物流想要获得发展，就得充分发挥资源优势进行高效的整合。共配可以改善以往资源松散，不集中等问题，让快递末端的资源由松散走向规模化和集约化。</p>', bg: caseBg2 },
        { title: '陕西省共配网点', desc: '企业仓、运、转、配全链协同的资源共享', detail: '通过共配，区域内的几家快递网点统一共享了从仓库、转运、支线运输、末端配送全链路的资源，并将各个快递网点的快递资源联合在一起进行优化，在件量不断攀升的情况下，车辆和人员反而有了相对的减少，大大降低了网点的经营成本。', bg: caseBg3 },
        { title: '湖北省共配网点', desc: '网点件量大幅度提高，日均派送量飙升', detail: '由于共配体系的搭建，之前多个快递员才可以完成的工作，现在少数的人力可以集中进行配送，大幅提高了派件效率和人均派件量。在时效内满足区域快件派送的前提下，也大幅减少了人力成本，仓储成本。通过共配，末端网点切实感受到了配送的高效，用户们也能明显感觉到服务质量得到了提升。', bg: caseBg4 }
      ],
      swiperOption: {
        direction: 'vertical',
        mousewheel: true
        // grabCursor: true
      },
      vedioCanPlay: false,
      fixStyle: '',
      videoPlayer: '',
      btnIcon: '',
      btnText: '',
      pageSwiper: '',
      activeIdx: 0
    }
  },
  methods: {
    playToggle () {
      if (this.vedioCanPlay) {
        this.videoPlayer.pause()
        this.vedioCanPlay = false
      } else {
        this.videoPlayer.play()
        this.vedioCanPlay = true
      }
    },
    onTransitionEnd () {
      this.activeIdx = this.pageSwiper.activeIndex
    }
  },
  watch: {
    vedioCanPlay: {
      handler (val) {
        if (val) {
          this.btnIcon = pauseIcon
          this.btnText = '点此暂停'
        } else {
          this.btnIcon = playIcon
          this.btnText = '点此播放'
        }
      },
      immediate: true
    }
  },
  created () {
    this.$emit('hideFooter', true)
  },
  beforeRouteLeave (to, from, next) {
    this.$emit('hideFooter', false)
    next()
  },
  mounted () {
    this.videoPlayer = document.getElementById('videoPlayer')
    this.pageSwiper = this.$refs.mySwiper.$swiper
    window.onresize = () => {
      const windowWidth = document.body.clientWidth
      const windowHeight = document.body.clientHeight
      const windowAspectRatio = windowHeight / windowWidth
      let videoWidth
      let videoHeight
      if (windowAspectRatio < 0.5625) {
        videoWidth = windowWidth
        videoHeight = videoWidth * 0.5625
        this.fixStyle = {
          height: windowWidth * 0.5625 + 'px',
          width: windowWidth + 'px',
          'margin-bottom': (windowHeight - videoHeight) / 2 + 'px',
          'margin-left': 'initial'
        }
      } else {
        videoHeight = windowHeight
        videoWidth = videoHeight / 0.5625
        this.fixStyle = {
          height: windowHeight + 'px',
          width: windowHeight / 0.5625 + 'px',
          'margin-left': (windowWidth - videoWidth) / 2 + 'px',
          'margin-bottom': 'initial'
        }
      }
    }
    window.onresize()
  }
}
</script>

<style lang="scss" scoped>
  @keyframes fadeIn {
    0% { opacity: 0;}
    100% { opacity: 1;}
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .success-case {
    text-align: left;
    box-sizing: border-box;
    .swiper {
      height: calc(100vh - 74px);
      .swiper-slide {
        .case-item {
          height: 100%;
          background-position: center top;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .vedio-item {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          background-position: center top;
          background-repeat: no-repeat;
          background-size: cover;
          .video-container {
            position: relative;
            height: 100%;
            overflow: hidden;
            .filter {
              z-index: 1;
              position: absolute;
              background: rgba(0, 0, 0, 0.4);
              width: 100%;
              padding: 292px 0 0 400px;
              .success-title {
                font-size: 80px;
                font-family: 'YouSheBiaoTiHei';
                color: #FFFFFF;
                line-height: 104px;
                letter-spacing: 26px;
              }
              .success-desc {
                margin-top: 30px;
                height: 37px;
                font-size: 26px;
                font-family: 'PingFangSC-Semibold, PingFang SC';
                font-weight: 600;
                color: #FFFFFF;
                line-height: 37px;
                letter-spacing: 6px;
              }
              .play-btn {
                margin-top: 60px;
                height: 72px;
                line-height: 72px;
                img {
                  width: 72px;
                  cursor: pointer;
                }
                span {
                  padding-left: 16px;
                  font-size: 20px;
                  font-family: 'PingFangSC-Semibold, PingFang SC';
                  font-weight: 600;
                  color: #FFFFFF;
                  letter-spacing: 4px;
                }
              }
            }
            .fillWidth {
              width: 100%;
            }
          }
        }
        .text-intro {
          display: flex;
          justify-content: center;
          align-items: center;
          .case-intro {
            width: 1200px;
            padding: 72px 105px 72px 120px;
            background: rgba(0, 99, 255, 0.6);
            opacity: 0;
            &.fadeIn {
              opacity: 1;
              animation: fadeIn 0.8s;
            }
            .text-wrap {
              &.fadeInText {
                animation: fadeIn 0.8s ease-in 0.1s;
                animation-fill-mode: both;
                -webkit-animation-fill-mode: both;
              }
            }
            .intro-title {
              padding-left: 37px;
              height: 37px;
              font-size: 26px;
              font-family: 'PingFangSC-Medium, PingFang SC';
              font-weight: 500;
              color: #FFFFFF;
              line-height: 37px;
              letter-spacing: 2px;
              background: url(../../assets/images/successCase/title_icon.png) left center no-repeat;
              background-size: 28px 18px;
            }
            .intro-desc {
              margin-top: 30px;
              height: 62px;
              font-size: 44px;
              font-family: 'PingFangSC-Semibold, PingFang SC';
              font-weight: 600;
              color: #FFFFFF;
              line-height: 62px;
              letter-spacing: 10px;
            }
            .intro-detail {
              margin-top: 44px;
              font-size: 20px;
              font-family: 'PingFangSC-Regular, PingFang SC';
              font-weight: 400;
              color: #FFFFFF;
              line-height: 36px;
              letter-spacing: 2px;
            }
          }
        }
      }
    }
  }
</style>
