<template>
<!-- 地址识别 -->
<div class="addressRecognition">
  <el-dialog title="智能填写" :visible.sync="dialogFormVisible" width="40%"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
            @close="closeDialog">
    <div>
      <el-input
        type="textarea"
        :rows="12"
        placeholder="粘贴或输入整段姓名、电话和地址，自动识别"
        :autosize="false"
        resize="none"
        maxlength="100"
        v-model="address">
      </el-input>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="resolveAddress">识 别</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { queryAddressResolve } from '@/assets/js/api'

export default {
  name: 'addressRecognition',
  props: {
    addressType: String,
    default: () => {
      return 'send'
    }
  },
  data () {
    return {
      dialogFormVisible: true,
      address: ''
    }
  },
  methods: {
    resolveAddress () {
      if (this.address === '') {
        this.$message.warning('空文本无法解析')
        return false
      }
      const params = {
        address: this.address.replace(/(^\s*)|(\s*$)/g, '')
      }
      queryAddressResolve(params).then(res => {
        if (res.code === 200) {
          this.$emit('resolveResult', 'success', res.data)
        } else {
          if (res.code === 4003) {
            setTimeout(() => {
              this.$emit('resolveResult', 'loginError')
            }, 1500)
          }
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    },
    closeDialog () {
      this.$emit('closeResolve')
    }
  }
}
</script>

<style scoped lang="scss">
.addressRecognition {

}
</style>
