<template>
  <div id="block">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.pageNo"
      :page-sizes="pagination.sizes"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'tablePagination',
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          pageNo: 1,
          pageSize: 15,
          sizes: [15, 30, 45, 60]
        }
      }
    },
    total: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleSizeChange (val) {
      this.pagination.pageNo = 1
      this.pagination.pageSize = val
      this.$emit('currentSizeChange', this.pagination)
    },
    handleCurrentChange (val) {
      this.pagination.pageNo = val
      this.$emit('currentPagination', this.pagination)
    },
    getData () {
      this.$emit('getData', this.pagination)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="scss">
  #block {
    .el-pagination {
      color: #A7A6AF;
      font-size: 14px;
      font-weight: normal;
      text-align: right;
      margin-top: 20px;
      padding-bottom: 20px;
    }
    .el-pagination__jump { color: #A7A6AF; }
  }
</style>
