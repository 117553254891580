<template>
  <div class="sendBulky">
    <div class="send-section">
      <div class="title-box">
        <img src="../../assets/images/send/icon_send.png" alt="send">
        <p class="m-l-10">寄件人</p>
      </div>
      <el-form label-position="right" :label-width="labelWidth" ref="sendInfoForm" :model="sendManInfo" :rules="sendManInfoRules"
               @submit.native.prevent>
        <el-form-item label="姓名" prop="sendManName">
          <div class="intelligent">
            <el-input v-model="sendManInfo.sendManName" maxlength="10" placeholder="请输入"></el-input>
            <el-button class="m-l-10" type="primary" size="small" @click="handleAddressDialog('send')">智能填写</el-button>
          </div>
        </el-form-item>
        <el-form-item label="手机号" prop="sendManMobile">
          <el-input v-model="sendManInfo.sendManMobile" maxlength="11" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="省市区" prop="sendAreaCodeList">
          <el-cascader
            ref="cascaderSend"
            v-model="sendManInfo.sendAreaCodeList"
            :options="areaData"
            :key="sendManInfo.sendModeKey"
            :props="{ expandTrigger: 'hover' }"
            @change="(value) => selectAreaChange('send', value)"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="sendManAddr">
          <el-input v-model="sendManInfo.sendManAddr" maxlength="50" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="send-section">
      <div class="title-box">
        <img src="../../assets/images/send/icon_receive.png" alt="receive">
        <p class="m-l-10">收件人</p>
      </div>
      <el-form label-position="right" :label-width="labelWidth" ref="recInfoForm" :model="recManInfo" :rules="recManInfoRules"
               @submit.native.prevent>
        <el-form-item label="姓名" prop="recManName">
          <div class="intelligent">
            <el-input v-model="recManInfo.recManName" maxlength="10" placeholder="请输入"></el-input>
            <el-button class="m-l-10" type="primary" size="small" @click="handleAddressDialog('receive')">智能填写</el-button>
          </div>
        </el-form-item>
        <el-form-item label="手机号" prop="recManMobile">
          <el-input v-model="recManInfo.recManMobile" maxlength="11" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="省市区" prop="recAreaCodeList">
          <el-cascader
            v-model="recManInfo.recAreaCodeList"
            :options="areaData"
            :key="recManInfo.recModeKey"
            :props="{ expandTrigger: 'hover' }"
            @change="(value) => selectAreaChange('receive', value)"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="recManAddr">
          <el-input v-model="recManInfo.recManAddr" maxlength="50" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="goods-section">
      <p class="title">物品信息</p>
      <el-form label-position="right" label-width="0px" :model="goodsInfo" ref="goodsInfoForm" :rules="goodsInfoRules"
               @submit.native.prevent>
        <el-form-item label="" prop="cargoId">
          <el-radio-group v-model="goodsInfo.cargoId" >
            <el-radio :label="item.id" v-for="item in goodsTypeList" :key="item.id">{{item.goods}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="">
          <div class="weight">预估重量（kg）</div>
          <div class="weight-tip">重量以快递员称重为准</div>
          <div>
            <el-slider
              v-model="goodsInfo.weight"
              :min="20"
              :max="50"
              show-input>
            </el-slider>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="goods-section">
      <p class="title">快递公司</p>
      <div class="express-box">
        <div class="express-item" v-for="(item, index) in expressList" :key="index"
             @click="chooseExpress(index)">
          <div class="icon-choose" :class="{'active': item.checked}"></div>
          <img :src="item.logoUrl" alt="logo">
          <div class="price">{{item.totalfee + '元'}}</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="protocol">
        <el-checkbox v-model="isAgree"></el-checkbox>
        <span>我已阅读同意</span>
        <span class="content">《快件物流资源共享服务协议》</span>
      </div>
      <div class="m-t-40">
        <el-button round type="primary" @click="submitOrder">下单</el-button>
      </div>
    </div>
    <!-- 登录组件 -->
    <login v-if="showLogin" @loginResult="loginResult"></login>
    <!-- 只能识别 -->
    <address-recognition v-if="showAddressRecognition" :addressType="addressType" @resolveResult="resolveResult" @closeResolve="closeResolve"></address-recognition>
  </div>
</template>

<script>

import { getAreaString, queryExpressCompany, queryGoodsType, queryOrderExpress } from '@/assets/js/api.js'
import login from '../../components/login.vue'
import addressRecognition from '@/components/addressRecognition.vue'

const expressImgObj = {
  debangwuliu: require('../../assets/images/send/logo_debangwuliu.png'),
  ems: require('../../assets/images/send/logo_ems.png'),
  huitongkuaidi: require('../../assets/images/send/logo_huitongkuaidi.png'),
  jd: require('../../assets/images/send/logo_jd.png'),
  shentong: require('../../assets/images/send/logo_shentong.png'),
  shunfeng: require('../../assets/images/send/logo_shunfeng.png'),
  tiantian: require('../../assets/images/send/logo_tiantian.png'),
  yuantong: require('../../assets/images/send/logo_yuantong.png'),
  yunda: require('../../assets/images/send/logo_yunda.png'),
  zhongtong: require('../../assets/images/send/logo_zhongtong.png')
}
const EXPRESSTYPE = 1

export default {
  name: 'sendBulky',
  components: {
    addressRecognition,
    login
  },
  data () {
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      const regPhone = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      if (!regPhone.test(value)) {
        return callback(new Error('请输入正确格式的手机号'))
      }
      callback()
    }
    return {
      showLogin: false,
      labelWidth: '120px',
      isAuth: false,
      areaData: [],
      sendManInfo: {
        sendManName: '', // 寄件人姓名
        sendManMobile: '', // 寄件人手机号
        sendManProvince: '', // 寄件人所在省
        sendManCity: '', // 寄件人所在市
        sendManDistrict: '', // 寄件人所在区
        sendManAddr: '', // 寄件人详细地址
        sendAreaCodeList: null,
        sendModeKey: 0
      },
      sendManInfoRules: {
        sendManName: [
          { required: true, message: '请输入寄件人姓名', trigger: 'blur' }
        ],
        sendManMobile: [
          { required: true, message: '请输入寄件人手机号', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        sendAreaCodeList: [
          { required: true, message: '请选择省市区', trigger: 'change' }
        ],
        sendManAddr: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ]
      },
      recManInfo: {
        recManName: '', // 收件人姓名
        recManMobile: '', // 收件人手机号
        recManProvince: '', // 收件人所在省
        recManCity: '', // 收件人所在市
        recManDistrict: '', // 收件人所在区
        recManAddr: '', // 收件人详细地址
        recAreaCodeList: null,
        recModeKey: 0
      },
      recManInfoRules: {
        recManName: [
          { required: true, message: '请输入寄件人姓名', trigger: 'blur' }
        ],
        recManMobile: [
          { required: true, message: '请输入寄件人手机号', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        recAreaCodeList: [
          { required: true, message: '请选择省市区', trigger: 'change' }
        ],
        recManAddr: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ]
      },
      showAddressRecognition: false,
      addressType: '',
      goodsInfo: {
        cargo: '',
        cargoId: null,
        weight: 20
      },
      goodsInfoRules: {
        cargoId: [
          { required: true, message: '请选择物品类型', trigger: 'change' }
        ]
      },
      goodsTypeList: [],
      expressList: [],
      expressInfo: {
        com: '',
        comName: ''
      },
      dayType: '',
      isAgree: false,
      validObj: {
        sendInfoForm: false,
        recInfoForm: false,
        goodsInfoForm: false
      }
    }
  },
  created () {
    if (!localStorage.getItem('express_token')) {
      this.showLogin = true
    }
  },
  mounted () {
    this.isAuth = localStorage.getItem('express_isAuth') ? localStorage.getItem('express_isAuth') : false
    this.getAreaList()
    this.getGoodsType()
  },
  methods: {
    // 登录
    loginResult (result) {
      if ((result === 'success') || (result === 'cancel')) {
        this.showLogin = false
        this.$emit('needAuth')
        if (localStorage.getItem('express_isAuth') !== '' || localStorage.getItem('express_isAuth') !== null) {
          this.isAuth = Number(localStorage.getItem('express_isAuth'))
        }
      }
    },
    // 获取地址json
    getAreaList () {
      this.loading = true
      getAreaString().then(res => {
        if (res.code === 200) {
          this.formatAreaList(res.data)
        } else {
          if (res.code === 4003) {
            this.showLogin = true
          }
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    },
    formatAreaList (data) {
      if (!data.province_list) {
        return
      }
      this.areaData = this.formatAreaUtil(data.province_list)
      this.areaData.forEach(e => {
        const provinceStr = e.value.substr(0, 2)
        e.children = this.formatAreaUtil(data.city_list, provinceStr, 2)
        if (e.children.length > 0) {
          e.children.forEach(city => {
            const cityStr = city.value.substr(0, 4)
            city.children = this.formatAreaUtil(data.county_list, cityStr, 4)
          })
        }
      })
    },
    formatAreaUtil (data, str, count) {
      const list = []
      for (const key in data) {
        if (str) {
          if (key.substr(0, count) === str) {
            const areaObj = {
              label: data[key],
              value: key
            }
            if (count === 2) { // 城市
              areaObj.children = []
            }
            list.push(areaObj)
          }
        } else {
          const areaObj = {
            label: data[key],
            value: key,
            children: []
          }
          list.push(areaObj)
        }
      }
      return list
    },
    selectAreaChange (type, value) {
      if (value && value.length > 0) {
        if (type === 'send') {
          this.sendManInfo.sendModeKey++
          this.sendManInfo.sendAreaCodeList = value
          this.sendManInfo.sendManProvince = this.getAreaName(value).provinceName
          this.sendManInfo.sendManCity = this.getAreaName(value).cityName
          this.sendManInfo.sendManDistrict = this.getAreaName(value).districtName
        } else if (type === 'receive') {
          this.recManInfo.recModeKey++
          this.recManInfo.recAreaCodeList = value
          this.recManInfo.recManProvince = this.getAreaName(value).provinceName
          this.recManInfo.recManCity = this.getAreaName(value).cityName
          this.recManInfo.recManDistrict = this.getAreaName(value).districtName
        }
      }
    },
    // 获取省市区名称
    getAreaName (code) {
      const nameObj = {
        provinceName: '',
        cityName: '',
        districtName: ''
      }
      const cityData = this.findName(this.areaData, code[0])[0].children
      const districtData = this.findName(cityData, code[1])[0].children
      nameObj.provinceName = this.findName(this.areaData, code[0])[0].label
      nameObj.cityName = this.findName(cityData, code[1])[0].label
      nameObj.districtName = this.findName(districtData, code[2])[0].label
      return nameObj
    },
    findName (data, code) {
      return data.filter(item => {
        if (item.value === code) {
          return item
        }
      })
    },
    handleAddressDialog (type) {
      this.showAddressRecognition = true
      this.addressType = type
    },
    // 地址解析结果
    resolveResult (status, res) {
      if (status === 'loginError') {
        this.showLogin = true
      } else if (status === 'success') {
        if (this.addressType === 'send') {
          this.sendManInfo.sendModeKey++
          this.sendManInfo.sendManName = res.name
          this.sendManInfo.sendManMobile = res.phone
          this.sendManInfo.sendManProvince = res.province
          this.sendManInfo.sendManCity = res.city
          this.sendManInfo.sendManDistrict = res.county
          this.sendManInfo.sendManAddr = res.detail
          this.sendManInfo.sendAreaCodeList = [res.provinceCode, res.cityCode, res.countyCode]
          this.clearValidate('sendInfoForm')
        } else {
          this.recManInfo.recModeKey++
          this.recManInfo.recManName = res.name
          this.recManInfo.recManMobile = res.phone
          this.recManInfo.recManProvince = res.province
          this.recManInfo.recManCity = res.city
          this.recManInfo.recManDistrict = res.county
          this.recManInfo.recManAddr = res.detail
          this.recManInfo.recAreaCodeList = [res.provinceCode, res.cityCode, res.countyCode]
          this.clearValidate('recInfoForm')
        }
      }
      setTimeout(() => {
        this.showAddressRecognition = false
      }, 200)
    },
    closeResolve () {
      this.showAddressRecognition = false
    },
    // 获取物品信息
    getGoodsType () {
      this.loading = true
      queryGoodsType(EXPRESSTYPE).then(res => {
        if (res.code === 200) {
          this.goodsTypeList = res.data
        } else {
          if (res.code === 4003) {
            this.showLogin = true
          }
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    },
    // 校验信息，获取可服务的快递公司
    watchRequireKeyChange () {
      const checkResult = this.checkRequireKey()
      if (checkResult === true) {
        this.getExpressList()
      }
    },
    checkRequireKey () {
      const requireObj = {
        sendManProvince: this.sendManInfo.sendManProvince,
        sendManCity: this.sendManInfo.sendManCity,
        sendManDistrict: this.sendManInfo.sendManDistrict,
        sendManAddr: this.sendManInfo.sendManAddr,
        recManProvince: this.recManInfo.recManProvince,
        recManCity: this.recManInfo.recManCity,
        recManDistrict: this.recManInfo.recManDistrict,
        recManAddr: this.recManInfo.recManAddr
      }
      for (const k in requireObj) {
        if (requireObj[k] === '') return k
      }
      return true
    },
    // 获取快递公司和价格
    getExpressList () {
      const params = {
        ...this.sendManInfo,
        ...this.recManInfo,
        orderType: EXPRESSTYPE,
        weight: String(this.goodsInfo.weight)
      }
      queryExpressCompany(params).then(res => {
        if (res.code === 200) {
          if (res.data.resultList && res.data.resultList.length > 0) {
            res.data.resultList.forEach(e => {
              e.checked = false
              e.logoUrl = expressImgObj[e.expressCode]
            })
            this.expressList = res.data.resultList
          }
        } else {
          if (res.code === 4003) {
            this.showLogin = true
          }
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    },
    // 选择快递公司
    chooseExpress (index) {
      this.expressList.forEach(e => {
        e.checked = false
      })
      this.expressList[index].checked = true
      this.expressInfo = {
        com: this.expressList[index].expressCode,
        comName: this.expressList[index].expressName
      }
    },
    validForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.validObj[formName] = valid
        } else {
          return false
        }
      })
    },
    // 清除校验信息
    clearValidate (formName) {
      this.$refs[formName].clearValidate()
    },
    submitOrder () {
      if (!localStorage.getItem('express_token')) {
        this.$message.error('请先登录')
        setTimeout(() => {
          this.showLogin = true
        }, 500)
        return false
      }
      if (localStorage.getItem('express_isAuth') !== '' || localStorage.getItem('express_isAuth') !== null) {
        this.isAuth = Number(localStorage.getItem('express_isAuth'))
      }
      if (this.isAuth !== 1) {
        this.$message.error('请进行实名认证')
        return false
      }
      if (!this.isAgree) {
        this.$message.error('请勾选快件物流资源共享服务协议')
        return false
      }
      this.validObj = {
        sendInfoForm: false,
        recInfoForm: false,
        goodsInfoForm: false
      }
      this.validForm('sendInfoForm')
      this.validForm('recInfoForm')
      this.validForm('goodsInfoForm')
      if (!(this.validObj.sendInfoForm && this.validObj.recInfoForm && this.validObj.goodsInfoForm)) {
        return false
      }
      if (this.expressInfo.com === '') {
        this.$message.error('请选择快递公司')
        return false
      }

      // 提交参数
      const params = {
        orderType: EXPRESSTYPE,
        ...this.recManInfo,
        ...this.sendManInfo,
        cargo: this.goodsInfo.cargo,
        weight: String(this.goodsInfo.weight),
        payment: 'SHIPPER',
        com: this.expressInfo.com,
        comName: this.expressInfo.comName,
        dayType: '今天',
        pickupStartTime: '12:00',
        pickupEndTime: '14:00'
      }
      this.submitUtil(params)
    },
    submitUtil (params) {
      this.loading = true
      queryOrderExpress(params).then(res => {
        if (res.code === 200) {
          this.$message.success('下单成功')
          this.$router.push({
            path: '/'
          })
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    }
  },
  watch: {
    sendManInfo: {
      handler: function () {
        this.watchRequireKeyChange()
      },
      deep: true
    },
    recManInfo: {
      handler: function () {
        this.watchRequireKeyChange()
      },
      deep: true
    },
    goodsInfo: {
      handler: function (oldVal, newVal) {
        this.goodsTypeList.forEach(e => {
          if (e.id === this.goodsInfo.cargoId) {
            this.goodsInfo.cargo = e.goods
          }
        })
        if (oldVal.weight !== newVal) {
          this.watchRequireKeyChange()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.sendBulky {
  // 去掉必填 * 号
  .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: ' '
  }
}
</style>
<style scoped lang="scss">
.sendBulky {
  width: 100%;
  text-align: left;
  .m-l-10 {
    margin-left: 10px;
  }
  .el-button--primary {
    background-color: #0066FF;
    border-color: #0066FF;
    &:hover {
      background-color: rgba(0, 102, 255, 0.8);
      border-color: rgba(0, 102, 255, 0.8)
    }
  }
  .el-cascader, .el-input {
    display: block;
    width: 490px;
  }
  .send-section {
    padding: 0 72px 40px 72px;
    border-bottom: 1px solid #F2F2F2;
    .title-box {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 50px 0 36px 0;
      font-size: 24px;
      color: #303133;
      font-weight: 600;
      img {
        display: block;
        width: 40px;
        height: 40px;
      }
    }
    .p-l-82 {
      padding-left: 82px;
    }
    .intelligent {
      display: flex;
      align-items: center;
    }
  }
  .goods-section {
    padding: 40px 260px 50px 130px;
    border-bottom: 1px solid #F2F2F2;
    .title {
      padding-bottom: 15px;
      font-size: 24px;
      font-weight: 500;
      color: #303133;
    }
    .el-radio {
      min-width: 116px;
      padding: 10px 0;
    }
    .el-tag {
      cursor: pointer;
    }
    .el-divider {
      background-color: #F2F2F2;
    }
    .weight {
      font-size: 20px;
      font-weight: 500;
      color: #303133;
    }
    .weight-tip {
      font-size: 20px;
      color: #BFC4CD;
    }
    .express-box {
      width: 100%;
      .express-item {
        display: flex;
        align-items: center;
        .icon-choose {
          position: relative;
          width: 16px;
          height: 16px;
          margin-right: 8px;
          border-radius: 50%;
          border: 1px solid #D1D1D1;
          background: #fff;
          &.active:after {
            position: absolute;
            top: 3px;
            left: 3px;
            width: 8px;
            height: 8px;
            content: ' ';
            border-radius: 50%;
            background: #1890FF;
          }
        }
        img {
          display: block;
          width: 120px;
          height: 54px;
        }
        .price {
          padding-left: 30px;
          font-size: 24px;
          font-weight: 600;
          color: #0066FF;
        }
      }
    }
    .el-input {
      display: block;
      width: 490px;
    }
  }
  .footer {
    padding: 54px 0;
    text-align: center;
    .protocol {
      font-size: 16px;
      color: #909399;
      span {
        margin-left: 10px;
      }
      .content {
        color: #606266;
      }
    }
    .m-t-40 {
      margin-top: 40px;
    }
    .el-button {
      width: 320px;
    }
  }
}
</style>
