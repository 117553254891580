<template>
  <div class="solution">
    <div class="solution-desc">
      <div class="desc-title" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp'}">
        <img src="../../assets/images/solution/solution_logo.png" alt="">
      </div>
      <div class="desc-remark" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp animate__delay-1s'}">全链协同的资源共享</div>
      <div class="desc-detail" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp animate__delay-1s'}">开发面向共享经济的快件物流综合服务平台，为参与企业提供一个信息门户，在企业之间实现资源信息共享与调度运营；构建信用体系和效率评价体系，
        开展多家物流企业仓、运、转、配全链协同的资源共享应用示范。</div>
    </div>
    <div class="solution-express" :style="{ backgroundImage: 'url(' + activeExpressBg + ')'}">
      <div class="express-list">
        <div class="express-item" v-for="(express, index) in expressList" :key="index" @mouseenter="onMouseEnter(index)"
        :class="{ 'activeBorder' : activeExpressId === index }">
          <div class="img-wrap">
            <img :src="express.img" alt="">
          </div>
          <div class="express-text">{{express.text}}</div>
        </div>
      </div>
    </div>
    <div class="express-h">
      <div class="h-bg"></div>
      <div class="h-content">
        <div class="content-title" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp'}">
          <div class="title-logo">
            <img src="../../assets/images/solution/h_title.png" alt="">
          </div>
          <div class="title-text">多种人力资源共享服务</div>
        </div>
        <div class="content-text" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp animate__delay-1s'}">为快递业务各环节提供人力需求发布、人效评估和人力共享等解决方案 了解各环节人员使用情况的同时，达到降本增效的目的</div>
        <div class="content-list">
          <div class="list-item" v-for="(item, index) in hList" :key="index">
            <div class="img-wrap" :class="{'none-border': index === 2}">
              <img :src="item.img" alt="">
            </div>
            <div class="item-title">{{item.title}}</div>
            <div class="item-text">
              <p>{{item.text1}}</p>
              <p>{{item.text2}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="express-t">
      <div class="t-title" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp'}">
        <div class="title-logo">
          <img src="../../assets/images/solution/t_title.png" alt="">
        </div>
        <div class="title-text">多样运力资源共享服务</div>
      </div>
      <div class="t-text" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp animate__delay-1s'}">通过对运力资源的高效管理和整合，并配合大数据技术的应用 不断优化运输效率</div>
      <div class="t-list">
        <div class="list-item">
          <div class="item-infor" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInLeft'}">
            <div class="infor-wrap">
              <p class="infor-title">运力需求发布</p>
              <p class="infor-desc">通过在运力资源需求发布云需求，快速对接相应运力</p>
            </div>
          </div>
          <div class="item-img">
            <img src="../../assets/images/solution/t_icon1.png" alt="">
          </div>
        </div>
        <div class="list-item reverse">
          <div class="item-infor" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInRight'}">
            <div class="infor-wrap">
              <p class="infor-title">运力需求分析</p>
              <p class="infor-desc">对仓库、中转中心及网点的运输配送量和时效进行数据分析，及时调整运力及路线，提高运输效率</p>
            </div>
          </div>
          <div class="item-img">
            <img src="../../assets/images/solution/t_icon2.png" alt="">
          </div>
        </div>
        <div class="list-item">
          <div class="item-infor" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInLeft'}">
            <div class="infor-wrap">
              <p class="infor-title">运力资源管理</p>
              <p class="infor-desc">对运力资源进行信息化管理，提高管理效率</p>
            </div>
          </div>
          <div class="item-img">
            <img src="../../assets/images/solution/t_icon3.png" alt="">
          </div>
        </div>
        <div class="list-item reverse">
          <div class="item-infor" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInRight'}">
            <div class="infor-wrap">
              <p class="infor-title">运力资源共享</p>
              <p class="infor-desc">对运力资源进行分时、分区进行共享，使其动态匹配运力需求，在满足运力需求的同时，降低运力成本</p>
            </div>
          </div>
          <div class="item-img">
            <img src="../../assets/images/solution/t_icon4.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="express-n">
      <div class="n-title" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp'}">
        <div class="title-logo">
          <img src="../../assets/images/solution/n_title.png" alt="">
        </div>
        <div class="title-text">多个快递末端系统兼容</div>
      </div>
      <div class="n-text" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp animate__delay-1s'}">满足基本业务需求的同时，不断进行优化，维持系统的稳定，全方位、多角度的提高产品质量，不断帮助业务降本增效</div>
      <div class="n-swiper">
        <div class="swiper-tab">
          <div class="tab-item" v-for="(tab, index) in swiperTabList" :key="index" @click="toSlide(tab.idx)" :class="{'active': activeTabId === index}">{{tab.text}}</div>
          <div class="tab-scroll" :style="{ left: 240 * activeTabId + 'px'}"></div>
        </div>
        <swiper class="swiper" :options="swiperOption" ref="mySwiper" @transitionStart="onTransitionEnd">
          <swiper-slide v-for="(slide, index) in swiperTabList" :key="index">
            <img :src="slide.img" alt="">
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="express-c">
      <div class="c-title" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp'}">
        <div class="title-logo">
          <img src="../../assets/images/solution/c_title.png" alt="">
        </div>
        <div class="title-text">多重信用体系认证</div>
      </div>
      <div class="c-text" v-animate-onscroll.repeat="{down: 'animate__animated animate__fadeInUp animate__delay-1s'}">建立预测模型预警违约行为和快件物流共享的信用标准，实现快递共享中信用数据互认，并建立快递物流企业间、及快递企业与客户的激励机制，实现快件物流共享的可持续发展。</div>
      <div class="c-warning">
        <div class="warning-img" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">
          <img src="../../assets/images/solution/c_warning.png" alt="">
        </div>
        <div class="warning-title">信用风险预警</div>
        <div class="warning-desc">通过各个事件的数据收集及分析，对可能发生的异常事件进行风险评估及预警</div>
      </div>
      <div class="c-credit">
        <div class="credit-title">建立信用标准</div>
        <div class="credit-desc">多环节、多维度设立信用标准，搭建全方位的信用体系</div>
        <div class="credit-img" v-animate-onscroll.repeat="'animate__animated animate__zoomIn'">
          <img src="../../assets/images/solution/c_credit.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import expressBg1 from '@/assets/images/solution/express_bg1.jpg'
import expressBg2 from '@/assets/images/solution/express_bg2.jpg'
import expressBg3 from '@/assets/images/solution/express_bg3.jpg'
import expressBg4 from '@/assets/images/solution/express_bg4.jpg'
import expressImgH from '@/assets/images/solution/express_h.png'
import expressImgT from '@/assets/images/solution/express_t.png'
import expressImgN from '@/assets/images/solution/express_n.png'
import expressImgC from '@/assets/images/solution/express_c.png'
import hIcon1 from '@/assets/images/solution/h_icon1.png'
import hIcon2 from '@/assets/images/solution/h_icon2.png'
import hIcon3 from '@/assets/images/solution/h_icon3.png'
import tBanner1 from '@/assets/images/solution/t_banner1.png'
import tBanner2 from '@/assets/images/solution/t_banner2.png'
import tBanner3 from '@/assets/images/solution/t_banner3.png'
import tBanner4 from '@/assets/images/solution/t_banner4.png'
import tBanner5 from '@/assets/images/solution/t_banner5.png'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'solution',
  components: {
    swiper: Swiper,
    swiperSlide: SwiperSlide
  },
  data () {
    return {
      expressList: [
        { text: '多种人力资源共享服务', img: expressImgH, bg: expressBg1 },
        { text: '多样运力资源共享服务', img: expressImgT, bg: expressBg2 },
        { text: '多个快递末端系统兼容', img: expressImgN, bg: expressBg3 },
        { text: '多重信用体系认证', img: expressImgC, bg: expressBg4 }
      ],
      activeExpressId: 0,
      hList: [
        { title: '人力需求发布', text1: '搭建人力资源需求发布平台', text2: '实现人力招聘信息的快速发布和对接', img: hIcon1 },
        { title: '人员效能评估', text1: '根据工作量、人员数量以及过往数据', text2: '进行动态评估，方便各环节人员的调整', img: hIcon2 },
        { title: '人员共享', text1: '在仓、转、运、配各环节实现人员共享', text2: '使人员利用率最大化，达到降本增效的目的', img: hIcon3 }
      ],
      tabSwiper: '',
      swiperOption: {
        // slidesPerView: 1.5669,
        slidesPerView: 'auto',
        spaceBetween: 100,
        centeredSlides: true,
        effect: 'coverflow',
        grabCursor: true,
        coverflowEffect: {
          rotate: 10,
          stretch: 0,
          depth: 10,
          modifier: 2,
          slideShadows: true
        }
      },
      swiperTabList: [
        { text: '多家快递支持', idx: 0, img: tBanner1 },
        { text: '操作一步到位', idx: 1, img: tBanner2 },
        { text: '特殊运单提醒', idx: 2, img: tBanner3 },
        { text: '问题件上报', idx: 3, img: tBanner4 },
        { text: '数据一屏掌握', idx: 4, img: tBanner5 }
      ],
      activeTabId: 0
    }
  },
  computed: {
    activeExpressBg () {
      return this.expressList.find((item, index) => index === this.activeExpressId).bg
    }
  },
  methods: {
    onMouseEnter (index) {
      this.activeExpressId = index
    },
    toSlide (index) {
      this.activeTabId = index
      this.$refs.mySwiper.$swiper.slideTo(index, 1500, false)
    },
    onTransitionEnd () {
      this.activeTabId = this.tabSwiper.activeIndex
    }
  },
  mounted () {
    this.tabSwiper = this.$refs.mySwiper.$swiper
    /* TDD 触发首屏的animate */
    window.addEventListener('load', () => {
      window.scrollTo(0, 1)
    })
  }
}
</script>

<style lang="scss" scoped>
  .solution {
    text-align: left;
    box-sizing: border-box;
    .solution-desc {
      height: 900px;
      padding-top: 236px;
      padding-left: 51.4583vw;
      background: url(../../assets/images/solution/solution_bg1.jpg) center top no-repeat;
      background-size: cover;
      .desc-title {
        padding-left: 238px;
        width: 296px;
        height: 68px;
        img {
          display: block;
          width: 296px;
        }
      }
      .desc-remark {
        &.animate__animated.animate__fadeInUp {
          --animate-delay: 0.5s;
        }
        position: relative;
        margin-top: 31px;
        width: 572px;
        height: 66px;
        border-radius: 8px;
        background-image: linear-gradient(to right, #0369FF 0%, #025ADC 100%);
        font-size: 38px;
        text-align: center;
        font-family: 'PingFang-SC-Regular, PingFang-SC';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 66px;
        letter-spacing: 14px;
        box-shadow: 0 20px 30px 0 rgba(166, 196, 237, 0.5);
        &::after {
          content: '';
          position: absolute;
          right: 70px;
          top: -20px;
          width: 0px;
          height: 0px;
          border: 10px solid transparent;
          border-bottom-color: #025ADC;
        }
      }
      .desc-detail {
        width: 566px;
        padding-left: 11px;
        margin-top: 32px;
        font-size: 16px;
        font-family: 'PingFangSC-Regular, PingFang SC';
        font-weight: 400;
        color: #2E3E57;
        line-height: 36px;
        letter-spacing: 1px;
      }
    }
    .solution-express {
      height: 900px;
      background-image: url(../../assets/images/solution/express_bg1.jpg);
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      padding-top: 130px;
      transition: all 0.5s linear;
      .express-list {
        width: 720px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .express-item {
          width: 340px;
          height: 260px;
          margin-bottom: 40px;
          border: 2px solid transparent;
          transition: all 0.5s ease;
          cursor: pointer;
          &:nth-child(1) {
            background: linear-gradient(225deg, #3E8CFF 0%, #2872E1 100%);
            border-radius: 16px 16px 0px 16px;
            &.activeBorder {
              border: 2px solid #4C94FF;
              box-shadow: 0px 20px 40px 0px rgba(1, 64, 156, 0.7);
            }
          }
          &:nth-child(2) {
            background: linear-gradient(225deg, #7745FF 0%, #562DFF 100%);
            border-radius: 16px 16px 16px 0px;
            &.activeBorder {
              border: 2px solid #805BFE;
              box-shadow: 0px 20px 60px 0px #38209D;
            }
          }
          &:nth-child(3) {
            background: linear-gradient(225deg, #FFA464 0%, #FF6D38 100%);
            border-radius: 16px 0px 16px 16px;
            &.activeBorder {
              border: 2px solid #FCAF82;
              box-shadow: 0px 20px 60px 0px #B64720;
            }
          }
          &:nth-child(4) {
            background: linear-gradient(225deg, #5CD2FF 0%, #1F9BEF 100%);
            border-radius: 0px 16px 16px 16px;
            &.activeBorder {
              border: 2px solid #75CCF9;
              box-shadow: 0px 20px 60px 0px #0E629B;
            }
          }
        }
        .img-wrap {
          padding-top: 67px;
          padding-left: 43px;
          img {
            display: block;
            width: 82px;
          }
        }
        .express-text {
          margin-top: 34px;
          padding-left: 43px;
          font-size: 24px;
          font-family: 'PingFangSC-Regular, PingFang SC';
          font-weight: 400;
          color: #fff;
          letter-spacing: 2px;
        }
      }
    }
    .express-h {
      height: 700px;
      position: relative;
      background-color: #fff;
      .h-bg {
        position: absolute;
        top: -30px;
        left: 50%;
        margin-left: -640px;
        width: 1280px;
        height: 760px;
        background: #E5F0FF;
        border-radius: 16px;
        opacity: 0.37;
      }
      .h-content {
        position: absolute;
        top: -50px;
        left: 50%;
        margin-left: -600px;
        width: 1200px;
        height: 800px;
        background-color: #fff;
        padding-top: 64px;
        .content-title {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          .title-logo {
            width: 99px;
            height: 81px;
            margin-right: 16px;
            img {
              display: block;
              width: 99px;
            }
          }
          .title-text {
            font-size: 32px;
            font-family: 'PingFangSC-Regular, PingFang SC';
            font-weight: 400;
            color: #2E3E57;
            line-height: 36px;
            letter-spacing: 4px;
          }
        }
        .content-text {
          &.animate__animated.animate__fadeInUp {
            --animate-delay: 0.5s;
          }
          width: 531px;
          margin: 0 auto;
          font-size: 16px;
          font-family: 'PingFangSC-Regular, PingFang SC';
          font-weight: 400;
          color: #2E3E57;
          line-height: 32px;
          letter-spacing: 1px;
          text-align: center;
        }
        .content-list {
          margin-top: 88px;
          display: flex;
          justify-content: space-around;
          .list-item {
            width: 328px;
            text-align: center;
            .img-wrap {
              width: 240px;
              margin: 0 auto;
              position: relative;
              &::after {
                content: '';
                position: absolute;
                top: 25px;
                right: -80px;
                width: 1px;
                height: 190px;
                background-color: rgba(207, 219, 237, 1);
              }
              &.none-border::after {
                width: 0;
              }
              img {
                display: block;
                width: 240px;
              }
            }
            .item-title {
              margin-top: 24px;
              font-size: 24px;
              font-family: 'PingFangSC-Regular, PingFang SC';
              font-weight: 400;
              color: #025ADC;
              line-height: 36px;
              letter-spacing: 3px;
            }
            .item-text {
              margin-top: 16px;
              font-size: 16px;
              font-family: 'PingFangSC-Regular, PingFang SC';
              font-weight: 400;
              color: #2E3E57;
              line-height: 26px;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
    .express-t {
      // height: 2252px;
      padding-top: 115px;
      background: url(../../assets/images/solution/t_bg.jpg) center top no-repeat;
      background-size: cover;
      .t-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .title-logo {
          width: 99px;
          height: 81px;
          margin-right: 21px;
          img {
            display: block;
            width: 99px;
          }
        }
        .title-text {
          font-size: 32px;
          font-family: 'PingFangSC-Regular, PingFang SC';
          font-weight: 400;
          color: #2E3E57;
          line-height: 36px;
          letter-spacing: 4px;
          color: #fff;
        }
      }
      .t-text {
        &.animate__animated.animate__fadeInUp {
          --animate-delay: 0.5s;
        }
        width: 480px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        font-family: 'PingFangSC-Regular, PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        letter-spacing: 1px;
      }
      .t-list {
        .list-item {
          // padding: 137px 0;
          padding: 7.1354vw 0;
          padding-right: 53.0208vw;
          padding-left: 0;
          position: relative;
          .item-infor {
            // height: 213px;
            height: 11.0937vw;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            // padding-top: 67px;
            // padding-top: 3.4895vw;
            // padding-right: 154px;
            padding-right: 8.02vw;
            text-align: right;
            background: linear-gradient(270deg, #ACB1FF 0%, rgba(105, 59, 255, 0) 100%);
            // opacity: 0.7;
            .infor-title {
              font-size: 32px;
              font-family: 'PingFangSC-Regular, PingFang SC';
              font-weight: 400;
              color: #FFFFFF;
              line-height: 36px;
              letter-spacing: 4px;
            }
            .infor-desc {
              margin-top: 16px;
              font-size: 16px;
              font-family: 'PingFangSC-Regular, PingFang SC';
              font-weight: 400;
              color: #FFFFFF;
              line-height: 26px;
              letter-spacing: 1px;
            }
          }
          .item-img {
            position: absolute;
            // width: 510px;
            width: 26.5625vw;
            // height: 490px;
            height: 25.5208vw;
            img {
              display: block;
              // width: 510px;
              width: 26.5625vw;
              height: 25.5208vw;
            }
          }
          &:nth-child(1) .item-img {
            // top: 57px;
            top: 2.9687vw;
            // right: 388px;
            right: 20.2083vw;
          }
          &:nth-child(2) .item-img {
            top: 0px;
            // left: 385px;
            left: 20.052vw;
          }
          &:nth-child(3) .item-img {
            top: 0px;
            // right: 388px;
            right: 20.2083vw;
          }
          &:nth-child(4) .item-img {
            // top: -57px;
            top: -2.9687vw;
            // left: 385px;
            left: 20.052vw;
          }
          &:last-child {
            border-bottom: none;
          }
          &.reverse {
            padding-left: 53.0208vw;
            padding-right: 0;
            .item-infor {
              background: linear-gradient(-270deg, #ACB1FF 0%, rgba(105, 59, 255, 0) 100%);
              // padding-top: 54px;
              // padding-top: 2.8125vw;
              // padding-left: 151px;
              padding-left: 7.8645vw;
              text-align: left;
              justify-content: flex-start;
            }
          }
        }
      }
    }
    .express-n {
      height: 900px;
      padding-top: 65px;
      background-color: #fff;
      .n-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .title-logo {
          width: 99px;
          height: 81px;
          margin-right: 16px;
          img {
            display: block;
            width: 99px;
          }
        }
        .title-text {
          font-size: 32px;
          font-family: 'PingFangSC-Regular, PingFang SC';
          font-weight: 400;
          color: #2E3E57;
          line-height: 36px;
          letter-spacing: 4px;
        }
      }
      .n-text {
        &.animate__animated.animate__fadeInUp {
          --animate-delay: 0.5s;
        }
        width: 486px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        font-family: 'PingFangSC-Regular, PingFang SC';
        font-weight: 400;
        color: #2E3E57;
        line-height: 32px;
        letter-spacing: 1px;
      }
      .n-swiper {
        margin-top: 78px;
        .swiper-tab {
          width: 1200px;
          margin: 0 auto;
          display: flex;
          border-bottom: 8px solid#E3E7EC;
          position: relative;
          .tab-item {
            flex-basis: 240px;
            text-align: center;
            line-height: 36px;
            height: 56px;
            padding-bottom: 20px;
            font-size: 24px;
            font-family: 'PingFangSC-Regular, PingFang SC';
            font-weight: 400;
            color: rgba(46, 62, 87, 0.7);
            letter-spacing: 3px;
            cursor: pointer;
            &.active {
              color: #2E3E57;
            }
          }
          .tab-scroll {
            position: absolute;
            bottom: -8px;
            left: 0px;
            width: 240px;
            height: 8px;
            background: #FF743D;
            border-radius: 4px;
            transition: left 0.5s ease;
          }
        }
        .swiper-slide {
          height: 460px;
          width: 1200px !important;
          transition: opacity 1.5s ease;
        }
      }
    }
    .express-c {
      height: 900px;
      padding-top: 64px;
      background-image: linear-gradient(225deg, #5CD2FF 0%, #1F9BEF 100%);
      position: relative;
      .c-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        .title-logo {
          width: 99px;
          height: 81px;
          margin-right: 16px;
          img {
            display: block;
            width: 99px;
          }
        }
        .title-text {
          font-size: 32px;
          font-family: 'PingFangSC-Regular, PingFang SC';
          font-weight: 400;
          color: #fff;
          line-height: 36px;
          letter-spacing: 4px;
        }
      }
      .c-text {
        &.animate__animated.animate__fadeInUp {
          --animate-delay: 0.5s;
        }
        width: 681px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        font-family: 'PingFangSC-Regular, PingFang SC';
        font-weight: 400;
        color: #fff;
        line-height: 32px;
        letter-spacing: 1px;
      }
      .c-warning {
        position: absolute;
        right: 0;
        bottom: 65px;
        // width: 1062px;
        width: 55.3125vw;
        height: 543px;
        padding-top: 76px;
        // padding-left: 216px;
        padding-left: 11.25vw;
        background: linear-gradient(270deg, #579AFD 0%, #3B7DF6 100%);
        // opacity: 0.4;
        // clip-path: polygon(100% 0, 100% 100%, 0 100%, 156px 0);
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 14.6892% 0);
        .warning-img {
          width: 392px;
          height: 266px;
          margin-bottom: 24px;
          img {
            display: block;
            width: 392px;
          }
        }
      }
      .c-credit {
        position: absolute;
        left: 0;
        bottom: 99px;
        // width: 1084px;
        width: 56.4583vw;
        height: 466px;
        padding-top: 64px;
        // padding-right: 221px;
        padding-right: 11.5104vw;
        clip-path: polygon(100% 0, 86.5314% 100%, 0 100%, 0 0);
        background: url(../../assets/images/solution/c_credit_banner.png) center top no-repeat;
        background-size: cover;
        text-align: right;
        .credit-img {
          width: 408px;
          height: 266px;
          margin-top: 24px;
          display: inline-block;
          padding-right: 16px;
          img {
            display: block;
            width: 392px;
          }
        }
      }
      .warning-title, .credit-title {
        font-size: 32px;
        font-family: 'PingFangSC-Semibold, PingFang SC';
        font-weight: 600;
        color: #FFFFFF;
        line-height: 36px;
        letter-spacing: 4px;
      }
      .warning-desc, .credit-desc {
        margin-top: 13px;
        font-size: 16px;
        font-family: 'PingFangSC-Regular, PingFang SC';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
        letter-spacing: 1px;
      }
    }
  }
</style>
