<template>
  <div class="share-link">
    <div class="content-box">
      <div class="share-title">
        <div class="title-text">共配网点信息登记</div>
        <div class="step-infor">
          <span class="step-label">{{activeStep}}</span>
          <span class="step-text">{{stepTitleText}}</span>
        </div>
      </div>
      <div class="first-step" v-show="activeStep === 1">
        <el-form label-position="left" label-width="90px" :model="orgBaseInfor" :rules=rules ref="orgBaseInfor">
          <el-form-item label="网点名称" prop="orgName">
            <el-input v-model="orgBaseInfor.orgName" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="网点地址" prop="PAC">
            <el-cascader
              v-model="orgBaseInfor.PAC"
              :options="options"
              @active-item-change="getNodes"
              @change="areaChange"
              :props="props"
              class="mr8 cascader-icon"
              clearable>
            </el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="detailAddr">
            <el-input v-model="orgBaseInfor.detailAddr" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="管理员姓名" prop="orgAdminName">
            <el-input v-model="orgBaseInfor.orgAdminName" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="管理员手机" prop="orgAdminPhone">
            <el-input v-model="orgBaseInfor.orgAdminPhone" placeholder="请输入" clearable></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-box">
          <el-button type="primary" class="next-btn" @click="goNextStep">下一步</el-button>
        </div>
      </div>
      <div class="second-step" v-show="activeStep === 2">
        <div class="express-select">
          <div class="select-notice">网点快递信息（可多选，至少一项）</div>
          <el-checkbox-group v-model="checkedExpress">
            <el-checkbox v-for="express in expressList" :label="express.key" :key="express.key">{{express.value}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="express-infor-list">
          <div class="express-infor-item" v-for="(item, index) in orgApplyExpressList" :key="index">
            <el-form label-position="left" label-width="100px" :model="item">
              <div class="express-name">{{item.expressName}}网点数据</div>
              <el-form-item label="网点编号" prop="expressOrgCode">
                <el-input v-model="item.expressOrgCode" placeholder="请输入" clearable maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="网点名称" prop="expressOrgName">
                <el-input v-model="item.expressOrgName" placeholder="请输入" clearable maxlength="100"></el-input>
              </el-form-item>
              <el-form-item label="所属城市" prop="cityName">
                <el-input v-model="item.cityName" placeholder="请输入" clearable maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="上一站编号" prop="lastOrgCode">
                <el-input v-model="item.lastOrgCode" placeholder="请输入" clearable maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="上一站名称" prop="lastOrgName">
                <el-input v-model="item.lastOrgName" placeholder="请输入" clearable maxlength="100"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="btn-box">
          <el-button type="primary" class="prev-btn" @click="goPrevStep">上一步</el-button>
          <el-button type="primary" class="submit-btn" @click="submit">确认提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { applySubmit, openUrl } from '../../assets/js/api.js'
import mixins from './mixin.vue'
export default {
  name: 'shareLink',
  mixins: [mixins],
  data () {
    return {
      activeStep: 1,
      orgBaseInfor: {
        PAC: [],
        orgName: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        detailAddr: '',
        orgAdminName: '',
        orgAdminPhone: ''
      },
      expressList: [],
      checkedExpress: ['yuantong'],
      rules: {
        orgName: [{ required: true, message: '请输入网点名称', trigger: 'blur' }, { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }],
        detailAddr: [{ required: true, message: '请输入地址', trigger: 'blur' }, { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }],
        PAC: [{ required: true, message: '请选择网点地址', trigger: 'change' }],
        orgAdminName: [{ required: true, message: '请输入管理员姓名', trigger: 'blur' }, { min: 1, max: 50, message: '长度在 1 到 5 个字符', trigger: 'blur' }],
        orgAdminPhone: [{ required: true, message: '请输入管理员手机号', trigger: 'blur' }, { pattern: '^1[3|4|5|6|7|8|9][0-9]\\d{8}$', message: '请输入正确的手机号码', trigger: 'blur' }]
      },
      allOrgApplyExpressList: [],
      applyId: ''
    }
  },
  computed: {
    stepTitleText () {
      return this.activeStep === 1 ? '网点基本信息' : '网点快递信息'
    },
    orgApplyExpressList () {
      return this.allOrgApplyExpressList.filter(item => {
        return this.checkedExpress.includes(item.expressCode)
      })
    }
  },
  methods: {
    goPrevStep () {
      this.activeStep = 1
    },
    goNextStep () {
      this.$refs.orgBaseInfor.validate((valid) => {
        if (valid) {
          this.activeStep = 2
        } else {
          return false
        }
      })
    },
    areaChange (val) {
      this.orgBaseInfor.provinceId = val[0]
      this.orgBaseInfor.cityId = val[1]
      this.orgBaseInfor.areaId = val[2]
    },
    submit () {
      if (this.checkedExpress.length === 0) {
        this.$message.error('请至少选择一家快递公司！')
        return false
      }
      const params = Object.assign({}, this.orgBaseInfor, {
        expressRequests: this.orgApplyExpressList,
        applyId: this.applyId
      })
      applySubmit(params).then(res => {
        if (res.code === 200) {
          this.$message.success('提交成功')
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    getExistedInfor () {
      const params = {
        id: this.applyId
      }
      openUrl(params).then(res => {
        if (res.code === 200) {
          this.orgBaseInfor = {
            PAC: [],
            orgName: res.data.orgName,
            provinceId: res.data.provinceId,
            cityId: res.data.cityId,
            areaId: res.data.areaId,
            detailAddr: res.data.detailAddr,
            orgAdminName: res.data.orgAdminName,
            orgAdminPhone: res.data.orgAdminPhone
          }
          this.fillCity(res.data.provinceId, res.data.cityId, res.data.areaId)
          this.expressList = res.data.responseList.map(item => {
            return {
              key: item.expressCode,
              value: item.expressName
            }
          })
          this.allOrgApplyExpressList = res.data.responseList
          const selectedExpress = res.data.responseList.filter(item => {
            return item.selectFlag
          })
          if (selectedExpress.length > 0) {
            this.checkedExpress = selectedExpress.map(item => item.expressCode)
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    formatExpName (expName) {
      if (expName.includes('快递')) {
        return expName.replace('快递', '')
      } else {
        return expName.replace('速递', '')
      }
    }
  },
  created () {
    this.$emit('footer', false)
    this.applyId = +this.$route.query.applyId
  },
  mounted () {
    this.getExistedInfor()
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 960px) {
    /* 电脑端CSS代码 */
    .share-link .content-box {
      width: 700px;
    }
  }
  @media screen and (max-width: 960px){
    /* 手机端CSS代码 */
    .share-link .content-box {
      width: 100vw;
    }
  }
  .share-link {
    background: #F5F7FA;
    text-align: left;
    .content-box {
      box-sizing: border-box;
      padding: 30px 20px;
      min-height: 100vh;
      background-color: #fff;
      margin: 0 auto;
      .share-title {
        padding-left: 7px;
        .title-text {
          line-height: 28px;
          height: 28px;
          font-size: 20px;
          font-family: 'PingFangSC-Semibold, PingFang SC';
          font-weight: 600;
          color: #323233;
          letter-spacing: 1px;
        }
        .step-infor {
          margin-top: 20px;
          height: 24px;
          line-height: 24px;
          font-size: 16px;
          .step-label {
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: #6554C0;
            text-align: center;
            color: #fff;
            font-family: 'PingFangSC-Medium, PingFang SC';
            font-weight: 500;
          }
          .step-text {
            padding-left: 10px;
            font-family: 'PingFangSC-Semibold, PingFang SC';
            font-weight: 600;
            color: #323233;
          }
        }
      }
      .first-step {
        margin-top: 50px;
        .btn-box {
          padding-top: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .second-step {
        margin-top: 43px;
        .express-select {
          padding-bottom: 7px;
          .select-notice {
            height: 20px;
            line-height: 20px;
            margin-bottom: 20px;
            font-family: 'PingFangSC-Regular, PingFang SC';
            font-weight: 400;
            font-size: 14px;
            color: #4D4E57;
          }
        }
        .express-infor-list {
          margin-top: 21px;
          .express-infor-item {
            margin-bottom: 5px;
            .el-form .express-name {
              padding: 8px 19px;
              margin-bottom: 10px;
              line-height: 20px;
              font-family: 'PingFangSC-Semibold, PingFang SC';
              font-weight: 600;
              font-size: 14px;
              color: #4D4E57;
              background-color: #F2F2F2;
            }
          }
        }
        .btn-box {
          padding-top: 34px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
</style>
<style lang="scss">
  @media screen and (max-width: 960px){
    /* 手机端CSS代码 */
    .el-popper{
      width: 100vw;
      overflow-x: scroll;
    }
    .share-link .el-checkbox-group .el-checkbox {
      margin-bottom: 15px;
    }
    .share-link .content-box .second-step .express-infor-list {
      margin-top: 6px !important;
    }
  }
  .share-link .el-form-item {
    margin-bottom: 16px;
    border-bottom: 1px solid #EDEFF2;
    .el-form-item__label {
      color: #4D4E57;
      font-family: 'PingFangSC-Regular, PingFang SC';
      font-weight: 400;
      &::before {
        content: '' !important;
      }
    }
    .el-input input {
      border: none;
      color: #A7A6AF;
      font-family: 'PingFangSC-Regular, PingFang SC';
      font-weight: 400;
    }
  }
  .share-link .second-step .el-form-item .el-form-item__label {
    padding-left: 10px;
  }
  .share-link .el-button.next-btn {
    width: 335px;
    margin: 0 auto;
    background-color: #6554C0;
    border: none;
    line-height: 20px;
    font-size: 14px;
  }
  .share-link .el-button.prev-btn, .share-link .el-button.submit-btn {
    width: 158px;
    background-color: #6554C0;
    border: none;
    line-height: 20px;
    font-size: 14px;
  }
  .share-link .el-checkbox-group .el-checkbox {
    margin-right: 21px;
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #6554C0;
      border-color: #6554C0;
    }
    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #6554C0;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #6554C0;
    }
  }
  .share-link .el-cascader {
    width: 100%;
  }
</style>
