<template>
<div class="send">
  <div class="certification-tips" v-if="showCertificationTips">
    <span>根据国家法律规定，寄件需要实名认证</span>
    <el-tag type="warning" size="small" effect="plain" @click="goAuth">实名认证</el-tag>
    <div class="close" @click="showCertificationTips = false"><i class="el-icon-close"></i></div>
  </div>
  <div class="send-container">
    <div class="tabs">
      <div class="tab-item" :class="{'active': expressType === 0}" @click="changeTab(0)">寄快递</div>
      <div class="tab-item" :class="{'active': expressType === 1}" @click="changeTab(1)">寄大件</div>
    </div>
    <send-express v-if="expressType === 0" @needAuth="needAuth"></send-express>
    <send-bulky v-if="expressType === 1" @needAuth="needAuth"></send-bulky>
  </div>
</div>
</template>

<script>

import sendExpress from '@/views/send/sendExpress.vue'
import sendBulky from '@/views/send/sendBulky.vue'

export default {
  name: 'index',
  components: {
    sendExpress,
    sendBulky
  },
  data () {
    return {
      showCertificationTips: true,
      expressType: 0
    }
  },
  mounted () {
    this.expressType = Number(this.$route.query.expressType)
    this.showCertificationTips = localStorage.getItem('express_isAuth') !== '1'
  },
  methods: {
    goAuth () {
      this.$router.push({
        path: '/authentication'
      })
    },
    changeTab (type) {
      this.expressType = type
    },
    needAuth () {
      if (localStorage.getItem('express_isAuth')) {
        this.showCertificationTips = !(Number(localStorage.getItem('express_isAuth')) === 1)
      } else {
        this.showCertificationTips = true
      }
    }
  }
}
</script>

<style lang="scss">
.send {
  .certification-tips {
    .el-tag {
      border-radius: 12px;
    }
  }
}
</style>
<style scoped lang="scss">
.send {
  width: 100%;
  min-height: 82vh;
  .certification-tips {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 6px 0;
    color: #EF964F;
    background: #FFEDD7;
    .el-tag {
      margin-left: 10px;
      cursor: pointer;
    }
    .close {
      margin-left: 5px;
      padding: 0 10px;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .send-container {
    width: 1200px;
    margin: 0 auto;
    .tabs {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F2F2F2;
      .tab-item {
        padding: 38px 14px 20px 14px;
        margin-right: 50px;
        font-size: 20px;
        font-weight: 600;
        color: #303133;
        cursor: pointer;
        &.active {
          color: #fff;
          background: #0066FF;
        }
      }
    }
  }
}
</style>
