<template>
<div>
  签收数据接口
</div>
</template>

<script>
export default {
  name: 'signApi'
}
</script>

<style scoped>

</style>
