<template>
  <div class="arriveApi">
    <div class="arr_info">
      <p class="arr_label">应用场景</p>
      <p class="arr_desc">
        1.向云打印机发送电子面单打印任务，在云打印机上打印电子面单
      </p>

      <p class="arr_desc">2.支持利用云打印机上配置的单号源，打印模板自动获取单号，获取三段码，设置打印模板，直接打印，无需预先获取单号，提高开发效率
      </p>

      <p class="arr_desc">3.支持仅预览电子面单底单及查询打印状态，详见：如何获取电子面单底单及电子面单打印状态
      </p>

      <p class="arr_desc pb40">注：此处“云打印机”指快宝的云打印解决方案，它通过在电脑上安装的云打印插件或“快宝盒子”专用设备，把普通台式热敏打印机变成云打印机，可通过网络远程打印电子面单。云打印机安装详见：如何安装云打印机，配置详见：如何管理云打印机；快宝盒子详见：购买快宝盒子
      </p>
    </div>

    <div class="arr_info">
      <p class="arr_label">接口名称</p>
      <p class="arr_desc pb40">
        cloud.print.do
      </p>

    </div>

    <div class="arr_info">
      <p class="arr_label">公共参数</p>
      <p class="arr_desc pb20">
        请求地址
      </p>
      <template>
        <el-table
          :data="requestUrlData"
          style="width: 100%">
          <el-table-column
            prop="envName"
            label="环境名称"
            width="180">
          </el-table-column>
          <el-table-column
            prop="httpsUrl"
            label="HTTPS 请求地址">
          </el-table-column>
        </el-table>
      </template>

      <p class="arr_desc pb20">
        公共响应参数
      </p>
      <template>
        <el-table
          :data="paramsData"
          style="width: 100%">
          <el-table-column
            prop="paramName"
            label="名称"
            width="180">
          </el-table-column>
          <el-table-column
            prop="paramType"
            label="类型">
          </el-table-column>
          <el-table-column
          prop="required"
          label="是否必须">
        </el-table-column>
          <el-table-column
            prop="describe"
            label="描述">
          </el-table-column>
        </el-table>
      </template>

    </div>
  </div>
</template>

<script>
export default {
  name: 'arriveApi',

  data () {
    return {
      requestUrlData: [{
        envName: '正式地址',
        httpsUrl: 'https://kop.kuaidihelp.com/api'
      }],
      paramsData: [{
        paramName: 'name',
        paramType: 'string',
        required: 'true',
        describe: '姓名'
      }]
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .arriveApi{
    text-align: left;
    padding: 40px;
    width: 980px;

      .arr_info{
        .arr_label{
          font-size:22px;
          color:rgba(2,1,6,1);
          line-height:25px;
        }
        .arr_desc{
          padding-top: 30px;
          font-size:14px;
          color:rgba(95,100,100,1);
          line-height:25px;
        }
      }

    .pt30{
      padding-top: 30px;
    }
    .pb40{
      padding-bottom: 40px;
    }
    .pb20{
      padding-bottom: 20px;
    }
  }

</style>
<style rel="stylesheet/scss" lang="scss" >
  .arriveApi {
    .el-table th {
      background-color: #FBFBFB;
    }
    .el-table .cell {
      font-size:14px;
      color:rgba(95,100,100,1);
      line-height:25px;
    }
  }
</style>
