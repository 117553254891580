<template>
  <div class="openPlatForm">
    <div class="titlePlatForm">
      <img src="../../assets/images/plantForm/bg-code-img.png"/>
      <div class="title_words">
      <p class="title_f">开放平台</p>
      <p class="title_tag">快递服务技术对接平台，共享各类服务接口，提供快件信息安全增值服务</p>
        <!-- <el-button @click="dialogFormVisible = true">申请接入</el-button> -->
      </div>
    </div>
    <div class="pf_content">
      <el-menu
        :default-active="defaultMenu"
        class="el-menu-pf"
        :unique-opened="true"
        @open="handleOpen"
        @select="handleSelect"
        background-color="#F2F3F5"
        active-text-color="#1240C0">
        <el-submenu :index="'a'+index" v-for="(menu,index) in menuList" :key="index">
          <template slot="title" class="menu_title">
            <span>{{menu.name}}</span>
          </template>
            <el-menu-item :index="itemMenu.path" :key="itemIndex" v-for="(itemMenu,itemIndex) in menu.childTags">{{itemMenu.name}}</el-menu-item>
        </el-submenu>
      </el-menu>
      <div class="pf_info">
          <div class="info_rows"><span>接口地址: </span><span>/openapi{{baseInfo.requestPath}}</span></div>
          <div class="info_rows"><span>请求方式: </span><span>{{baseInfo.requestMethod}}</span></div>
          <div class="info_rows"><span>consumes: </span><span>{{JSON.stringify(baseInfo.consumes)}}</span></div>
          <div class="info_rows"><span>produces: </span><span>{{JSON.stringify(baseInfo.produces)}}</span></div>
          <div class="info_rows"><span>接口描述: </span><span>{{baseInfo.description}}</span></div>
          <div class="info_rows"><span>请求参数: </span></div>
          <el-table
            :data="baseInfo.parameters"
            style="width: 100%;margin: 7px 0;"
            border>
            <el-table-column
              prop="name"
              label="参数名称">
            </el-table-column>
            <el-table-column
              prop="description"
              label="参数说明">
            </el-table-column>
            <el-table-column
              prop="in"
              label="请求类型">
            </el-table-column>
            <el-table-column
              prop="required"
              label="是否必须">
              <template slot-scope="scope">
                  {{scope.row.required}}
              </template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="数据类型">
            </el-table-column>
            <el-table-column
              prop="schema"
              label="schema">
            </el-table-column>
          </el-table>
          <div class="info_rows">响应状态</div>
          <el-table
            :data="baseInfo.responses"
            style="width: 100%;margin: 7px 0;"
            border>
            <el-table-column
              prop="statusCode"
              label="状态码">
            </el-table-column>
            <el-table-column
              prop="description"
              label="说明">
            </el-table-column>
            <el-table-column
              prop="schema"
              label="schema">
            </el-table-column>
          </el-table>
        <div class="info_rows">响应参数</div>
        <el-table
          :data="responseList"
          style="width: 100%;margin: 7px 0;"
          border
          row-key="propertyName"
          :tree-props="{children: 'child'}">
          <el-table-column
            prop="propertyName"
            label="参数名称">
          </el-table-column>
          <el-table-column
            prop="description"
            label="参数说明">
          </el-table-column>
          <el-table-column
            prop="type"
            label="类型">
            <template slot-scope="scope">
              <span>{{scope.row.type}}</span><span v-if="scope.row.format">({{scope.row.format}})</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { allApis, singleApiInfo } from '../../assets/js/api.js'
export default {
  name: 'index',
  data () {
    return {
      success: false,
      dialogFormVisible: false,
      menuList: [],
      defaultMenu: '',
      formLabelWidth: '80px',
      responseList: [],
      baseInfo: {},
      tableData: [{
        id: 1,
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        id: 2,
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        id: 3,
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄',
        child: [{
          id: 31,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          id: 32,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄',
          child: [{
            id: 33,
            date: '2016-05-01',
            name: '王小虎1',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            id: 34,
            date: '2016-05-01',
            name: '王小虎2',
            address: '上海市普陀区金沙江路 1519 弄'
          }]
        }]
      }, {
        id: 4,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {

    handleOpen (key, keyPath) {
    },
    handleSelect (index) {
      console.log(index)
      this.getSingleInfo(index)
    },
    getList () {
      allApis().then((res) => {
        if (res.code === 200) {
          this.menuList = res.data.tags
          this.defaultMenu = this.menuList[0].childTags[0].path
          this.getSingleInfo(this.defaultMenu)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getSingleInfo (path) {
      const params = {
        path: path
      }
      singleApiInfo(params).then((res) => {
        if (res.code === 200) {
          this.responseList = res.data.responseParameters
          this.baseInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    }
    // handleClose () {
    //   this.dialogFormVisible = false
    //   this.$refs.form.resetFields()
    // },
    //
    // onsubmit () {
    //   this.$refs.form.validate((valid) => {
    //     if (valid) {
    //       apiApply(this.form).then((res) => {
    //         if (res.code === 200) {
    //           this.success = true
    //           this.$refs.form.resetFields()
    //           this.dialogFormVisible = false
    //         } else {
    //           this.$message.error(res.msg)
    //         }
    //       })
    //     } else {
    //       return false
    //     }
    //   })
    // },
    //
    // onCancel () {
    //   this.dialogFormVisible = false
    //   this.$refs.form.resetFields()
    // }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .openPlatForm {
    width: 100%;
    .titlePlatForm {
      position: relative;
      img{
        width: 100%;
      }
      .title_words {
        width: 100%;
        padding-left: 20%;
        top: calc(50% - 92px);
        text-align: left;
        position: absolute;

        .title_f {
          font-size: 30px;
          color: rgba(255, 255, 255, 1);
          line-height: 55px;
          letter-spacing: 8px;
        }

        .title_tag {
          margin-top: 20px;
          margin-bottom: 40px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 32px;
          letter-spacing: 4px;
        }
      }
    }

    .pf_content{
      display: flex;
      width: 1200px;
      margin: auto;
      .el-menu-pf{
        // flex: 2;
        width: 350px;
        background-color:#F2F3F5;
        .menu_title{
          font-size:16px;
          color:rgba(2,1,6,1);
          line-height:25px
        }
      }
      .pf_info{
        flex: 1;
        padding: 10px;
        background: #FFFFFF;
        .info_rows{
          padding: 7px 0;
          text-align: left;
          font-size: 14px;
          line-height: 28px;
          span{
            color: #ab0f3a;
            &:first-child{
              margin-right: 7px;
              display: inline-block;
              width: 80px;
              font-weight: bold;
              color: #444444;
            }
          }
        }
      }
    }

    .submit_info{
      .tip{
        font-size:16px;
        font-weight:500;
        color:rgba(0,0,0,0.85);
        line-height:32px;
        margin: 15px 0px;
      }
    }
  }
</style>

<style rel="stylesheet/scss" lang="scss">
  .openPlatForm {
    .pf_content{

      .el-submenu__title{
        font-size:16px;
        color:rgba(2,1,6,1);
        line-height:56px;
        text-align: right;
        padding-right: 80px;
      }

      .el-menu-item {
        color:rgba(95,100,100,1);
        line-height:50px;
        text-align: right;
      }

      /*.el-submenu__title{*/
      /*  width: 220px;*/
      /*}*/

      .el-menu-item:hover{
        background-color: #f2f3f5 !important;
        color: #1240C0;
      }
      .el-submenu__title{
        background-color: #f2f3f5 !important;
      }
    }

    .el-button {
      font-size: 15px;
      font-weight: 400;
      color: rgba(18, 64, 192, 1);
      width: 120px;
      height: 38px;
      border-radius: 24px;
      opacity: 0.94;
      padding: 10px 16px;
    }

    .pf_dialog{
      .el-dialog{
        width: 512px !important;
        border-radius: 20px;
        margin-top: 28vh !important;
      }

      .el-dialog__body {
        width: 512px;
        padding: 20px 35px 20px 30px!important;
      }

      .el-dialog__footer{
        text-align: center;
        .el-button{
          width: 90px;
          height: 38px;
          border-radius:10px;
          font-size:14px;
          font-weight:400;
          color:rgba(128,128,128,1);
          letter-spacing:2px;
        }
        .el-button.el-button--primary{
          color:rgba(255,255,255,1);
        }
        .el-button--primary {
          color: #FFF;
          background-color: rgba(18,64,192,1);
          border-color: rgba(18,64,192,1);
        }
      }
    }

  .el-submenu .el-menu-item {
    // padding-left: 95px !important;
  }

  .el-input__inner {
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #F2F3F5;
  }

    .submit_info {
      .el-icon-success {
        color: #52C41A;
        font-size: 70px;
      }

      .el-dialog{
        width: 340px !important;
        border-radius: 16px;
        margin-top: 28vh !important;
      }

      .el-dialog__body {
        width: 340px;
        padding: 10px 30px 20px 30px !important;
      }
    }
  }
</style>
