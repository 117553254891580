
const isDuringDate = (startDateStr, endDateStr) => {
  const curDate = new Date().getTime()
  const starArr = startDateStr.split(/[- : /]/)
  const endArr = endDateStr.split(/[- : /]/)
  const startDate = new Date(starArr[0], starArr[1] - 1, starArr[2], starArr[3], starArr[4], starArr[5]).getTime()
  const endDate = new Date(endArr[0], endArr[1] - 1, endArr[2], endArr[3], endArr[4], endArr[5]).getTime()
  return curDate >= startDate && curDate <= endDate
}

const getNowDate = () => {
  const curDate = new Date()
  const year = curDate.getFullYear()
  let month = curDate.getMonth() + 1
  let date = curDate.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }
  return `${year}/${month}/${date}`
}

module.exports = {
  isDuringDate,
  getNowDate
}
