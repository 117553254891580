<template>
  <div class="productResource">
    <div class="product_res_title">
      <img src="../assets/images/productResource/pd_res_title.png"/>
      <div class="title_words">
        <p class="title_f">
          运力资源共享平台
        </p>
        <p class="title_tag">
          线上管理运力资产，轻松解决异常问题
        </p>
        <el-button @click="applyClick"> 立即试用</el-button>
      </div>
    </div>
    <div class="pd_content">
      <div class="pd_detail">
        <div class="item">
          <img class="item_img" src="../assets/images/productResource/pd_res_1.png"/>
          <div class="item_desc">
            <p class="label_1">派件轨迹实时监控</p>
            <p class="label_2 pt28">随时了解异常派件轨迹问题，提升派件时效</p>
          </div>
        </div>
        <div class="item" style="padding-top: 40px; ">
          <img class="item_img" src="../assets/images/productResource/pd_res_2.png"/>
          <div class="item_desc">
            <p class="label_1">运力资产轻松管理</p>
            <p class="label_2 pt28">线上实现运力资产盘点与分配，避免线下管理混乱情况发生</p>
          </div>
        </div>
        <div class="item" style="padding-top: 40px; ">
          <img class="item_img" src="../assets/images/productResource/pd_res_3.png"/>
          <div class="item_desc">
            <p class="label_1">运营数据实时查看</p>
            <p class="label_2 pt28">派送、签收数据实时掌控，异常问题定位及时解决</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pd_content_2">
      <div class="pd_show">
        <img class="show_img" src="../assets/images/productResource/pd_res_4.png"/>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'productResource',
  methods: {
    applyClick () {
      this.$router.push({
        path: '/contact'
      })
    }
  }

}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .productResource{
    width: 100%;
    .product_res_title {
      width: 100%;
      position: relative;
      img{
        width: 100%;
      }
      .title_words {
        width: 100%;
        padding-left: 20%;
        top: calc(50% - 92px);
        text-align: left;
        position: absolute;

        .title_f {
          font-size: 30px;
          color: rgba(255, 255, 255, 1);
          line-height: 55px;
          letter-spacing: 8px;
        }

        .title_tag {
          margin-top: 20px;
          margin-bottom: 40px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 32px;
          letter-spacing: 4px;
        }
      }
    }

    .pd_content{
      background: #FBFBFB;
      .pd_detail{
        margin: 0 auto;
        padding: 50px 0 80px 0px ;
        width: 1100px;
        .item{
          height: 350px;
          display: flex;
          .item_img{
            width: 840px;
          }
          .item_desc{
            width: 335px;
            padding-top: 100px;
            padding-left: 25px;
            padding-right: 20px;
            text-align: left;
            background: #FFFFFF;
            .label_1{
              font-size:22px;
              font-weight:500;
              color:rgba(0,0,0,1);
              line-height:30px;
              letter-spacing:2px;
            }
            .pt28{
              padding-top: 28px;
            }
            .label_2{
              font-size:15px;
              font-weight:400;
              color:rgba(102,102,102,1);
              line-height:30px;
              letter-spacing:2px;
            }
          }
        }
      }
    }

    .pd_content_2 {
      background: #ffffff;
      .pd_show{
        width: 1100px;
        margin: 0 auto;
        padding: 80px 0;
        .show_img{
          width: 100%;
          height: 420px;
        }
      }
    }
  }
</style>

<style rel="stylesheet/scss" lang="scss">
  .productResource{
    .el-button {
      font-size: 15px;
      font-weight: 400;
      color: rgba(18, 64, 192, 1);
      width: 120px;
      height: 38px;
      border-radius: 24px;
      opacity: 0.94;
      padding: 10px 16px;
    }
  }
</style>
