<template>
  <div class="qrcode-section">
    <vue-qr
        class="app-qr-code"
        :size="qrcodeOption.wid"
        :correctLevel="3"
        :text="qrcodeOption.url"
        :logoScale=".25"
        :logoMargin="5"
        logoBackgroundColor="white"
        :margin="0" >
    </vue-qr>
    <div v-if="qrcodeOption.iconUrl" class="qr-logo" :style="{'width': qrcodeOption.imgwid + 'px', 'height': qrcodeOption.imghei + 'px'}" >
      <img :src="qrcodeOption.iconUrl" alt="logo">
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
export default {
  name: 'vueQrcodes',
  props: {
    qrcodeOption: {
      type: Object,
      default: function () {
        return {
          url: '',
          iconUrl: '',
          wid: '80',
          hei: '80',
          imgwid: '20',
          imghei: '20'
        }
      }
    }
  },
  components: {
    VueQr
  },
  created () {

  }
}
</script>

<style scoped lang="scss">
  .qrcode-section {
    position: relative;
    width: 100%;
    .qr-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
