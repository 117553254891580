import { render, staticRenderFns } from "./productResource.vue?vue&type=template&id=01abcfd2&scoped=true&"
import script from "./productResource.vue?vue&type=script&lang=js&"
export * from "./productResource.vue?vue&type=script&lang=js&"
import style0 from "./productResource.vue?vue&type=style&index=0&id=01abcfd2&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true&"
import style1 from "./productResource.vue?vue&type=style&index=1&id=01abcfd2&prod&rel=stylesheet%2Fscss&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01abcfd2",
  null
  
)

export default component.exports