<template>
  <div class="productData">
    <div class="product_data_title">
      <img src="../assets/images/productData/productDataTitle.png"/>
      <div class="title_words">
        <p class="title_f">
          易拣通数据大屏
        </p>
        <p class="title_tag">
          展示各环节数据，实现集中监控，辅助管理员统一调度
        </p>
        <el-button @click="applyClick"> 立即试用</el-button>
      </div>
    </div>
    <div class="pd_content">
      <div class="pd_detail">
        <div class="item">
          <img class="item_img" src="../assets/images/productData/product_data_1.png"/>
          <div class="item_desc">
            <p class="label_1">多网点数据接入</p>
            <p class="label_1">实时数据监控</p>
            <p class="label_2 pt28">异构数据源整合，可轻松接入网点各个业务系统，彻底打破数据孤岛</p>
          </div>
        </div>
        <div class="item" style="padding-top: 40px; ">
          <img class="item_img" src="../assets/images/productData/product_data_2.png"/>
          <div class="item_desc">
            <p class="label_1">实时扫描数据监控</p>
            <p class="label_2 pt28">到件扫描统计</p>
            <p class="label_2">派件扫描统计</p>
            <p class="label_2">签收扫描统计</p>
          </div>
        </div>
        <div class="item" style="padding-top: 40px; ">
          <img class="item_img" src="../assets/images/productData/product_data_3.png"/>
          <div class="item_desc">
            <p class="label_1">快件包裹趋势展示</p>
            <p class="label_2 pt28">到件趋势</p>
            <p class="label_2 ">派件趋势</p>
            <p class="label_2 ">签收趋势</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'productData',
  methods: {
    applyClick () {
      this.$router.push({
        path: '/contact'
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .productData{
    width: 100%;
    .product_data_title {
      position: relative;
      img{
        width: 100%;
      }
      .title_words {
        width: 100%;
        padding-left: 20%;
        top: calc(50% - 92px);
        text-align: left;
        position: absolute;

        .title_f {
          font-size: 30px;
          color: rgba(255, 255, 255, 1);
          line-height: 55px;
          letter-spacing: 8px;
        }

        .title_tag {
          margin-top: 20px;
          margin-bottom: 40px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 32px;
          letter-spacing: 4px;
        }
      }
    }

    .pd_content{
      background: #F5F5F5;
      .pd_detail{
        margin: 0 auto;
        padding: 50px 0 80px 0px ;
        width: 1100px;
        .item{
          height: 350px;
          display: flex;
          .item_img{
            width: 840px;
          }
          .item_desc{
            width: 335px;
            padding-top: 100px;
            padding-left: 25px;
            padding-right: 20px;
            text-align: left;
            background: #FFFFFF;
            .label_1{
              font-size:22px;
              font-weight:500;
              color:rgba(0,0,0,1);
              line-height:30px;
              letter-spacing:2px;
            }
            .pt28{
              padding-top: 28px;
            }
            .label_2{
              font-size:15px;
              font-weight:400;
              color:rgba(102,102,102,1);
              line-height:30px;
              letter-spacing:2px;
            }
          }
        }
      }
    }
  }
</style>

<style rel="stylesheet/scss" lang="scss">
  .productData{
    .el-button {
      font-size: 15px;
      font-weight: 400;
      color: rgba(18, 64, 192, 1);
      width: 120px;
      height: 38px;
      border-radius: 24px;
      opacity: 0.94;
      padding: 10px 16px;
    }
  }
</style>
