<template>
  <div></div>
</template>

<script>
import { queryArea } from '../../assets/js/api.js'
export default {
  name: 'mixin',
  data () {
    return {
      options: [],
      props: {
        value: 'id',
        label: 'name',
        children: 'children'
      }
    }
  },
  methods: {
    getNodes (val) {
      // 获取城市（二级）
      if (val.length === 1) {
        this.getCity(val[0])
        // 获取地区 （三级）
      } else if (val.length === 2) {
        this.getArea(val[0], val[1])
      }
    },
    getProvince (cb) {
      const param = {
        parentId: 0,
        pageFlag: false
      }
      queryArea(param).then((res) => {
        if (res.code === 200) {
          res.data.resultData.map(item => {
            this.$set(item, 'name', item.name)
            this.$set(item, 'children', [])
          })
          this.options = res.data.resultData
        } else {
          console.log(res.data.msg)
        }
        cb && cb()
      }, error => {
        console.log(error)
      })
    },
    // 查询市
    getCity (provinceId, cb) {
      const param = {
        parentId: provinceId,
        pageFlag: false
      }
      queryArea(param).then(res => {
        if (res.code === 200) {
          res.data.resultData.map(item => {
            this.$set(item, 'name', item.name)
            this.$set(item, 'children', [])
          })
          this.options.map(item => {
            if (item.id === provinceId) {
              item.children = res.data.resultData
            }
          })
        } else {
          this.$message.error(res.msg)
        }
        cb && cb()
      })
    },
    // 查询区县
    getArea (provinceId, cityCode, cb) {
      const param = {
        parentId: cityCode,
        pageFlag: false
      }
      queryArea(param).then(res => {
        if (res.code === 200) {
          res.data.resultData.map(item => {
            this.options.map(item => {
              if (item.id === provinceId) {
                item.children.map(city => {
                  city.children = res.data.resultData
                })
              }
            })
          })
        } else {
          this.$message.error(res.msg)
        }
        cb && cb()
      })
    },
    // 回显省市县
    fillCity (provinceId, cityId, areaId) {
      this.getProvince(() => {
        this.orgBaseInfor.PAC.push(provinceId)
        this.getCity(provinceId, () => {
          this.orgBaseInfor.PAC.push(cityId)
          this.getArea(provinceId, cityId, () => {
            this.orgBaseInfor.PAC.push(areaId)
          })
        })
      })
    }
  },
  created () {
    this.getProvince()
  }
}
</script>
