<template>
  <div class="supply-plateform">
    <div class="plateform-content">
      <div class="publish-btn">
        <div class="btn" @click="publishInfor">发布</div>
      </div>
      <div class="publish-list">
        <div class="list-item" v-for="(item, index) in supplyInforList" :key="index">
          <div class="supply-infor">
            <div class="infor-detail">
              <div class="infor-sup">{{item.cityName}}<span class="seprate">|</span>{{item.infoType | formateInforType}}</div>
              <div class="infor-middle">
                <span class="infor-use">{{item.use}}</span>
                <span class="infor-storage" v-if="item.infoType === 1">{{item.storageArea}}</span>
                <span class="infor-time">{{item.startTime}} ~ {{item.endTime}}</span>
                <span class="infor-phone">联系电话：{{item.phone}}</span>
              </div>
              <div class="infor-bottom">
                <div class="infor-address">{{item | formateAddress}}</div>
                <div class="infor-remark" v-if="item.comments">备注：{{item.comments}}</div>
              </div>
            </div>
            <div class="infor-price">{{item.price}}</div>
          </div>
          <div class="create-time">{{item.createTime}}</div>
        </div>
      </div>
      <table-pagination :total="total" @currentPagination="currentPagination" @currentSizeChange="currentSizeChange" @getData="getSupplyInforList" :pagination="pagination"></table-pagination>
    </div>
    <el-dialog :visible.sync="publishDialog" width="554px" top="20px" custom-class="publish-dialog" :close-on-click-modal="false" @close="closeDialog">
      <el-form ref="publishForm" :model="publishFormData" label-width="110px" :rules="rules">
        <el-form-item label="发布信息类型" prop="infoType">
          <el-select v-model="publishFormData.infoType" placeholder="发布信息类型" style="width: 275px">
            <el-option v-for="item in inforTypeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库面积" prop="storageArea" v-if="publishFormData.infoType === 1">
          <el-input v-model="publishFormData.storageArea" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="车辆类型" prop="carClass"  v-if="publishFormData.infoType === 0">
          <el-input v-model="publishFormData.carClass" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="用途" prop="use">
          <el-input v-model="publishFormData.use" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="publishFormData.price" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="使用时间" prop="useTime">
          <el-date-picker
            style="width: 275px"
            v-model="publishFormData.useTime"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="onDataRangeChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="信息截至时间" prop="expireTime">
          <el-date-picker
            style="width: 275px"
            v-model="publishFormData.expireTime"
            type="date"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            placeholder="截至日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="publishFormData.phone" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="图形验证码" prop="imgCode">
          <el-input v-model="publishFormData.imgCode" clearable class="img-wrap" placeholder="请输入"></el-input>
          <img v-if='imgSrc' class="code-img" :src="imgSrc"  @click="getCaptchCode">
        </el-form-item>
        <el-form-item label="短信验证码" prop="smsLoginCode">
          <el-input v-model="publishFormData.smsLoginCode" clearable class="img-wrap" placeholder="请输入"></el-input>
          <el-button type="text" @click="getSmsCode" v-if="sendFlag">发送验证码</el-button>
          <el-button type="text" v-else>{{timer}}s</el-button>
        </el-form-item>
        <el-form-item label="地址" prop="PAC">
          <el-cascader
            style="width: 275px"
            v-model="publishFormData.PAC"
            :options="options"
            @active-item-change="getNodes"
            @change="areaChange"
            :props="props"
            class="mr8 cascader-icon"
            clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="addr">
          <el-input v-model="publishFormData.addr" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="publishFormData.comments" maxlength="100" show-word-limit placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item size="middle">
          <el-button type="primary" class="publish-btn confirm" @click="validateInfor">确认发布</el-button>
          <el-button class="publish-btn cancel" @click="cancelPublish">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import tablePagination from '@/components/tablePagination.vue'
import { querySupply, captchCode, smsCode, saveInfo } from '../../assets/js/api.js'
import mixins from '../shareLink/mixin.vue'
export default {
  name: 'supplyPlateform',
  mixins: [mixins],
  components: {
    tablePagination
  },
  data () {
    return {
      supplyInforList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        sizes: [10, 15, 30, 45, 60]
      },
      total: 0,
      publishDialog: false,
      inforTypeList: [
        { key: 0, value: '我要找车' },
        { key: 1, value: '我要找仓库' }
      ],
      publishFormData: {
        infoType: 0,
        carClass: '',
        storageArea: '',
        use: '',
        price: '',
        useTime: '',
        startTime: '',
        endTime: '',
        expireTime: '',
        phone: '',
        imgCode: '',
        smsLoginCode: '',
        smsToken: '',
        PAC: '',
        provinceName: '',
        cityName: '',
        areaName: '',
        addr: '',
        comments: ''
      },
      rules: {
        infoType: [{ required: true, message: '请选择发布信息类型', trigger: 'change' }],
        carClass: [{ required: true, message: '请输入车辆类型', trigger: 'blur' }, { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }],
        storageArea: [{ required: true, message: '请输入仓库面积', trigger: 'blur' }, { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }],
        use: [{ required: true, message: '请输入用途', trigger: 'blur' }, { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }, { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }],
        useTime: [{ required: true, message: '请选择使用时间', trigger: 'change' }],
        expireTime: [{ required: true, message: '请选择信息截至时间', trigger: 'change' }],
        phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }, { pattern: '^1[3|4|5|6|7|8|9][0-9]\\d{8}$', message: '请输入正确的手机号码', trigger: 'blur' }],
        imgCode: [{ required: true, message: '请输入图形验证码', trigger: 'blur' }, { pattern: '^[A-Za-z0-9]{4}$', message: '请输入正确的图形验证码', trigger: 'blur' }],
        smsLoginCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }, { pattern: '^[0-9]{4}$', message: '请输入正确的短信验证码', trigger: 'blur' }],
        PAC: [{ required: true, message: '请选择地址', trigger: 'change' }],
        addr: [{ required: true, message: '请输入详细地址', trigger: 'blur' }, { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }],
        comments: [{ required: false }]
      },
      captchaToken: '',
      imgSrc: '',
      timer: 60,
      sendFlag: true,
      isPublishing: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      authTimer: null
    }
  },
  methods: {
    currentSizeChange (pagination) {
      this.getSupplyInforList(pagination)
    },
    currentPagination (pagination) {
      this.getSupplyInforList(pagination)
    },
    getSupplyInforList (pagination) {
      this.pagination = pagination || this.pagination
      const params = {
        pageNo: this.pagination.pageNo,
        limit: this.pagination.pageSize
      }
      querySupply(params).then(res => {
        if (res.code === 200) {
          this.supplyInforList = res.data.resultData
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    getCaptchCode () {
      captchCode().then(res => {
        if (res.code === 200) {
          this.captchaToken = res.data.captchaToken
          this.imgSrc = `data:image/jpeg;base64,${res.data.img}`
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    getSmsCode () {
      const phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/
      const captchaReg = /^[A-Za-z0-9]{4}$/
      if (!phoneReg.test(this.publishFormData.phone)) {
        this.$message.error('联系电话格式错误，请确认！')
        return false
      }
      if (!captchaReg.test(this.publishFormData.imgCode)) {
        this.$message.error('图形验证码格式错误，请确认！')
        return false
      }
      const params = {
        captchaToken: this.captchaToken,
        imgCode: this.publishFormData.imgCode,
        userPhone: this.publishFormData.phone
      }
      smsCode(params).then(res => {
        if (res.code === 200) {
          this.publishFormData.smsToken = res.data
          this.$message.success('短信发送成功！')
          this.countdown()
        } else {
          this.$message.error(res.msg)
          this.getCaptchCode()
        }
      }).catch(err => {
        this.getCaptchCode()
        this.$message.error(err)
      })
    },
    countdown () {
      this.sendFlag = false
      this.authTimer = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          this.sendFlag = true
          this.timer = 60
          this.getCaptchCode()
          clearInterval(this.authTimer)
        }
      }, 1000)
    },
    publishInfor () {
      for (const k in this.publishFormData) {
        if (k === 'infoType') {
          this.publishFormData[k] = 0
        } else {
          this.publishFormData[k] = ''
        }
      }
      this.getCaptchCode()
      this.publishDialog = true
      this.sendFlag = true
      clearInterval(this.authTimer)
      this.timer = 60
    },
    closeDialog () {
      this.$refs.publishForm.resetFields()
    },
    onDataRangeChange (val) {
      this.publishFormData.startTime = val[0]
      this.publishFormData.endTime = val[1]
    },
    areaChange (val) {
      const province = this.options.find(prov => prov.id === val[0])
      const city = province.children.find(city => city.id === val[1])
      const area = city.children.find(area => area.id === val[2])
      this.publishFormData.provinceName = province.name
      this.publishFormData.cityName = city.name
      this.publishFormData.areaName = area.name
    },
    cancelPublish () {
      this.publishDialog = false
    },
    validateInfor () {
      if (this.isPublishing) {
        return false
      }
      this.$refs.publishForm.validate((valid) => {
        if (valid) {
          this.savePublishInfor()
        }
      })
    },
    savePublishInfor () {
      this.isPublishing = true
      saveInfo(this.publishFormData).then(res => {
        if (res.code === 200) {
          this.$message.success('信息发布成功!')
          this.$refs.publishForm.resetFields()
          this.publishDialog = false
          this.isPublishing = false
          this.getSupplyInforList()
        } else {
          this.isPublishing = false
          this.$message.error(res.msg)
          clearInterval(this.authTimer)
          this.timer = 60
          this.sendFlag = true
          this.getCaptchCode()
        }
      }).catch(err => {
        this.$message.error(err)
      })
    }
  },
  filters: {
    formateInforType (val) {
      return val ? '找仓库' : '找车'
    },
    formateAddress (val) {
      return `${val.provinceName}${val.cityName}${val.areaName}${val.addr}`
    }
  },
  created () {
    this.getSupplyInforList()
  }
}
</script>
<style lang="scss">
  .publish-dialog {
    .el-form {
      width: 385px;
      margin: 0 auto;
      .el-input.img-wrap {
        width: 147px;
        float: left;
      }
      .el-form-item__label {
        line-height: 36px;
      }
      .el-form-item__content {
        line-height: 36px;
        .el-input__inner {
          height: 36px;
          line-height: 36px;
        }
      }
    }
    .publish-btn {
      width: 120px;
      height: 36px;
      padding: 0;
      &.confirm {
        background-color: #0858D7;
      }
      &.cancel {
        color:#0858D7;
        border-color: #0858D7;
      }
    }
    .el-form-item.el-form-item--middle {
      margin-bottom: 0px;
    }
  }
</style>
<style lang="scss" scoped>
  .supply-plateform {
    min-height: calc(100vh - 74px);
    background: #F9F9F9;
    .plateform-content {
      width: 1200px;
      margin: 0 auto;
      .publish-btn {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0;
        .btn {
          width: 90px;
          height: 32px;
          border-radius: 4px;
          line-height: 32px;
          text-align: center;
          font-size: 14px;
          color: #fff;
          background-color: #0858D7;
          cursor: pointer;
        }
      }
      .publish-list {
        text-align: left;
        .list-item {
          padding: 28px 40px 32px 40px;
          background-color: #fff;
          margin-bottom: 20px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .supply-infor {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            align-items: center;
            border-right: 1px solid #D0D0D0;
            .infor-detail {
              .infor-sup {
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
                color: #333333;
                .seprate {
                  padding: 0 20px;
                }
              }
              .infor-middle {
                padding: 20px 0;
                line-height: 20px;
                color: #333333;
                span {
                  margin-right: 30px;
                }
              }
              .infor-bottom {
                line-height: 20px;
                color: #666666;
                .infor-remark {
                  margin-top: 15px;
                  max-width: 600px;
                }
              }
            }
            .infor-price {
              text-align: right;
              padding-right: 40px;
              color: #FF552E;
              font-size: 20px;
              font-weight: 600;
            }
          }
          .create-time {
            padding-left: 39px;
            padding-right: 50px;
            color: #666666;
          }
        }
      }
    }
  }
  .code-img {
    width: 108px;
    height: 36px;
    margin-left: 20px;
  }
</style>
