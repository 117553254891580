<template>
  <div class="delivery_system">
    <div class="delivery_title">
      <img src="../assets/images/delivery/delivery_title.png"/>
      <div class="title_words">
        <p class="title_f">
          易拣通共配系统
        </p>
        <p class="title_tag">
          多家快递公司包裹的到、派、签，实现扫码数据无缝的回传
        </p>
        <el-button @click="applyClick"> 立即试用</el-button>
      </div>
    </div>

    <div class="delivery_content">
      <div class="content_words">
        <span class="line"></span>
        <span class="text">系统特色，助力快递网点</span>
        <span class="line"></span>
      </div>
      <div >
      <div class="content_img">
        <div class="item item_1">
          <div class="mask_bg"></div>
          <div class="d_label">
          <p class="label_1">到派签一体化</p>
          <p class="label_2">对接圆通、百世、申通、韵达、中通等快递公司的系统，实现扫码数据无缝的回传。数据上传准确率100%。</p>
          </div>
        </div>
        <div class="item item_2" style="margin-left: 24px">
          <div class="mask_bg"></div>
          <div class="d_label">
            <p class="label_1">自动化分拣</p>
            <p class="label_2">与知名分拣设备厂共同研发自动化分拣设备、实现大规模海量订单的自动分拣任务，分拣数据会同步回传快递系统。</p>
          </div>
        </div>
      </div>
      <div class="content_img" style="padding-top: 30px">
        <div class="item item_3" >
          <div class="mask_bg"></div>
          <div class="d_label">
            <p class="label_1">BI报表</p>
            <p class="label_2">网点管理员可以看到每天的扫码数据与不同用户的扫描统计、可个性化定制。</p>
          </div>
        </div>
        <div class="item item_4" style="margin-left: 24px">
          <div class="mask_bg"></div>
          <div class="d_label">
            <p class="label_1">问题件上报</p>
            <p class="label_2">对接圆通、百世、申通、韵达、中通等快递公司的系统，实现扫码数据无缝的回传。数据上传准确率100%。</p>
          </div>
        </div>
      </div>

      <div class="content_2">
        <p class="title">合作优势，降本增效助力网点</p>

        <el-tabs :tab-position="tabPosition">
          <el-tab-pane label="降本增效">
            <img style="width:100%;" src="../assets/images/delivery/delivery_tabs.png"/>
          </el-tab-pane>
          <el-tab-pane label="一个APP">
            <img style="width:100%;" src="../assets/images/delivery/delivery_tabs.png"/>
          </el-tab-pane>
          <el-tab-pane label="统一数据管理">
            <img style="width:100%;" src="../assets/images/delivery/delivery_tabs.png"/>
          </el-tab-pane>
          <el-tab-pane label="一把扫描枪">
            <img style="width:100%;" src="../assets/images/delivery/delivery_tabs.png"/>
          </el-tab-pane>
          <el-tab-pane label="账号统一登录">
            <img style="width:100%;" src="../assets/images/delivery/delivery_tabs.png"/>
          </el-tab-pane>
          <el-tab-pane label="数据无缝回传">
            <img style="width:100%;" src="../assets/images/delivery/delivery_tabs.png"/>
          </el-tab-pane>
        </el-tabs>
      </div>

      </div>

    </div>
    <div class="delivery_content_2">
      <div class="system_show">
        <p class="title">
          运筹帷幄，共配系统展示
        </p>
        <div class="system_show_img">
          <img src="../assets/images/delivery/delivery_item1.png"/>
          <img src="../assets/images/delivery/delivery_item2.png"/>
        </div>
        <div class="system_show_img">
          <img src="../assets/images/delivery/delivery_item3.png"/>
          <img src="../assets/images/delivery/delivery_item4.png"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'deliverySystem',
  data () {
    return {
      tabPosition: 'bottom'
    }
  },
  methods: {
    applyClick () {
      this.$router.push({
        path: '/contact'
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .delivery_system {
    width: 100%;
    .delivery_title {
      img{
        width: 100%;
      }
      position: relative;
      .title_words {
        width: 100%;
        padding-left: 20%;
        top: calc(50% - 92px);
        text-align: left;
        position: absolute;

        .title_f {
          font-size: 30px;
          color: rgba(255, 255, 255, 1);
          line-height: 55px;
          letter-spacing: 8px;
        }

        .title_tag {
          margin-top: 20px;
          margin-bottom: 40px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          line-height: 32px;
          letter-spacing: 4px;
        }
      }

    }

    .delivery_content {
      width: 1100px;
      margin: 0 auto;
      .content_words {
        text-align: center;
        margin: 55px 0px 30px 0px;
        /*.line {*/
        /*  width: 371px;*/
        /*  display: inline-block;*/
        /*  border-top: 0.5px solid #CBD0D6;*/
        /*  vertical-align: 5px;*/
        /*}*/
        .text{
          margin: 0 62px;
          font-size:22px;
          font-weight:500;
          color:rgba(2,1,6,1);
          line-height:37px;
          letter-spacing:3px;
        }
      }

      .content_img{
        display: flex;
        margin: 0 auto;

        .item{
          background-size: cover;
          text-align: left;
          width: 588px;
          height: 360px;
          position: relative;
          overflow: hidden;
          .mask_bg{
            position: absolute;
            background: #0452D9;
            box-sizing: border-box;
            transform: translate(0, 360px);
          }
          .d_label{
            padding: 100px 60px 0 60px;
            .label_1{
              padding-bottom: 30px;
              font-size:24px;
              color:rgba(255,255,255,1);
              opacity: 0;
              line-height:42px;
              letter-spacing:4px;
              transform: translate(0, 80px);
              font-weight: 600;
            }
            .label_2{
              font-size:15px;
              font-weight:400;
              color:rgba(255,255,255,70);
              line-height:32px;
              letter-spacing:3px;
              opacity: 0;
              transform: translate(0, 80px);
            }
          }
        }

        .item_1{
          background-image: url("../assets/images/delivery/delivery_1.png");
        }
        .item_2{
          background-image: url("../assets/images/delivery/delivery_2.png");
        }
        .item_3{
          background-image: url("../assets/images/delivery/delivery_3.png");
        }
        .item_4{
          background-image: url("../assets/images/delivery/delivery_3.png");
        }

        .item_1:hover{
         .mask_bg {
           width: 100%;
           height: 100%;
           transform: translate(0, 0px);
           transition: all .5s cubic-bezier(0.2, 0.0, 0.2, 1);
           -webkit-transition: all .5s cubic-bezier(0.2, 0.0, 0.2, 1);
         }
         .label_1 {
           opacity: 1;
           transform: translate(0, 30px);
           transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
           -webkit-transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
         }
         .label_2{
           opacity: 1;
           transform: translate(0, 30px);
           transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
           -webkit-transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
         }
       }

        .item_2:hover{
          .mask_bg {
            width: 100%;
            height: 100%;
            transform: translate(0, 0px);
            transition: all .5s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: all .5s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
          .label_1 {
            opacity: 1;
            transform: translate(0, 30px);
            transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
          .label_2{
            opacity: 1;
            transform: translate(0, 30px);
            transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
        }

        .item_3:hover{
          .mask_bg {
            width: 100%;
            height: 100%;
            transform: translate(0, 0px);
            transition: all .5s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: all .5s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
          .label_1 {
            opacity: 1;
            transform: translate(0, 30px);
            transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
          .label_2{
            opacity: 1;
            transform: translate(0, 30px);
            transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
        }

        .item_4:hover{
          .mask_bg {
            width: 100%;
            height: 100%;
            transform: translate(0, 0px);
            transition: all .5s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: all .5s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
          .label_1 {
            opacity: 1;
            transform: translate(0, 30px);
            transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
          .label_2{
            opacity: 1;
            transform: translate(0, 30px);
            transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
            -webkit-transition: transform .5s cubic-bezier(0.2, 0.0, 0.2, 1), opacity .3s cubic-bezier(0.2, 0.0, 0.2, 1);
          }
        }
    }

      .content_2{
        margin: 75px 0px 65px 0px;
        .title{
          text-align: left;
          padding-left: 5px;
          padding-bottom: 30px;
          font-size:22px;
          font-weight:500;
          color:rgba(2,1,6,1);
          line-height:37px;
          letter-spacing:3px;
        }
      }
  }
    .delivery_content_2{
      background: #FBFBFB;
      padding-bottom: 90px;
      .system_show{
        width: 1100px;
        margin: 0 auto;
        .title{
          text-align: left;
          padding:55px 0 35px 5px;
          font-size:22px;
          font-weight:500;
          color:rgba(2,1,6,1);
          line-height:37px;
          letter-spacing:3px;
        }
        .system_show_img{
          display: flex;
          img{
            width: 550px;
          }
        }
      }
    }

}
</style>

<style rel="stylesheet/scss" lang="scss">
  .delivery_system {

    .el-button {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(18, 64, 192, 1);
      width: 120px;
      height: 38px;
      border-radius: 24px;
      opacity: 0.94;
      padding: 10px 16px;
    }

    .el-tabs__item {
      width: 183.4px;
      height: 100px;
      text-align: center;
      background:#F2F3F5;
      font-size:18px;
      font-weight:500;
      color:rgba(2,1,6,1);
      line-height:100px;
      letter-spacing:2px
    }

    .el-tabs__item:hover {
      color: #020106;
      background: #FBFBFB;
      cursor: pointer;
    }

    .el-tabs__item.is-active {
      /*background: #FBFBFB;*/
      color:rgba(2,1,6,1);
    }

    .el-tabs__active-bar {
      width: 0px !important;
    }

    .el-tabs__item {
      padding: 0 20px !important;
    }

    .el-tabs--bottom .el-tabs__header.is-bottom{
      margin-top: 0;
    }
  }
</style>
