<template>
<div class="login" v-loading.fullscreen.lock="loading">
  <el-dialog
    :visible.sync="loginVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeLogin"
    width="500px">
    <div class="login-section">
      <div class="title">账号登录</div>
      <div class="login-form">
        <div class="input-item">
          <img class="icon" :class="{'active': formLogin.phone}" src="../assets/images/send/icon_phone.png" alt="phone">
          <el-input v-model="formLogin.phone" placeholder="请输入手机号" maxlength="11" type="tel"></el-input>
        </div>
      </div>
      <div class="login-form">
        <div class="input-item">
          <img class="icon" :class="{'active': formLogin.captchaCode}" src="../assets/images/send/icon_code.png" alt="phone">
          <el-input v-model="formLogin.captchaCode" placeholder="请输入验证码" maxlength="4"></el-input>
        </div>
        <div class="code-item" @click="getImgCode">
          <img :src="imgCode" alt="code">
        </div>
      </div>
      <div class="login-form">
        <div class="input-item">
          <img class="icon" :class="{'active': formLogin.code}" src="../assets/images/send/icon_msg.png" alt="phone">
          <el-input v-model="formLogin.code" placeholder="请输入短信验证码" maxlength="6"></el-input>
        </div>
        <div class="code-item">
          <el-button size="small" type="primary" :disabled="!(formLogin.phone && formLogin.captchaCode) || (smsTimer !== null)"
          @click="getSmsCode">{{btnTxt}}</el-button>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" :disabled="!(formLogin.phone && formLogin.captchaCode && formLogin.code)" @click="loginFunc" >登  录</el-button>
    </div>
    <div class="privacy">
      <span>登录即代表您同意</span>
      <span class="link">《快件物流资源共享平台隐私政策》</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { getCaptchCode, getSendSms, login } from '@/assets/js/api.js'
import { mapMutations } from 'vuex'
export default {
  name: 'login',
  data () {
    return {
      loading: false,
      loginVisible: true,
      formLogin: {
        phone: '',
        captchaCode: '',
        code: '',
        captchaToken: ''
      },
      imgCode: '',
      btnTxt: '获取验证码',
      smsTimer: null
    }
  },
  mounted () {
    this.getImgCode()
  },
  methods: {
    ...mapMutations(['updateBtnStatus']),
    getImgCode () {
      this.loading = true
      getCaptchCode().then(res => {
        if (res.code === 200) {
          this.imgCode = `data:image/jpeg;base64,${res.data.img}`
          this.formLogin.captchaToken = res.data.captchaToken
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    },
    getSmsCode () {
      if (this.smsTimer) {
        return
      }
      // 校验手机号
      const regPhone = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      if (!regPhone.test(this.formLogin.phone)) {
        this.$message.error('请输入正确格式的手机号')
        return
      }
      const params = {
        phone: this.formLogin.phone,
        captchaCode: this.formLogin.captchaCode,
        captchaToken: this.formLogin.captchaToken
      }
      getSendSms(params).then(res => {
        if (res.code === 200) {
          this.$message.success('短信验证码发送成功')
          // 开启 60s 倒计时
          this.setCountDown()
        } else {
          this.$message.error(res.msg)
          this.getImgCode()
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    },
    setCountDown () {
      let time = 60
      this.smsTimer = setInterval(() => {
        time--
        if (time > 0) {
          this.btnTxt = `${time}s 重新获取`
        } else {
          this.btnTxt = '重新获取验证码'
          clearInterval(this.smsTimer)
          this.smsTimer = null
        }
      }, 1000)
    },
    loginFunc () {
      // 校验手机号
      const regPhone = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      if (!regPhone.test(this.formLogin.phone)) {
        this.$message.error('请输入正确格式的手机号')
        return
      }
      if (!this.formLogin.captchaCode) {
        this.$message.error('请输入验证码')
        return
      }
      if (!this.formLogin.code) {
        this.$message.error('请输入短信验证码')
        return
      }
      login(this.formLogin).then(res => {
        if (res.code === 200) {
          this.$message.success('登录成功')
          localStorage.setItem('express_token', res.data.token)
          localStorage.setItem('express_isAuth', res.data.hulaUserRes.isAuth)
          this.$emit('loginResult', 'success')
          this.updateBtnStatus(true)
        } else {
          this.$message.error(res.msg)
          this.getImgCode()
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$message.error(err)
      })
    },
    closeLogin () {
      this.$emit('loginResult', 'cancel')
    }
  }
}
</script>
<style lang="scss">
.login {
  .input-item {
    .el-input__inner {
      color: #1D54C2;
      border: none;
    }
  }
  .el-button--primary {
    background-color: #1D54C2;
    border-color: #1D54C2;
    &:hover {
      background-color: rgba(29,84,194,0.8);
      border-color: rgba(29,84,194,0.8)
    }
  }
  .el-button--primary.is-disabled {
    background-color: rgba(29,84,194,0.5);
    border-color: rgba(29,84,194,0.3)
  }
  .el-button--primary.is-disabled:hover {
    color: #FFF;
    background-color: rgba(29,84,194,0.5);
    border-color: rgba(29,84,194,0.3)
  }
  .dialog-footer, .code-item {
    .el-button {
      width: 100%;
    }
    img {
      display: block;
      width: 110px;
      height: 40px;
    }
  }
}
</style>
<style scoped lang="scss">
$themeColor: #1D54C2;
.login {
  width: 100%;
  .login-section {
    width: 340px;
    margin: 0 auto;
    text-align: left;
    .title {
      margin-bottom: 40px;
      font-size: 24px;
      font-weight: 600;
      color: #606266;
    }
    .login-form {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .input-item {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        color: $themeColor;
        border-bottom: 1px solid #D8DCE6;
        &.active {
          border-bottom: 1px solid #1D54C2;
        }
        .icon {
          display: block;
          width: 24px;
          height: 24px;
          opacity: 0.3;
          &.active {
            opacity: 1;
          }
        }
        .el-input__inner {
          border: none;
        }
      }
      .code-item {
        margin-left: 10px;
        width: 110px;
        max-height: 40px;
      }
    }
  }
  .dialog-footer {
    width: 340px;
    margin: 20px auto;
  }
  .privacy {
    padding-bottom: 40px;
    font-size: 14px;
    color: #909399;
    text-align: center;
    .link {
      color: $themeColor;
      cursor: pointer;
    }
  }
}
</style>
