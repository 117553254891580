<template>
  <div class="home">
    <div class="banner">
      <!--<carousel @watchIndex="changeButton"></carousel>-->
      <!--<el-button class='btn' v-if="showButton" @click="applyClick">免费使用</el-button>-->

      <swiper :options="swiperOption" ref="mySwiper">
        <!-- slides -->
        <swiper-slide class="swiper-item">
          <div class='swiper-img swiper1'></div>
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <div class='swiper-img swiper2'></div>
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <div class='swiper-img swiper3'></div>
        </swiper-slide>
        <!-- Optional controls ,显示小点-->
        <div class="swiper-pagination1" slot="pagination"></div>
      </swiper>
      <div class="menu-icon">
        <div class="icon-section">
          <div class="icons">
            <div class="icons-item" v-for="item in iconList" :key="item.index" @click="goDetail(item.index)">
              <img :src="item.icon" alt="">
              <p>{{item.name}}</p>
            </div>
            <div class="icons-item" v-for="item in serviceTypeList" :key="item.id" @click="goService(item.id)" >
              <img :src="item.rmk1" >
              <p>{{item.serviceName}}</p>
            </div>
            <div class="icons-item" @click="goService('all')">
              <img :src="require('../assets/imgs/icon'+moreService.index+'.png')" alt="">
              <p>{{moreService.name}}</p>
            </div>
            <img class="price-bt" src="../assets/imgs/yuyi.jpg" alt="" @click="openPrice">
          </div>
          <div class="news-section">
            <div class="stripe"></div>
            <div class="title">
                <span class="name">
                  新闻速递
                </span>
              <span class="more" @click="goMore">
                  更多
                </span>
            </div>
            <ul class="content">
              <li @click="goNewsDetail(1)">除了价格战，快递企业的第三选择！</li>
              <li @click="goNewsDetail(0)">快递末端难，“共享”到底行不行？</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--解决方案-->
    <img src="../assets/imgs/title1.png" alt="" class="item-title">
    <div class="solve">
      <div>
        <ul>
          <li @click="toggleSolution('home_solution1')" :class="{'active': solution == 'home_solution1'}"
              data-id='home_solution1'>
            <p>H+</p>
            <p>多种人力资源共享服务</p>
            <img src="../assets/imgs/icon-arrow.png" alt="" v-show="solution == 'home_solution1'">
          </li>
          <li @click="toggleSolution('home_solution2')" :class="{'active': solution == 'home_solution2'}"
              data-id='home_solution2'>
            <p>T+</p>
            <p>多样运力资源共享服务</p>
            <img src="../assets/imgs/icon-arrow.png" alt="" v-show="solution == 'home_solution2'">
          </li>
          <li @click="toggleSolution('home_solution3')" :class="{'active': solution == 'home_solution3'}"
              data-id='home_solution3'>
            <p>N+</p>
            <p>多个快递末端系统兼容</p>
            <img src="../assets/imgs/icon-arrow.png" alt="" v-show="solution == 'home_solution3'">
          </li>
          <li @click="toggleSolution('home_solution4')" :class="{'active': solution == 'home_solution4'}"
              data-id='home_solution4'>
            <p>C+</p>
            <p>多重信用体系认证</p>
            <img src="../assets/imgs/icon-arrow.png" alt="" v-show="solution == 'home_solution4'">
          </li>
        </ul>
        <div>
          <lottie v-show="solution == 'home_solution1'" :options="defaultOptionsH" :height="469" :width="586"
                  v-on:animCreated="handleAnimationH"/>
          <lottie v-show="solution == 'home_solution2'" :options="defaultOptionsT" :height="469" :width="586"
                  v-on:animCreated="handleAnimationT"/>
          <lottie v-show="solution == 'home_solution3'" :options="defaultOptionsN" :height="469" :width="586"
                  v-on:animCreated="handleAnimationN"/>
          <lottie v-show="solution == 'home_solution4'" :options="defaultOptionsC" :height="469" :width="586"
                  v-on:animCreated="handleAnimationC"/>
        </div>
      </div>
      <img src="../assets/imgs/item-bg1.png" alt="" class="item-bg bg1">
      <img src="../assets/imgs/item-bg2.png" alt="" class="item-bg bg2">
    </div>
    <!--成功案例-->
    <div class="successCase">
      <img src="../assets/imgs/title3.png" alt="" class="item-title">
      <div>
        <div class="case1">
          <img src="../assets/imgs/case1.png" alt="">
          <p class="case-title">山东省共配网点</p>
          <p class="case-info">该共配网点整合周边乡镇其他网点资源，共建物流配送中心，改善了以往的配送资源松散的问题，探索出了乡村物流的新模式</p>
        </div>
        <div class="case2">
          <img src="../assets/imgs/case2.png" alt="">
          <p class="case-title">陕西省共配网点</p>
          <p class="case-info">多家网点从仓到配，对各自的物流资源进行共享，在不断降低成本，提高效率的同时，大大的降低了网点的经营成本，件量也在不断攀升</p>
        </div>
        <div class="case3">
          <img src="../assets/imgs/case3.png" alt="">
          <p class="case-title">湖北省共配网点</p>
          <p class="case-info">通过共配模式的探索，改善了之前派送效率低，人力成本高的问题，在服务质量不变的情况下，派送时效明显提高</p>
        </div>
        <div class="case4">
          <img src="../assets/imgs/case4.png" alt="">
          <p class="case-title">河北省共配网点</p>
          <p class="case-info">一个APP对多个快递运单进行到、派、签操作，自动化扫描、驿站、快递柜的对接，给予了线下业务强有力的支持</p>
        </div>
      </div>
      <p @click="goSuccessCase">
        更多案例<img src="../assets/imgs/more.png" alt="">
      </p>
      <img src="../assets/imgs/item-bg3.png" alt="" class="item-bg bg3">
      <img src="../assets/imgs/item-bg4.png" alt="" class="item-bg bg4">
    </div>
    <!--平台弹幕-->
    <div class="platform">
      <img src="../assets/imgs/title4.png" alt="" class="item-title">
      <div class="tips">共人、共物、共车 一点就知道</div>
      <div class="platform-content">
        <div class="bullet-item bullet-animation"
             @click="goPlateform"
             :data-line="item.line"
             v-for="item in bulletList"
             @animationend="animationend"
             :key="item.id">
          <div class="left">
            <img :src="item.head" alt=""/>
          </div>
          <div class="right">
            <p><span>{{item.address}}</span><span>{{item.info}}</span></p>
            <p><span>价格面议</span><span>三年以上</span></p>
          </div>
        </div>
        <!--<img src="../assets/imgs/01.png" v-animate-onscroll.repeat="{down: 'animate__animated animate__rotateInUpLeft'}" alt="">-->
        <!--<img src="../assets/imgs/02.png" v-animate-onscroll.repeat="{down: 'animate__animated animate__rotateIn'}" alt="">-->
        <!--<img src="../assets/imgs/03.png" v-animate-onscroll.repeat="{down: 'animate__animated animate__rotateInUpRight'}" alt="">-->
        <!--<img src="../assets/imgs/04.png" v-animate-onscroll.repeat="{down: 'animate__animated animate__rotateInDownRight'}" alt="">-->
        <!--<img src="../assets/imgs/05.png" v-animate-onscroll.repeat="{down: 'animate__animated animate__rotateInDownLeft'}" alt="">-->
      </div>
      <img class="platform-bg" src="../assets/imgs/platform-bg.png" alt="">
    </div>
    <!--合作单位-->
    <div class="cooperate">
      <img src="../assets/imgs/title5.png" alt="" class="item-title">
      <div class="cooperate-list">
        <img src="../assets/imgs/cooper-icon1.png" alt="">
        <img src="../assets/imgs/cooper-icon2.png" alt="">
        <img src="../assets/imgs/cooper-icon3.png" alt="">
        <img src="../assets/imgs/cooper-icon4.png" alt="">
        <img src="../assets/imgs/cooper-icon5.png" alt="">
        <img src="../assets/imgs/cooper-icon6.png" alt="">
        <img src="../assets/imgs/cooper-icon7.png" alt="">
      </div>
    </div>
    <el-dialog title="时效价格" :visible.sync="dialogTableVisible" width="800px" height="600px">
      <iframe width="800px" height="600px" src="http://114.116.255.42:8080/ersp/views/express1/price/searchFile.jsp"></iframe>
    </el-dialog>
    <!--我要申请-->
<!--    <img src="../assets/imgs/apply.png" alt="" class="apply" @click="goApply">-->
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import animationDataH from '../assets/lottie/h.json'
import animationDataN from '../assets/lottie/n.json'
import animationDataT from '../assets/lottie/t.json'
import animationDataC from '../assets/lottie/c.json'
import animationDataM1 from '../assets/lottie/map01.json'
import animationDataM23 from '../assets/lottie/map02&03.json'
import animationDataM4 from '../assets/lottie/map04.json'
import { queryTExpressServiceList } from '@/assets/js/api.js'

export default {
  name: 'Home',
  components: {
    SwiperSlide,
    Swiper
  },
  data () {
    return {
      dataList: ['yto', 'bs', 'db', 'sto', 'yd', 'tt', 'zto', 'sf'],
      solution: 'home_solution1',
      solutionBak: '',
      tabsItem: 'tab_item1',
      showButton: true,
      dialogTableVisible: false,
      iconList: [
        {
          index: 1,
          name: '寄快递',
          icon: require('../assets/imgs/icon1.png')
        },
        {
          index: 9,
          name: '寄大件',
          icon: require('../assets/imgs/icon2.png')
        }
      ],
      moreService: {
        index: 12,
        name: '更多服务'
      },
      swiperOption: {
        pagination: {
          el: '.swiper-pagination1'
        },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        }
      },
      list: [
        { id: 1, address: '西安', info: '寻找同城送货小哥', line: 0, head: require('../assets/imgs/head/01.png') },
        { id: 2, address: '咸阳', info: '寻找半挂车', line: 0, head: require('../assets/imgs/head/02.png') },
        { id: 3, address: '延安', info: '寻找牵引车', line: 0, head: require('../assets/imgs/head/03.png') },
        { id: 4, address: '铜川', info: '寻找同城送货小哥', line: 0, head: require('../assets/imgs/head/04.png') },
        { id: 5, address: '汉中', info: '寻找牵引车', line: 0, head: require('../assets/imgs/head/05.png') },
        { id: 6, address: '宝鸡', info: '寻找半挂车', line: 0, head: require('../assets/imgs/head/06.png') },
        { id: 7, address: '渭南', info: '寻找同城送货小哥', line: 0, head: require('../assets/imgs/head/07.png') },
        { id: 8, address: '安康', info: '寻找牵引车', line: 0, head: require('../assets/imgs/head/08.png') },
        { id: 9, address: '商洛', info: '寻找半挂车', line: 0, head: require('../assets/imgs/head/09.png') }
      ], // 普通的弹幕队列
      bulletList: [],
      idx: 3,
      defaultOptionsH: {
        animationData: animationDataH,
        autoplay: true
      },
      defaultOptionsN: {
        animationData: animationDataN,
        autoplay: false
      },
      defaultOptionsT: {
        animationData: animationDataT,
        autoplay: false
      },
      defaultOptionsC: {
        animationData: animationDataC,
        autoplay: false
      },
      defaultOptionsM1: {
        animationData: animationDataM1,
        autoplay: true
      },
      defaultOptionsM23: {
        animationData: animationDataM23,
        autoplay: true
      },
      defaultOptionsM4: {
        animationData: animationDataM4,
        autoplay: true
      },
      animationSpeed: 1,
      animH: {},
      animN: {},
      animT: {},
      animC: {},
      animM1: {},
      animM23: {},
      animM4: {},
      solve: true,
      interval: '',
      serviceType: 1,
      serviceTypeList: []
    }
  },
  filters: {},
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  created () {
    this.getServiceTypeList()
  },
  mounted () {
    this.swiper.slideTo(0, 1000, true)
    this.$router.beforeEach((to, from, next) => {
      if (from && from.path === 'send') {
        document.body.scrollTop = document.documentElement.scrollTop = 0
      }
      next()
    })
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
    // this.listenerFunction()
    this.interval = setInterval(() => {
      let item = null
      if (!item) {
        item = this.list.shift()
      }
      if (item) {
        item.line = this.idx
        // this.idx = (this.idx % 4 + 1);
        this.idx = Math.floor(Math.random() * 4) + 1
        this.bulletList.push(item)
      }
    }, 2000)
  },
  methods: {
    openPrice () {
      // this.dialogTableVisible = true
      this.$router.push({
        path: '/semantic'
      })
    },
    handleAnimationH: function (anim) {
      this.animH = anim
    },
    handleAnimationN: function (anim) {
      this.animN = anim
    },
    handleAnimationT: function (anim) {
      this.animT = anim
    },
    handleAnimationC: function (anim) {
      this.animC = anim
    },
    handleAnimationM1: function (anim) {
      this.animM1 = anim
    },
    handleAnimationM23: function (anim) {
      this.animM23 = anim
    },
    handleAnimationM4: function (anim) {
      this.animM4 = anim
    },
    toggleSolution (id) {
      this.solution = id
      switch (this.solution) {
        case 'home_solution1' :
          this.animH.play()
          break
        case 'home_solution2' :
          this.animT.play()
          break
        case 'home_solution3' :
          this.animN.play()
          break
        case 'home_solution4' :
          this.animC.play()
          break
      }
    },
    changeService (type) {
      this.serviceType = type
    },
    goSuccessCase () {
      this.$router.push({
        path: '/successCase'
      })
    },
    goPlateform () {
      this.$router.push({
        path: '/supplyPlatform'
      })
    },
    goMore () {
      this.$router.push({
        path: '/news/center'
      })
    },
    goNewsDetail (id) {
      this.$router.push({
        path: '/news/detail?id=' + id
      })
    },
    goDetail (idx) {
      if (idx === 3 || idx === 5 || idx === 4) {
        this.$router.push({
          path: '/supplyPlatform'
        })
      } else if (idx === 1 || idx === 9) {
        this.$router.push({
          path: '/send',
          query: {
            expressType: (idx === 1) ? 0 : 1
          }
        })
      } else {
        alert('敬请期待')
      }
    },
    goService (type) {
      this.$router.push({
        path: '/serviceList',
        query: {
          serviceType: type === 'all' ? '' : type
        }
      })
    },
    goApply () {
      this.$router.push({
        path: '/applyUse'
      })
    },
    toggleItem (e) {
      this.tabsItem = e.target.dataset.id !== undefined ? e.target.dataset.id : this.tabsItem
    },
    moreClick () {
      this.$router.push({
        path: '/productService'
      })
    },
    applyClick () {
      this.$router.push({
        path: '/contact'
      })
    },
    animationend () {
      this.list.push(this.bulletList.shift())
    },
    handleScroll () {
      const y = window.pageYOffset
      if (y < 472 && !this.solve) {
        this.solve = true
      }
      if (y >= 472 && this.solve) {
        this.solve = false
        this.animH.play()
      }
    },
    listenerFunction (e) {
      document.addEventListener('scroll', this.handleScroll, true)
    },
    getServiceTypeList () {
      const params = {
        serviceType: 1 // 一级分类
      }
      queryTExpressServiceList(params).then(res => {
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            this.calcClassify(res.data)
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    calcClassify (list) {
      let tempList = []
      if (list.length >= 8) {
        tempList = list.splice(0, 9)
      } else {
        tempList = list.splice(0, list.length)
      }
      this.serviceTypeList = tempList
    }
  },
  beforeDestroy () {
    // document.removeEventListener("scroll", this.listenerFunction);
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
  .home {
    background: #F8FBFF;
    .price-bt{
      width: 100%;
      margin-top: 5px;
      cursor: pointer;
    }
    .item-title {
      display: block;
      margin: auto;
    }
    .banner {
      position: relative;
      margin-bottom: 89px;
      .swiper-item {
        width: 100% !important;
        margin-right: 0 !important;
        .swiper-img {
          width: 100%;
          height: 648px;
          background-position: center 100%;
          background-repeat: no-repeat;
        }
        .swiper1 {
          background-image: url("../assets/imgs/banner1.png");
        }
        .swiper2 {
          background-image: url("../assets/imgs/banner2.png");
        }
        .swiper3 {
          background-image: url("../assets/imgs/banner3.png");
        }
      }
      .swiper-pagination1 {
        position: absolute;
        z-index: 10;
      }
      .menu-icon {
        width: 1200px;
        position: absolute;
        left: 50%;
        z-index: 999;
        top: 0;
        transform: translate(-50%);
        .icon-section {
          position: absolute;
          width: 328px;
          height: auto;
          background: #ffffff;
          padding: 16px 20px;
          box-sizing: border-box;
          right: 0;
          .icons {
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0;
            background: rgba( 238, 245, 254, 0.79);
            border-radius: 16px;
            .icons-item {
              width: 96px;
              text-align: center;
              margin-bottom: 15px;
              cursor: pointer;
              img {
                display: block;
                width: 36px;
                height: 36px;
                margin: 0 auto;
              }
              p {
                margin-top: 14px;
                font-size: 14px;
                color: #1240C0;
                line-height: 20px;
              }
              //&:nth-child(10), &:nth-child(11), &:nth-child(12) {
              //  margin-bottom: 0;
              //}
            }
          }
          .news-section {
            width: 100%;
            // margin-top: 20px;
            padding-top: 20px;
            .stripe {
              width: 100%;
              height: 4px;
              margin-bottom: 15px;
              background: linear-gradient(45deg, #fff 25%, #025ADC 0, #025ADC 50%, #fff 0, #fff 75%, #025ADC 0);
              background-size: 4px 4px;
            }
            .title {
              text-align: left;
              margin-bottom: 22px;
              line-height: 25px;
              .name {
                color: #1240C0;
                font-size: 18px;
                font-weight: 600;
              }
              .more {
                color: #909399;
                font-size: 14px;
                float: right;
                cursor: pointer;
              }
            }
            .content {
              text-align: left;
              font-size: 14px;
              > li {
                cursor: pointer;
                line-height: 20px;
                margin-bottom: 12px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .solve {
      width: 1120px;
      box-sizing: border-box;
      margin: auto;
      position: relative;
      margin-top: 69px;
      > div {
        padding: 40px;
        width: 1120px;
        box-sizing: border-box;
        background: #ffffff;
        display: flex;
        position: relative;
        z-index: 2;
        ul {
          width: 368px;
          .active {
            background: #E9F2FF;
          }
          li {
            cursor: pointer;
            color: #025ADC;
            padding: 12px 21px 12px 72px;
            position: relative;
            height: 120px;
            box-sizing: border-box;
            border-radius: 12px;
            > p {
              text-align: left;
              font-size: 20px;
              font-weight: 600;
              &:first-child {
                font-size: 60px;
                font-weight: 400;
                margin-bottom: 5px;
                font-family: YouSheBiaoTiHei;
              }
            }
            img {
              position: absolute;
              display: block;
              width: 32px;
              height: 32px;
              top: 44px;
              left: 20px;
            }
          }
        }
        > div {
          flex: 1;
        }
      }
      .item-bg {
        position: absolute;
      }
      .bg1 {
        left: -140px;
        bottom: -50px;
      }
      .bg2 {
        right: -140px;
        top: -50px;
      }
    }
    .successCase {
      width: 1120px;
      margin: auto;
      padding-top: 89px;
      position: relative;
      > div {
        margin-top: 95px;
        display: flex;
        z-index: 2;
        position: relative;
        > div {
          margin-right: 40px;
          box-shadow: 0 10px 15px rgba(0, 66, 162, 0.2);
          position: relative;
          overflow: hidden;
          &:last-child {
            margin-right: 0;
          }
          flex: 1;
          height: 480px;
          > p {
            position: absolute;
            color: #ffffff;
            width: 100%;
            padding: 0 20px;
          }
          .case-title {
            font-weight: 600;
            font-size: 24px;
            letter-spacing: 3px;
            top: 40px;
            text-align: center;
          }
          .case-info {
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 2px;
            transform: translateY(100%);
            text-align: left;
            transition: all ease-out .3s;
            padding-bottom: 30px;
          }
          &:hover .case-info {
            transform: translateY(-100%);
          }
        }
      }
      > p {
        text-align: right;
        font-size: 24px;
        color: #025ADC;
        line-height: 32px;
        letter-spacing: 5px;
        margin-top: 20px;
        cursor: pointer;
        img {
          vertical-align: text-top;
          margin-left: 6px;
        }
        margin-bottom: 140px;
      }
      .item-bg {
        position: absolute;
      }
      .bg3 {
        left: -90px;
        bottom: -30px;
      }
      .bg4 {
        right: -70px;
        top: 155px;
      }
    }
    .service-center {
      width: 100%;
      background: #025ADC;
      margin-top: 110px;
      padding: 40px 0 60px 0;
      > div {
        width: 1120px;
        margin: auto;
        .service-tab {
          display: flex;
          justify-content: space-around;
          margin-top: 27px;
          > img {
            opacity: 0.6;
            cursor: pointer;
          }
          .active {
            opacity: 1;
          }
        }
      }
      .service-content {
        background: url("../assets/imgs/service-bg.png") 100% 100%;
        padding-bottom: 50px;
        border: 2px solid #69A5FF;
        border-radius: 10px;
        overflow: hidden;
        .service-head {
          color: #E4F6FF;
          font-size: 18px;
          padding: 40px 0 0 40px;
          text-align: left;
          .service-title {
            font-family: "YouSheBiaoTiHei";
            color: #E4F6FF;
            font-size: 48px;
            margin-right: 40px;
          }
        }
        .service-info {
          display: flex;
          padding: 0 40px;
          margin-top: 48px;
          position: relative;
          ul {
            li {
              text-align: left;
              margin-bottom: 36px;
              color: #E4F6FF;
              .name {
                font-size: 16px;
                margin-right: 20px;
              }
              .count {
                font-size: 52px;
                letter-spacing: 5px;
                font-family: 'Alibaba Sans Heavy Italic';
              }
              > img {
                width: 32px;
                height: 32px;
                margin-right: 18px;
              }
            }
          }
          > div {
            position: absolute;
            top: -130px;
            right: 50px;
          }
        }
      }
    }
    .platform {
      background: #025ADC;
      width: 100%;
      padding-top: 89px;
      box-sizing: border-box;
      position: relative;
      .platform-bg {
        display: block;
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translate(-50%);
      }
      .tips {
        color: #FFFFFF;
        line-height: 25px;
        letter-spacing: 1px;
        font-size: 18px;
        margin-top: 19px;
      }
      .platform-content {
        width: 1120px;
        margin: auto;
        overflow: hidden;
        position: relative;
        z-index: 10;
        height: 650px;
        > img {
          position: absolute;
          &:first-child {
            bottom: 100px;
            left: -10px;
          }
          &:nth-child(2) {
            top: 250px;
            left: 380px;
          }
          &:nth-child(3) {
            bottom: 150px;
            right: -10px;
          }
          &:nth-child(4) {
            top: 70px;
            right: 20px;
          }
          &:nth-child(5) {
            top: 0px;
            left: 20px;
          }
        }
        .bullet-item {
          background: url('../assets/imgs/bullet-bg.png') 100% 100%;
          width: 320px;
          height: 80px;
          border-radius: 16px;
          padding: 8px 10px;
          position: absolute;
          cursor: pointer;
          z-index: 10;
          top: 0;
          left: 0;
          .left {
            float: left;
            margin-right: 20px;
            img {
              width: 64px;
              height: 64px;
              border-radius: 50%;
              overflow: hidden;
              display: block;
            }
          }
          .right {
            float: left;
            p {
              text-align: left;
              font-size: 14px;
              color: #303133;
              margin-top: 10px;
              &:last-child {
                color: #FF463A;

              }
              span:last-child {
                margin-left: 30px;
              }
            }
          }
        }
        .bullet-item[data-line="1"] {
          top: 20px
        }
        .bullet-item[data-line="2"] {
          top: 150px
        }
        .bullet-item[data-line="3"] {
          top: 280px
        }
        .bullet-item[data-line="4"] {
          top: 420px
        }
        .bullet-item[data-line="5"] {
          top: 550px
        }
        .bullet-animation {
          animation: right2left 7s linear both;
          &:hover{
            animation-play-state:paused;
          }
        }

        @keyframes right2left {
          0% {
            transform: translate(100vw)
          }
          100% {
            transform: translate(-100%);
          }
        }
      }
    }
    .cooperate {
      width: 1120px;
      margin: auto;
      padding-top: 89px;
      box-sizing: border-box;
      .cooperate-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 77px;
        padding: 0 10px;
        > img {
          display: block;
          width: 200px;
          margin-right: 25px;
          height: 100px;
          margin-bottom: 40px;
          &:nth-child(5n) {
            margin-right: 0px;
          }
        }
      }
    }
    .apply {
      width: 100%;
      display: block;
    }
  }
</style>
<style lang="scss">
  .home {
    .el-divider__text {
      font-size: 24px !important;
      line-height: 35px;
      letter-spacing: 2px;
    }
    .el-dialog__body{
      padding: 0;
    }
    .el-button {
      padding: 12px 16px;
    }
    .swiper-pagination1 {
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        display: inline-block;
        border: 2px solid #FFFFFF;
        border-radius: 0;
        background: none;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        width: 30px;
        height: 15px;
        background: #ffffff;
      }
    }
    .count sup{
    vertical-align: revert;
    }
  }

</style>
