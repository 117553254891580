<template>
    <div class="authentication">
        <div class="content" v-show="step === 1">
            <div class="tips">根据国家管理局要求，寄件需要实名登记</div>
            <div class="title">上传身份证信息</div>
            <el-upload
                    class="upload-demo"
                    drag
                    :action="imageUploadUrl"
                    :data="imageUpLoadData"
                    :headers="uploadHeaders"
                    :show-file-list="false"
                    :on-success="handleClassSuccess"
                    :before-upload="beforeClassUpload"
                    >
                <img class="upload-img" src="../../assets/images/authentication/upLoad.png" alt="">
                <div class="el-upload__tip" slot="tip">点击拍摄/请上传身份证人像面</div>
            </el-upload>
            <hr/>
            <div class="text">不符合上传信息的情况</div>
            <img class="info" src="../../assets/images/authentication/info.png" alt="">
        </div>
        <div class="content" v-show="step === 2">
            <div class="title" style="padding-top: 64px">上传成功</div>
            <el-form label-width="100px" class="result-form">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="name" readonly></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="idCard">
                    <el-input v-model="idCard" readonly></el-input>
                </el-form-item>
            </el-form>
            <div class="form-tip">
                <img src="../../assets/images/authentication/right_icons.png" alt="">同意授权快递员在取件前查验我的真实姓名及其身份号码
            </div>
            <div class="bts">
                <!--<div class="submit">信息正确，提交</div>-->
                <div @click="goBack">返回</div>
            </div>
        </div>
        <!-- 登录组件 -->
        <login v-if="showLogin" @loginResult="loginResult"></login>
    </div>
</template>
<script>
import { queryInfo } from '@/assets/js/api.js'
import API from '../../assets/js/ApiUrl'
import login from '../../components/login.vue'
export default {
  name: 'authentication',
  components: {
    login
  },
  data () {
    return {
      step: 2,
      uploadHeaders: {
        token: localStorage.getItem('express_token')
      },
      imageUpLoadData: {
        file: '',
        sourcePic: 'file'
      },
      imageUploadUrl: API.upload,
      loading: '',
      name: '',
      showLogin: false,
      isAuth: localStorage.getItem('express_isAuth'),
      idCard: ''
    }
  },
  methods: {
    // 登录
    loginResult (result) {
      if ((result === 'success') || (result === 'cancel')) {
        this.showLogin = false
        this.isAuth = localStorage.getItem('express_isAuth')
        // eslint-disable-next-line eqeqeq
        if (this.isAuth == 1) {
          this.step = 2
          this.queryInfo()
        } else {
          this.step = 1
        }
      }
    },
    // 图片压缩控制
    photoCompress (file, w, objDiv) {
      const _self = this
      const ready = new FileReader()
      /* 开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容. */
      ready.readAsDataURL(file)
      ready.onload = function () {
        const re = this.result
        _self.canvasDataURL(re, w, objDiv, file)
      }
    },
    canvasDataURL (path, obj, callback, file) {
      const img = new Image()
      img.src = path
      img.onload = function () {
        const that = this
        // 默认按比例压缩
        let w = that.width
        let h = that.height
        const scale = w / h
        w = obj.width || w
        h = obj.height || (w / scale)
        let quality = 0.2 // 默认图片质量为0.7
        // 生成canvas
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // 创建属性节点
        const anw = document.createAttribute('width')
        anw.nodeValue = w
        const anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        const base64 = canvas.toDataURL('image/jpeg', quality)

        // base64再转file文件
        const arr = base64.split(','); const mime = arr[0].match(/:(.*?);/)[1] // 转成blob
        const bstr = atob(arr[1]); let n = bstr.length; const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        let fileName = ''
        if (file.name && file.name.indexOf('.') > -1) {
          fileName = file.name.split('.')[0]
        }
        const files = new window.File([new Blob([u8arr], { type: mime })], fileName + '.jpeg', { type: 'image/jpeg' }) // 转成file
        callback(files) // 回调
      }
    },
    // 上传图片
    handleClassSuccess (res, file) {
      this.loading.close()
      if (res.code === 200) {
        this.name = res.data.name
        this.idCard = res.data.idcard
        localStorage.setItem('express_isAuth', 1)
        this.step = 2
      } else if (res.code === 4003) {
        this.showLogin = true
        this.$message.error(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传验证
    beforeClassUpload (file) {
      this.imageUpLoadData.file = file
      const isPNG = file.type === 'image/png'
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 10

      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是JPG或PNG格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10M!')
        return false
      }
      // 图片压缩
      this.photoCompress(file, {
        quality: 0.2
      }, res => {
        this.imageUpLoadData.file = res
      })
      this.loading = this.$loading({
        lock: true,
        text: '认证中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    goBack () {
      this.$router.go(-1)
    },
    queryInfo () {
      const params = {}
      queryInfo(params).then(res => {
        this.name = res.data.name
        this.idCard = res.data.idcard
      })
    }
  },
  created () {
  },
  mounted () {
    // eslint-disable-next-line eqeqeq
    if (this.isAuth == 1) {
      this.step = 2
      this.queryInfo()
    } else {
      this.step = 1
    }
  }
}
</script>
<style lang="scss" scoped>
    .authentication {
        background: #F7F7F7;
        padding: 40px 0;
        .content{
            width: 1200px;
            background: #ffffff;
            padding-bottom: 120px;
            margin: auto;
            .tips{
                width: 616px;
                height: 40px;
                background: #FFE8C7;
                border-radius: 4px;
                font-size: 14px;
                color: #666666;
                text-align: center;
                line-height: 40px;
                margin: auto;
            }
            .title{
                color: #303133;
                font-weight: 500;
                font-size: 24px;
                padding-left: 70px;
                margin-top: 24px;
                text-align: left;
                margin-bottom: 35px;
            }
            .upload-img{
                width: 385px;
                height: 260px;
            }
            .el-upload__tip{
                color: #4098FF;
                font-size: 16px;
                margin-top: 20px;
            }
            hr{
                width: 800px;
                height: 1px;
                background: #F2F2F2;
                margin: 36px auto 17px auto;
                border: none;
            }
            .text{
                color: #BFC4CD;
                font-size: 16px;
                padding-left: 200px;
                text-align: left;
                margin-bottom: 37px;
            }
            .info{
                width: 516px;
                height: 124px;
                display: block;
                margin: auto;
            }
            .result-form{
                width: 590px;
                margin: auto;
            }
            .form-tip{
                font-size: 14px;
                color:#BFC4CD;
                text-align: center;
                img{
                    width: 12px;
                    height: 12px;
                    border: 1px solid #BFC4CD;
                    margin-right: 11px;
                    vertical-align: middle;
                }
            }
            .bts{
                margin-top: 86px;
                padding-right: 115px;
                div{
                    cursor: pointer;
                    display: inline-block;
                    width: 121px;
                    height: 40px;
                    line-height: 38px;
                    box-sizing: border-box;
                    border: 1px solid #0066FF;
                    color: #0066FF;
                    font-size: 14px;
                    border-radius: 4px;
                }
                .submit{
                    background: #0066FF;
                    color: #FFFFFF;
                    margin-right: 29px;
                }
            }
        }
    }
</style>
<style lang="scss">
    .authentication {
        .el-upload-dragger{
            width: 385px;
            height: 260px;
            border: none;
        }
    }
</style>
