import http from './http.js'

const comments = 'commentsApi/comments'

export const addPurpose = (params) => {
  return http.request({ method: 'post', url: '/purpose/addPurpose', data: params })
}

export const addComputeModel = (params) => {
  return http.request({ method: 'post', url: '/purpose/addComputeModel', data: params })
}

export const apiApply = (params) => {
  return http.request({ method: 'post', url: '/apiApply/apply', data: params })
}

export const saveApplyInfor = (params) => {
  return http.request({ method: 'post', url: '/api/dOrgInfoApply/save', data: params })
}

export const queryExpressList = () => {
  return http.request({ method: 'get', url: '/api/dOrgInfoApply/queryExpressList' })
}

export const queryArea = (params) => {
  return http.request({ method: 'post', url: '/api/dOrgInfoApply/queryArea', data: params })
}

export const applySubmit = (params) => {
  return http.request({ method: 'post', url: '/api/dOrgInfoApply/applySubmit', data: params })
}

export const openUrl = (params) => {
  return http.request({ method: 'get', url: `/api/dOrgInfoApply/openUrl?id=${params.id}` })
}

export const querySupply = (params) => {
  return http.request({ method: 'get', url: `/api/officialWebsite/queryAll?pageNo=${params.pageNo}&limit=${params.limit}` })
}

export const captchCode = () => {
  return http.request({ method: 'get', url: '/api/officialWebsite/getCaptchCode' })
}

export const smsCode = (params) => {
  return http.request({ method: 'post', url: '/api/officialWebsite/validateCaptchCode', data: params })
}

export const saveInfo = (params) => {
  return http.request({ method: 'post', url: '/api/officialWebsite/saveInfo', data: params })
}
export const allApis = () => {
  return http.request({ method: 'get', url: '/api/openapiDocs/allApis' })
}
export const singleApiInfo = (params) => {
  return http.request({ method: 'get', url: `/api/openapiDocs/singleApiInfo?apiPath=${params.path}` })
}
// 登录相关
export const getCaptchCode = () => {
  return http.request({ method: 'post', url: '/hula/hula/user/geth5CaptchCode' })
}

export const getSendSms = (params) => {
  return http.request({ method: 'post', url: '/hula/hula/user/h5SendSms', data: params })
}

export const login = (params) => {
  return http.request({ method: 'post', url: '/hula/hula/user/h5Login', data: params })
}

// 寄件相关
export const getAreaString = () => {
  return http.request({ method: 'post', url: '/hula/appletAddressManager/getAreaString' })
}

export const queryAddressResolve = (params) => {
  return http.request({ method: 'post', url: '/hula/appletAddressManager/addressResolver', data: params })
}

// 物品类型列表
export const queryGoodsType = (params) => {
  return http.request({ method: 'get', url: `/hula/hula/sundry/queryGoods?queryType=${params}` })
}

// 获取快递公司
export const queryExpressCompany = (params) => {
  return http.request({ method: 'post', url: '/hula/hula/price/query', data: params })
}
// 获取个人信息
export const queryInfo = (params) => {
  return http.request({ method: 'post', url: '/hula/appleuserPaper/query', data: params })
}
// 寄快递下单
export const queryOrderExpress = (params) => {
  return http.request({ method: 'post', url: '/hula/hula/express/send', data: params })
}
// 服务分类
export const queryTExpressServiceList = (params) => {
  return http.request({ method: 'post', url: `${comments}/app/tExpressService/queryTExpressServiceList`, data: params })
}
// 查询发布的服务列表
export const queryServicePublishParamPage = (params) => {
  return http.request({ method: 'post', url: `${comments}/app/tExpressServicePublishParam/getServicePublishParamPage`, data: params })
}
// 发布的服务详情
export const queryServicePublishDetail = (params) => {
  return http.request({ method: 'post', url: `${comments}/app/tExpressServicePublishParam/getServicePublishParamDeatil`, data: params })
}
// 快递纵横app信息
export const queryAppFileInfo = (params) => {
  return http.request({ method: 'post', url: `${comments}/app/appVersion/queryAppFileInfo`, data: params })
}
