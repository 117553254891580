<template>
    <div class="navbar">
        <div class="container">
          <div class="title">快件物流资源共享平台</div>
          <div class="label">
            <ul @click="toggleSolution($event)">
              <router-link to="/"><li :class="{'active': currentRoute == '/'}" data-id = '/'>首页</li></router-link>
              <router-link to="/solution"><li :class="{'active': currentRoute == '/solution'}" data-id = '/solution'>解决方案</li></router-link>
              <router-link to="/serviceCenter"><li :class="{'active': currentRoute == '/serviceCenter'}"  data-id = '/serviceCenter'>服务中心</li></router-link>
              <router-link to="/serviceList"><li :class="{'active': currentRoute == '/serviceList'}"  data-id = '/serviceList'>供需平台</li></router-link>
              <router-link to="/openPlatform/arriveApi"><li :class="{'active': currentRoute == '/openPlatform/arriveApi'}" data-id = '/openPlatform/arriveApi'>开放平台</li></router-link>
              <router-link to="/contact"><li :class="{'active': currentRoute == '/contact'}" data-id = '/contact'>联系我们</li></router-link>
<!-- 2021-06-11 暂时隐藏申请入口 -->
             <!-- <router-link to="/applyUse"><li class="apply" data-id = '/applyUse'>申请使用</li></router-link> -->
              <div class="apply" @click="logOut" v-if="showLogoutBtn">退出登录</div>
            </ul>
          </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'navbar',
  data () {
    return {
      page: '/',
      currentRoute: '/',
      showLogout: localStorage.getItem('express_token')
    }
  },
  computed: {
    ...mapState(['showLogoutBtn'])
  },
  methods: {
    ...mapMutations(['updateBtnStatus']),
    toggleSolution (e) {
      this.currentRoute = e.target.dataset.id !== undefined ? e.target.dataset.id : this.currentRoute
    },
    logOut () {
      localStorage.removeItem('express_token')
      localStorage.removeItem('express_isAuth')
      this.updateBtnStatus(false)
      this.$router.push({
        path: '/'
      })
    }
  },
  mounted () {
    this.currentRoute = this.$route.path
  },
  beforeRouteEnter (to, form, next) {
    next()
  },
  watch: {
    $route: {
      handler (val) {
        this.currentRoute = val.path
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar{
    font-size: 14px;
    height: 72px;
    .container{
        width:1200px;
        display:flex;
        margin:0 auto;
        /*justify-content: space-around;*/
      .title{
        font-size:20px;
        font-family:MicrosoftYaqiHei-Bold,MicrosoftYaqiHei;
        font-weight:bold;
        color:#0858D7;
        line-height:72px;
        letter-spacing:2px;
        padding-left: 45px;
        background: url("../assets/imgs/logo.png") left center no-repeat;
        background-size: 32px 32px;
      }
      .label{
        padding-left: 180px;
        .apply{
          color: #ffffff;
          background: #0066FF;
          padding: 9px 20px;
          margin-right: 0;
          margin-left: 5px;
          position: relative;
          top: -8px;
          border-radius: 8px;
          cursor: pointer;
        }
        ul{
          display: flex;
          padding:30px 10px 10px 10px;
          li{
            padding: 0 25px 16px 25px;
            cursor: pointer;
            &.active {
              color: #1240C0;
              &:after{
                display: block;
                content:'';
                width:20px;
                height: 2px;
                /*background:#1240C0;*/
                margin:0 auto;
              }
            }
          }
        }
      }
    }
    .logo{
        width:273px;
        height: 32px;
        margin-top: 20px;
    }
    /*ul{*/
    /*    display: flex;*/
    /*    padding: 10px;*/
    /*    &>li{*/
            /*&.active,&:hover{*/
            /*    color: #1240C0;*/
            /*    &:after{*/
            /*        display: block;*/
            /*        content:'';*/
            /*        width:20px;*/
            /*        height: 2px;*/
            /*        background:#1240C0;*/
            /*        margin:0 auto;*/
            /*    }*/
            /*}*/
        /*}*/
    /*}*/

}
</style>
