import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import productService from '../views/productService.vue'
import openPlatform from '../views/openPlatform/index.vue'
import deliverySystem from '../views/deliverySystem.vue'
import productData from '../views/productData.vue'
import productResource from '../views/productResource.vue'
import shareLink from '../views/shareLink/index.vue'
import supplyPlateform from '../views/supplyPlateform/index.vue'
import serviceList from '../views/serve/serviceList.vue'
import serviceCenter from '../views/serviceCenter/index.vue'
import solution from '../views/solution/index.vue'
// import applyUse from '../views/applyUse/index.vue'
import successCase from '../views/successCase/index.vue'
import contact from '../views/contact.vue'
import arriveApi from '../views/openPlatform/arriveApi'
import dispatchApi from '../views/openPlatform/dispatchApi'
import sendApi from '../views/openPlatform/sendApi'
import signApi from '../views/openPlatform/signApi'
import authentication from '../views/authentication/authentication'
import semantic from '../views/semantic'

import send from '../views/send/index.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news/index.vue'),
    redirect: 'center',
    children: [
      {
        path: 'center',
        name: 'center',
        component: () => import('@/views/news/list')
      }, {
        path: 'detail',
        component: () => import('@/views/news/details')
      }]
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/measure',
    name: 'measure',
    component: () => import('@/views/measure')
  },
  {
    path: '/productService',
    name: 'productService',
    component: productService
  },
  {
    path: '/openPlatform',
    name: 'openPlatform',
    component: openPlatform,
    redirect: 'arrive',
    children: [{
      path: 'arriveApi',
      name: 'arrive',
      component: arriveApi
    },
    {
      path: 'dispatchApi',
      component: dispatchApi
    },
    {
      path: 'sendApi',
      component: sendApi
    },
    {
      path: 'signApi',
      component: signApi
    }
    ]
  },
  {
    path: '/deliverySystem',
    name: 'deliverySystem',
    component: deliverySystem
  },
  {
    path: '/productData',
    name: 'productData',
    component: productData
  },
  {
    path: '/productResource',
    name: 'productResource',
    component: productResource
  },
  {
    path: '/shareLink',
    name: 'shareLink',
    component: shareLink
  },
  {
    path: '/supplyPlateform', // 供需平台
    name: 'supplyPlateform',
    component: supplyPlateform
  },
  {
    path: '/serviceList', // 服务列表
    name: 'serviceList',
    component: serviceList
  },
  {
    path: '/serviceCenter', // 服务中心
    name: 'serviceCenter',
    component: serviceCenter
  },
  {
    path: '/solution', // 解决方案
    name: 'solution',
    component: solution
  },
  // {
  //   path: '/applyUse', // 申请使用
  //   name: 'applyUse',
  //   component: applyUse
  // },
  {
    path: '/authentication', // 申请使用
    name: 'authentication',
    component: authentication
  },
  {
    path: '/successCase',
    name: 'successCase',
    component: successCase
  },
  {
    path: '/send',
    name: 'send',
    component: send
  },
  {
    path: '/semantic',
    name: 'semantic',
    component: semantic
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path === '/applyUse') {
    next({ path: '/' })
  }
  next()
})
export default router
