<template>
  <div id="emptyBox" class="empty-box">
    <img :src="emptyOption.picUrl" alt="暂无数据">
    <p class="empty-tip">{{emptyOption.tip}}</p>
  </div>
</template>

<script>
import noData from '@/assets/images/icon_nodata.png'
export default {
  name: 'empty',
  props: {
    emptyOption: {
      type: Object,
      default: function () {
        return {
          picUrl: '',
          tip: ''
        }
      }
    }
  },
  data () {
    return {
      noDataUrl: noData
    }
  },
  created () {
    this.emptyOption.picUrl = this.emptyOption.picUrl ? this.emptyOption.picUrl : this.noDataUrl
    this.emptyOption.tip = this.emptyOption.tip ? this.emptyOption.tip : '暂无数据'
  }
}
</script>

<style lang="scss">
  .el-table::before {
    height: 0;
  }
</style>
<style scoped lang="scss">
  .empty-box {
    width: 100%;
    padding: 120px 0;
    > img {
      display: block;
      width: 260px;
      height: 219px;
      margin: 0 auto;
    }
    .empty-tip {
      padding: 10px 0;
      color: #B2CDF0;
    }
  }
  .el-dialog__wrapper{
    .empty-box{
      padding: 30px 0 0 0;
    }
  }
</style>
