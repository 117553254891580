<template>
  <footer>
    <!--<div class="contact">-->
    <!--<ul>-->
    <!--<li>-->
    <!--<div class="img">-->
    <!--<img style="height: 150px;" src="@/assets/imgs/hula.jpg" alt="">-->
    <!--</div>-->
    <!--<p>扫一扫 <br/>关注呼啦快寄</p>-->
    <!--</li>-->
    <!--<li>-->
    <!--<div class="img">-->
    <!--<img style="height: 150px;"  src="@/assets/imgs/yijiantong.png" alt="">-->
    <!--</div>-->
    <!--<p>扫一扫 <br/>下载易拣通</p>-->
    <!--</li>-->
    <!--</ul>-->
    <!--<div class="info">-->
    <!--<p class="title">联系我们</p>-->
    <!--<div>电话 400-7381-9923 <span>邮箱 xxx@xom.cn</span></div>-->
    <!--<div>地址 上海青浦区华新镇华徐公路3029弄28号</div>-->
    <!--</div>-->
    <!--</div>-->
    <div class="footer">
      <span>Copyright © 2020-2021 快件物流资源共享服务平台 版权所有 上海青浦区华新镇华徐公路3029弄28号 | 邮政编码：201705 技术支持：快件物流资源共享服务平台项目组</span>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank">沪ICP备05004632号-30</a>
      <a href="https://beian.mps.gov.cn/#/query/websearch?code=31811802004961" rel="noreferrer" target="_blank">
        <img src="@/assets/images/gongan.png"/>
        沪公网安备31011802004961
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footer',
  data () {
    return {
      str: '沪公网安备31011802004961'
    }
  }
}
</script>

<style lang="scss" scoped>
  .contact {
    color: #fff;
    height: 290px;
    background: #1240C0;
    padding: 50px 0 20px 0;
    display: flex;
    ul {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      li {
        margin-right: 50px;
      }
      .img {
        width: 150px;
        height: 150px;
        background: #fff;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .info {
      text-align: left;
      padding: 10px 0 0 100px;
      font-size: 14px;
      line-height: 40px;
      .title {
        font-size: 18px;
        font-weight: 600;
      }
      span {
        padding-left: 80px;
      }
    }
  }

  .footer {
    font-size: 12px;
    color: #fff;
    text-align: center;
    height: 110px;
    // line-height: 110px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #030066;
    a {
      color: #ffffff;
      margin-top: 8px;
      img {
        width: 16px;
        height: 16px;
        // vertical-align: middle;
      }
    }
  }
</style>
<style lang="scss">
</style>
