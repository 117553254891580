<template>
<div class="serviceList">
  <div class="serve-container">
    <div class="publish-btn">
      <el-button type="primary" size="small" icon="el-icon-s-promotion" @click="openPublishDialog">发布</el-button>
    </div>
    <div class="swiper-section" >
      <div class="all-type" :class="{'active': currentServeType === allType.id}" @click.stop="serveFirstTypeChange('all')">
        <img :src="allType.icon" alt="icon">
        <p>{{allType.name}}</p>
      </div>
      <div class="swiper-list swiper-no-swiping" id="mySwiperList" ref="mySwiperList">
        <swiper class="mySwiper" :options="swiperOption" ref="mySwiper" v-if="swiperOption">
          <swiper-slide v-for="(item, index) in swiperTypeList" :key="index">
            <div class="swiper-item" :class="{'active': currentServeType === childItem.id}" v-for="(childItem, childIndex) in item" :key="childIndex"
            @click.stop="serveFirstTypeChange(childItem.id)" >
              <img :src="childItem.rmk1" alt="icon">
              <p>{{childItem.serviceName}}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper-button-next" v-show="swiperShowNext">
        <div class="nex-img"></div>
      </div>
      <div class="swiper-button-prev" v-show="!swiperShowNext">
        <div class="nex-img"></div>
      </div>
    </div>
    <div class="second-container" v-if="currentServeType !== 'all' && secondTypeList.length > 0">
      <div class="second-section">
        <div class="second-item" :class="{'active': item.checked}" v-for="(item, index) in secondTypeList" :key="item.id"
             @click="chooseSecondType(item, index)">
          {{item.serviceName}}
        </div>
        <div class="second-item put-away" v-if="secondTypeList.length > secondCount"
             @click="expandSecond(false)">
          收起
        </div>
      </div>
      <div class="handle-section" v-if="isShowExpand && secondTypeList.length > 6" @click="expandSecond(true)">
        展开
        <img src="../../assets/images/serve/icon_expand.png" alt="expand">
      </div>
    </div>
    <div>
      <div v-if="serviceList.length > 0">
        <div class="list-item" v-for="item in serviceList" :key="item.publishId">
        <div class="serve-info">
          <div class="info-item">
            <div class="title-section">
              <span class="publish-title">{{item.publishName}}</span>
              <span class="m-l-5 city-icon"><i class="el-icon-location-outline"></i></span>
              <span class="city">{{item.cityName}}</span>
            </div>
            <p>
              <el-tag type="warning" size="medium" >{{item.firstName}}>{{item.secondName}}</el-tag>
            </p>
            <p class="name">
              <span>发布人：{{item.createUserName | formatName}}</span>
              <span class="m-l-20">发布时间：{{item.createTime}}</span>
            </p>
          </div>
          <div class="price-section">{{item.publishAmount}}</div>
        </div>
        <div class="link--info">
          <div class="link" @click="openDialog('link', item)">
            <img src="../../assets/images/serve/icon_serve_phone.png" alt="img">
            <span> 立即联系</span>
          </div>
          <div class="link" @click="openDialog('detail', item)">
            <img src="../../assets/images/serve/icon_serve_detail.png" alt="img">
            <span> 查看详情</span>
          </div>
        </div>
      </div>
      </div>
      <div v-if="serviceList.length === 0">
        <empty :emptyOption="emptyOption"></empty>
      </div>
      <div class="page-section" v-if="total > 0">
        <el-pagination
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
  <!-- 发布弹窗 -->
  <el-dialog title="发布" :visible.sync="showDownDialog" width='640px' :close-on-click-modal="false" @close="closeDialog('down')" class="down-dialog">
    <div>
      <div class="code-section" v-if="appInfo">
        <div class="box">
          <vue-qrcodes :qrcodeOption="{
            ...qrcodeSize,
            url: appInfo.appUrl,
            iconUrl: appInfo.appImg
            }">
          </vue-qrcodes>
        </div>
        <div class="tip">
          海量服务信息，尽在快递纵横APP,用浏览器扫描左侧二维码，即可下载并发布服务
        </div>
      </div>
      <div class="info-section">
        <p class="title">发布方法：</p>
        <p class="step">步骤一：<span class="link">登录</span>快递纵横账号（可用手机号进行注册）。</p>
        <p>步骤二：在首页点击快递服务<span class="link">功能入口</span>，进入服务首页，点击右上角“<span class="link">我的发布</span>”，在新页面中点击<span class="link">新建发布</span>按钮，即可发布服务。</p>
      </div>
      <div class="info-section m-t-5">
        <img class="down-guide" src="../../assets/images/serve/icon_down_guide.png" alt="img">
      </div>
    </div>
  </el-dialog>
  <!-- 立即联系 -->
  <el-dialog title="立即联系" :visible.sync="showLinkDialog" width='640px' :close-on-click-modal="false" @close="closeDialog('link')" class="link-dialog">
    <div class="link-info" v-if="serveDetail">
      <div class="tip">温馨提示：请拨打如下电话进行联系，请勿泄露个人隐私</div>
      <div class="phone-box">联系人：{{serveDetail.personName}}</div>
      <div class="phone-box">联系电话：<span class="phone">{{serveDetail.personPhone}}</span></div>
    </div>
  </el-dialog>
  <!-- 详情弹窗 -->
  <el-dialog title="查看详情" :visible.sync="showDetailDialog" width='640px' :close-on-click-modal="false" @close="closeDialog('detail')" class="detail-dialog">
    <div v-if="serveDetail">
      <div class="title-box">
        <div class="title">{{serveDetail.publishName}}</div>
        <div class="price">{{serveDetail.publishAmount}}</div>
      </div>
      <div class="m-t-5">
        <el-tag type="warning" size="medium" >{{serveDetail.firstName}}>{{serveDetail.secondName}}</el-tag>
      </div>
      <div class="publish-person m-t-5">
        <span>发布人：{{serveDetail.createUserName | formatName}}</span>
        <span class="m-l-20">发布时间：{{serveDetail.createTime}}</span>
      </div>
      <div class="city-box m-t-5">
        <span class="icon-city"><i class="el-icon-location-outline"></i></span>
        <span class="m-l-5">{{serveDetail.cityName}}</span>
      </div>
      <div class="detail-list m-t-5">
        <div class="split-title">详细信息</div>
        <div class="content desc-content">
          <p v-html="serveDetail.publishDesc"></p>
          <div class="link-box">
            <span>联系人姓名：{{serveDetail.personName}}</span>
            <span class="m-l-20">联系人电话：{{serveDetail.personPhone}}</span>
          </div>
        </div>
      </div>
      <div v-if="serveDetail.detailResponseList && serveDetail.detailResponseList.length > 0">
        <div class="detail-list m-t-5" v-for="(item, index) in serveDetail.detailResponseList" :key="index">
          <div class="split-title">{{item.paramName}}</div>
          <div class="detail-img" >
            <div class="detail-img" v-if="(item.paramType === 4) && item.paramContent">
              <img :src="item.paramContent" alt="img">
<!--              <img v-for="(imgItem, imgIndex) in item.paramContent" :key="imgIndex" :src="imgItem" alt="img">-->
            </div>
            <div class="content" v-else>{{item.paramContent}}</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { queryTExpressServiceList, queryServicePublishParamPage, queryServicePublishDetail, queryAppFileInfo } from '@/assets/js/api'
import empty from '@/components/empty'
import vueQrcodes from '@/components/vueQrcodes'

export default {
  name: 'serviceList',
  components: {
    SwiperSlide,
    Swiper,
    empty,
    vueQrcodes
  },
  data () {
    return {
      loading: false,
      swiperOption: null,
      allType: {
        id: 'all',
        name: '全部分类',
        icon: require('../../assets/images/serve/icon_serve_all.png')
      },
      firstTypeList: [],
      secondTypeList: [],
      secondTypeListAll: [],
      swiperTypeList: [],
      swiperShowNext: true,
      currentServeType: 'all',
      searchParams: {
        firstId: '',
        secondId: '',
        limit: 10,
        pageNo: 1
      },
      total: 0,
      serviceList: [],
      emptyOption: {
        tip: '该分类下暂无信息，请试试其他分类吧'
      },
      showLinkDialog: false,
      showDetailDialog: false,
      serveDetail: null,
      showDownDialog: false,
      qrcodeSize: {
        wid: 120,
        hei: 120,
        imgwid: 30,
        imghei: 30
      },
      appInfo: null,
      winWidth: 0,
      isShowExpand: false,
      secondCount: 8
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  filters: {
    formatName (val) {
      const regex = /^(.).+$/
      return val.replace(regex, '$1**')
    }
  },
  created () {
    this.getAppInfo()
  },
  mounted () {
    this.initPageParams()
    // resize
    window.onresize = () => {
      this.winWidth = document.body.clientWidth
    }
  },
  methods: {
    initPageParams () {
      this.winWidth = document.body.clientWidth
      // 获取一级分类
      this.getServiceTypeList(1, '')
      if (this.$route.query.serviceType) {
        this.currentServeType = Number(this.$route.query.serviceType)
        this.searchParams.firstId = Number(this.$route.query.serviceType)
        // 获取一级分类
        this.getServiceTypeList(2, this.searchParams.firstId)
      }
      this.getPublishList()
    },
    swiperInit (index) {
      this.swiperOption = {
        slidesPerView: 'auto',
        autoplay: false,
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next'
        },
        slidesPerGroupAuto: true,
        initialSlide: index,
        observer: true,
        observeParents: false,
        on: {
          click: () => {
            return false
          },
          slidePrevTransitionEnd: () => {
            if (this.swiperTypeList.length === 1) {
              return false
            }
            if (!this.swiperShowNext && (this.swiper.activeIndex === 0)) {
              this.swiperShowNext = true
            }
          },
          slideNextTransitionEnd: () => {
            if (this.swiperTypeList.length === 1) {
              return false
            }
            if (this.swiperShowNext && (this.swiper.activeIndex === this.swiperTypeList.length - 1)) {
              this.swiperShowNext = false
            }
            if (!this.swiperShowNext && (this.swiper.activeIndex === 0)) {
              this.swiperShowNext = true
            }
          }
        }
      }
      if (this.$refs.mySwiper) {
        this.$refs.mySwiper.initSwiper()
      }
    },
    openFullScreen (flag) {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.9)'
      })
      if (!flag) {
        loading.close()
      }
    },
    getServiceTypeList (type, id) {
      const params = {
        parentId: id,
        serviceType: type // 一级分类
      }
      queryTExpressServiceList(params).then(res => {
        if (res.code === 200) {
          if (type === 1) {
            this.formatSwiperType(res.data)
          } else if (type === 2) {
            // 获取二级分类
            this.formatSecondType(res.data)
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },
    formatSwiperType (list) {
      if (!list || list.length === 0) {
        this.swiperTypeList = []
        this.firstTypeList = []
        return false
      }
      this.firstTypeList = list
      const w = this.$refs.mySwiperList.clientWidth
      const itemW = 110
      const swiperNum = Math.floor(w / itemW)
      let swiperPage = Math.floor(list.length / swiperNum)
      const remainderNum = list.length % swiperNum
      if (remainderNum > 0) {
        swiperPage += 1
      }
      for (let i = 0; i < swiperPage; i++) {
        if (i === (swiperPage - 1) && remainderNum > 0) {
          this.swiperTypeList[i] = list.slice(i * swiperNum, (i * swiperNum) + remainderNum)
        } else {
          this.swiperTypeList[i] = list.slice(i * swiperNum, (i + 1) * swiperNum)
        }
      }
      if (this.searchParams.firstId) {
        this.formatFirstActiveType()
      } else {
        this.swiperInit(0)
      }
    },
    formatFirstActiveType () {
      for (let i = 0; i < this.swiperTypeList.length; i++) {
        const tempList = this.swiperTypeList[i]
        for (let j = 0; j < tempList.length; j++) {
          if (this.searchParams.firstId === tempList[j].id) {
            this.$nextTick(() => {
              this.swiperInit(i)
            })
            break
          }
        }
      }
    },
    formatSecondType (list) {
      if (list && list.length > 0) {
        list.forEach(e => {
          e.checked = false
        })
        this.secondTypeListAll = list
        this.secondTypeList = this.secondTypeListAll
        // if (this.secondTypeListAll.length > this.secondCount) {
        //   this.secondTypeList = this.secondTypeListAll.slice(0, this.secondCount)
        // } else {
        //   this.secondTypeList = this.secondTypeListAll
        // }
      } else {
        this.secondTypeList = []
        this.secondTypeListAll = []
      }
    },
    serveFirstTypeChange (id) {
      this.isShowExpand = false
      this.currentServeType = id
      if (id === 'all') {
        this.searchParams = {
          firstId: '',
          secondId: '',
          limit: 10,
          pageNo: 1
        }
        this.$nextTick(() => {
          this.swiper.slideTo(0, 200, false)
          this.swiperShowNext = true
        })
      } else {
        this.searchParams = {
          firstId: id,
          secondId: '',
          limit: 10,
          pageNo: 1
        }
        this.getServiceTypeList(2, id)
      }
      this.getPublishList()
    },
    chooseSecondType (item, index) {
      this.secondTypeList.forEach(e => {
        e.checked = false
      })
      this.secondTypeList[index].checked = !this.secondTypeList[index].checked
      if (this.secondTypeList[index].checked) {
        this.searchParams.secondId = item.id
      } else {
        this.searchParams.secondId = ''
      }
      this.searchParams.pageNo = 1
      this.getPublishList()
    },
    getPublishList () {
      this.openFullScreen(true)
      queryServicePublishParamPage(this.searchParams).then(res => {
        if (res.code === 200) {
          if (res.data.resultData && res.data.resultData.length > 0) {
            this.serviceList = res.data.resultData
          } else {
            this.serviceList = []
          }
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
        this.openFullScreen(false)
      }).catch(err => {
        this.openFullScreen(false)
        this.$message.error(err.msg)
      })
    },
    handleCurrentChange (val) {
      this.searchParams.pageNo = val
      this.getPublishList()
    },
    closeDialog (type) {
      if (type === 'link') {
        this.showLinkDialog = false
      } else if (type === 'detail') {
        this.showDetailDialog = false
      } else if (type === 'down') {
        this.showDownDialog = false
      }
      this.serveDetail = null
    },
    openPublishDialog () {
      this.showDownDialog = true
    },
    openDialog (type, item) {
      const params = {
        publishId: item.publishId,
        pageType: 'detail'
      }
      queryServicePublishDetail(params).then(res => {
        if (res.code === 200) {
          this.serveDetail = res.data
          if (type === 'link') {
            this.showLinkDialog = true
          } else if (type === 'detail') {
            this.showDetailDialog = true
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },
    getAppInfo () {
      queryAppFileInfo({}).then(res => {
        if (res.code === 200) {
          this.appInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },
    expandSecond (flag) {
      if (flag) {
        this.secondTypeList = this.secondTypeListAll
        this.isShowExpand = false
      } else {
        this.secondTypeList = this.secondTypeListAll.slice(0, this.secondCount)
        this.isShowExpand = true
      }
    }
  }
}
</script>

<style lang="scss">
.el-loading-spinner {
  font-size: 35px !important;
}
.serviceList {
  .el-dialog {
    margin: 0 !important;
    max-height: 600px;
    .el-dialog__header {
      padding: 10px;
      text-align: left;
      color: #fff;
      background: #1D54C2 !important;
      .el-dialog__title {
        color: #fff
      }
    }
    .el-dialog__body {
      max-height: 556px;
      overflow-y: auto;
    }
    .el-dialog__headerbtn {
      top: 15px;
      .el-dialog__close {
        color: #fff;
      }
    }
  }
  .link-dialog, .detail-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    .link-info {
      font-size: 14px;
      color: #333333;
      >div {
       padding: 8px 0;
        font-weight: 500;
      }
      .tip {
        font-size: 12px;
        line-height: 20px;
        color: #f00;
        text-align: center;
      }
      .phone-box {
        padding-left: 240px;
        text-align: left;
      }
      .phone {
        color: #1D54C2
      }
    }
  }
  .down-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-dialog__body {
      padding: 0 20px !important;
    }
  }
}
</style>
<style scoped lang="scss">
$theme_color: #1240C0;
.serviceList {
  width: 100%;
  min-height: calc(100vh - 182px);
  background: #FAFAFA;
  ::-webkit-scrollbar {
    display: none;
  }
  .serve-container {
    max-width: 1400px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .wb1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .publish-btn {
    padding: 20px 0;
    text-align: right;
  }
  .m-l-20 {
    margin-left: 20px;
  }
  .m-l-5 {
    margin-left: 5px;
  }
  .m-t-5 {
    margin-top: 5px;
  }
  .swiper-section {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 12px;
    .all-type, .swiper-item {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 110px !important;
      height: 110px;
      margin-right: 6px;
      font-size: 14px;
      color: #1240C0;
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #ECECEC;
      cursor: pointer;
      &.active {
        background: #F6F9FF;
        border: 1px solid #3174DE;
      }
      img {
        display: block;
        width: 48px;
        height: 48px;
      }
      p {
        margin-top: 8px;
      }
    }
    .swiper-list {
      flex: 1;
      //width: calc(100% - 162px);
      overflow-x: hidden;
      .swiper-container {
        width: 100%;
      }
      //.swiper-slide-active {
      //  background: #fff;
      //}
      .swiper-slide {
        display: flex;
        align-items: center;
      }
    }
    .swiper-button-prev, .swiper-button-next {
      position: relative;
      top: 0;
      width: 52px;
      height: 110px;
      margin-top: 0;
      background: #FFFFFF;
      cursor: pointer;
      border-radius: 8px;
    }
    .swiper-button-prev, .swiper-button-next {
      right: 0;
      &::after {
        content: '';
      }
    }
    .swiper-button-prev {
      .nex-img {
        transform: rotate(180deg);
      }
    }
    .swiper-button-next, .swiper-button-prev {
      .nex-img {
        width: 28px;
        height: 28px;
        background-image: url("../../assets/images/serve/icon_right_default.png");
        background-size: 100%;
      }
    }
    .swiper-button-next:hover, .swiper-button-next:active, .swiper-button-prev:hover, .swiper-button-prev:active {
      .nex-img {
        background-image: url("../../assets/images/serve/icon_right_active.png");
      }
    }
  }
  .second-container {
    display: flex;
    align-items: self-start;
    width: 100%;
    min-height: 76px;
    margin: 12px 0;
    padding: 20px 0;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #ECECEC;
    overflow-y: hidden;
    .handle-section {
      width: 60px;
      display: flex;
      align-items: center;
      padding: 10px;
      font-size: 12px;
      color: #0959D8;
      cursor: pointer;
      img {
        width: 12px;
        height: 10px;
        margin-left: 3px;
      }
    }
    .second-section {
      flex: 1;
    }
  }
  .second-section {
    display: flex;
    flex-wrap: wrap;
    align-items: self-start;
    .second-item {
      width: 160px;
      min-height: 36px;
      padding: 10px 20px;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      &.active {
        color: $theme_color;
      }
    }
    .put-away {
      font-size: 12px;
      color: $theme_color;
    }
  }
  .list-item {
    display: flex;
    width: 100%;
    padding: 5px 40px;
    margin-bottom: 12px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #ECECEC;
    &:hover, &:active {
      background: #F6F9FF
    }
    .serve-info {
      flex: 1;
      display: flex;
      align-items: center;
      .info-item {
        flex: 1;
        text-align: left;
      }
      .title-section {
        padding: 10px 0;
      }
      .publish-title {
        font-size: 18px;
        color: #333333;
        font-weight: 500;
      }
      .city-icon {
        font-size: 14px;
        color: #A4A4A4
      }
      .city {
        color: #333333;
        font-size: 14px;
      }
      .name {
        padding: 10px 0;
        font-size: 12px;
        color: #333333
      }
      .price-section {
        padding: 0 40px;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #EC2C2B;
      }
    }
    .link--info {
      padding: 20px 40px;
      font-size: 14px;
      color: #0C5BD8;
      border-left: 1px solid #ECECEC;
      cursor: pointer;
      .link {
        display: flex;
        align-items: center;
        padding: 5px 0;
        img {
          display: block;
          width: 18px;
          margin-right: 5px;
        }
      }
    }
  }
  .page-section {
    margin-bottom: 20px;
    text-align: right;
    background: #fff;
  }
  .detail-dialog {
    text-align: left;
    .title-box {
      display: flex;
      .title {
        flex: 1;
        word-wrap: break-word;
        word-break: break-all;
        line-height: 26px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .price {
        max-width: 200px;
        min-width: 90px;
        padding-left: 10px;
        line-height: 28px;
        font-size: 18px;
        font-weight: 600;
        color: #EC2C2B;
      }
    }
    .publish-person {
      line-height: 22px;
      font-size: 12px;
      color: #979797;
    }
    .city-box {
      line-height: 22px;
      font-size: 12px;
      color: #333;
      .icon-city {
        color: #A4A4A4
      }
    }
    .detail-list {
      .split-title {
        position: relative;
        width: 100%;
        padding: 5px 10px;
        line-height: 30px;
        font-weight: 500;
        color: #303133;
        &:before {
          position: absolute;
          content: ' ';
          top: 12px;
          left: 0;
          height: 16px;
          border-left: 3px solid $theme-color;
        }
      }
      .content {
        line-height: 22px;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 12px;
        color: #303133;
      }
      .desc-content {
        word-wrap: break-word;
        word-break: break-all;
        border-radius: 2px;
        border: 1px solid #E5E5EE;
        p {
          padding: 10px;
        }
        .link-box {
          padding: 10px;
          background: #F3F7FF;
          border-top: 1px solid #E5E5EE;
        }
      }
      .detail-img {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        img {
          display: block;
          width: 100%;
          //margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .down-dialog {
    text-align: left;
    .el-dialog__body {
      padding: 0 20px !important;
    }
    .code-section {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 0;
      .box {
        padding: 0 50px;
      }
      .tip {
        font-size: 16px;
        font-weight: 500;
        color: #484848;
        line-height: 26px;
      }
    }
    .info-section {
      width: 100%;
      padding: 25px 20px;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
      background: #F1F6FF;
      border-radius: 10px;
      .title {
        padding-bottom: 5px;
      }
      .step {
        padding: 5px 0;
      }
      .link {
        color: $theme_color;
        font-weight: 600;
      }
      .down-guide {
        width: 100%;
      }
    }
  }
}
</style>
