<template>
<div>
  派件数据接口
</div>
</template>

<script>
export default {
  name: 'dispatchApi'
}
</script>

<style scoped>

</style>
