<template>
  <div class="service-center">
    <div class="pages yuncang">
      <div class="yuncang-intro">
        <div class="intro-title">
          <div class="title-text" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInLeft'}">云仓服务</div>
          <div class="title-info" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInRight'}">全渠道仓储+配送的仓配一体化服务</div>
        </div>
        <div class="intro-img">
          <img src="../../assets/images/serviceCenter/chatu.jpg" alt="">
        </div>
      </div>
      <div class="yuncang-fun">
        <div class="fun-item" :class="{'active': activeFunIdx === index}" v-for="(fun, index) in centerFunList" :key="index"
        :style="{ backgroundImage: 'url(' + fun.img + ')'}" @mouseenter="onMouseEnter(index)">
          <div class="fun-title">{{fun.title}}</div>
          <div class="fun-text">{{fun.text}}</div>
        </div>
      </div>
    </div>
    <div class="pages app">
      <div class="app-phone">
        <div class="phone-icon1" v-animate-onscroll.repeat="{down: 'animate__animated animate__rotateInDownLeft'}">
          <img src="../../assets/images/serviceCenter/iphone_01.png" alt="">
        </div>
        <div class="phone-icon2" v-animate-onscroll.repeat="{down: 'animate__animated animate__rotateInUpLeft'}">
          <img src="../../assets/images/serviceCenter/iphone_02.png" alt="">
        </div>
      </div>
      <div class="app-intro">
        <div class="intro-title">易拣通App</div>
        <div class="intro-remark">多家快递公司包裹到、派、签实现扫码数据无缝回传</div>
        <div class="intro-list">
          <div class="list-item" v-for="(item ,index) in appIntroList" :key="index" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInRight'}">
            <div class="img-wrap">
              <img :src="item.img" alt="">
            </div>
            <div class="item-detail">
              <div class="detail-title">{{item.title}}</div>
              <div class="detail-text">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pages screen">
      <div class="screen-title">易拣通数据大屏</div>
      <div class="screen-intro">展示各环节数据，实现集中监控，辅助管理员统一调度</div>
      <div class="screen-fun">
        <div class="fun-desc">多网点数据接入实时监控</div>
        <div class="fun-desc">快件包裹趋势展示</div>
        <div class="fun-desc">实时扫描数据监控</div>
      </div>
      <div class="screen-img">
        <img src="../../assets/images/serviceCenter/screen_show.png" alt="" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInUp'}">
      </div>
    </div>
    <div class="pages cockpit">
      <div class="cockpit-intro">
        <div class="cockpit-title">易拣通共配系统</div>
        <div class="cockpit-remark">多家快递公司包裹到、派、签实现扫码数据无缝回传</div>
        <div class="cockpit-list">
          <div class="list-item" v-for="(item ,index) in cockpitIntroList" :key="index" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInLeft'}">
            <div class="img-wrap">
              <img :src="item.img" alt="">
            </div>
            <div class="item-detail">
              <div class="detail-title">{{item.title}}</div>
              <div class="detail-text">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cockpit-img">
        <img src="../../assets/images/serviceCenter/cockpit_show.png" alt="" v-animate-onscroll.repeat="{down: 'animate__animated animate__slideInRight'}">
      </div>
    </div>
    <div class="pages transport">
      <div class="transport-title">运力资源共享平台</div>
      <div class="transport-desc">线上管理运力资产，轻松解决异常问题</div>
      <div class="transport-fun">
        <div class="fun-item" v-for="(item, index) in transportIntroList" :key="index" :style="{ backgroundImage: 'url(' + item.img + ')'}">
          <div class="item-title">{{item.title}}</div>
          <div class="item-text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import centerFun1 from '@/assets/images/serviceCenter/center_fun1.png'
import centerFun2 from '@/assets/images/serviceCenter/center_fun2.png'
import centerFun3 from '@/assets/images/serviceCenter/center_fun3.png'
import centerFun4 from '@/assets/images/serviceCenter/center_fun4.png'
import centerFun5 from '@/assets/images/serviceCenter/center_fun5.png'
import appIcon1 from '@/assets/images/serviceCenter/app_icon1.png'
import appIcon2 from '@/assets/images/serviceCenter/app_icon2.png'
import appIcon3 from '@/assets/images/serviceCenter/app_icon3.png'
import cockpitIcon1 from '@/assets/images/serviceCenter/cockpit_icon1.png'
import cockpitIcon2 from '@/assets/images/serviceCenter/cockpit_icon2.png'
import cockpitIcon3 from '@/assets/images/serviceCenter/cockpit_icon3.png'
import cockpitIcon4 from '@/assets/images/serviceCenter/cockpit_icon4.png'
import transportIcon1 from '@/assets/images/serviceCenter/transport_icon1.png'
import transportIcon2 from '@/assets/images/serviceCenter/transport_icon2.png'
import transportIcon3 from '@/assets/images/serviceCenter/transport_icon3.png'
export default {
  name: 'serviceCenter',
  data () {
    return {
      centerFunList: [
        { title: '系统支持', text: '订单管理系统、分仓监控系统、仓储管理系统、配送管理系统、前端订单抓取工具、移动物流工具', img: centerFun1 },
        { title: '仓储管理', text: '仓管理、收货清点、商品质检、库存管理、循环盘点、货品拣选、多品包装、出仓验货、包裹发运、退货处理', img: centerFun2 },
        { title: '方案设计', text: '运营问题诊断、运营数据分析、订制解决方案、物流结构规划、运作流程设计、管理系统设定', img: centerFun3 },
        { title: '物流配送服务', text: '干线运输、区域配送、门店配送、分仓调拨、快递配送、平台入仓、智能筛单、线路优化、包裹跟踪、承运商管理', img: centerFun4 },
        { title: '增值服务', text: '仓单质押贷款、货物保险购买，包材设计集采，贴标及换包装，产品组合加工、其他定制服务', img: centerFun5 }
      ],
      activeFunIdx: 0,
      appIntroList: [
        { title: '到派签一体化', text: '对接圆通、百世、申通、韵达、中通等快递公司的系统，实现到、派、签各节点数据无缝回传。', img: appIcon1 },
        { title: '多终端适配，扫拍一步搞定', text: '手机、工业巴枪、蓝牙扫描器，适配市面主流设备，满足各种扫描场景；扫描条形码的同时，也能进行拍照上传。', img: appIcon2 },
        { title: '任务管理，多家快递问题件上报', text: '待派列表和签收列表，当天工作量一目了然，适配多家快递公司问题件类型，一键上传上报，轻松搞定问题件。', img: appIcon3 }
      ],
      cockpitIntroList: [
        { title: '基础数据管理', text: '对网点业务员和快递业务进行全面管理，同时协助业务人员更好的进行业务操作。', img: cockpitIcon1 },
        { title: '自动化分拣', text: '与知名分拣设备厂共同研发自动化分拣设备、实现大规模海量订单的自动分拣任务，分拣数据会同步回传快递系统。', img: cockpitIcon2 },
        { title: '业务数据管理', text: '各个业务节点操作数据全方位管理，清楚获悉业务情况和操作现状，做到业务情况心中有数。', img: cockpitIcon3 },
        { title: 'BI报表', text: '网点管理员可以看到每天的扫码数据与不同用户的扫描统计、可个性化定制。', img: cockpitIcon4 }
      ],
      transportIntroList: [
        { title: '派件轨迹实时监控', text: '随时了解异常派件轨迹问题，提升派件时效', img: transportIcon1 },
        { title: '运力资产轻松管理', text: '线上实现运力资产盘点与分配，避免线下管理混乱情况发生', img: transportIcon2 },
        { title: '运营数据实时查看', text: '派送、签收数据实时掌控，异常问题定位及时解决', img: transportIcon3 }
      ]
    }
  },
  methods: {
    onMouseEnter (idx) {
      this.activeFunIdx = idx
    }
  },
  mounted () {
    /* TDD 触发首屏的animate */
    window.addEventListener('load', () => {
      window.scrollTo(0, 1)
    })
  }
}
</script>

<style lang="scss" scoped>
  .pages {
    height: 900px;
    position: relative;
    &.yuncang {
      background: url(../../assets/images/serviceCenter/yuncang_bg.jpg) center top no-repeat;
      background-size: cover;
      .yuncang-intro {
        width: 960px;
        margin: 0 auto;
        padding-top: 61px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .intro-title {
          color: #fff;
          text-align: left;
          .title-text {
            font-size: 54px;
            line-height: 54px;
            font-family: 'SourceHanSansCN-Heavy, SourceHanSansCN';
            font-weight: 800;
            text-shadow: 0px 6px 17px rgba(7, 9, 95, 0.3);
          }
          .title-info {
            margin-top: 33px;
            font-size: 26px;
            line-height: 32px;
            font-family: 'PingFangSC-Thin, PingFang SC';
            font-weight: 300;
            text-shadow: 0px 6px 11px rgba(41, 7, 95, 0.55);
          }
        }
        .intro-img {
          width: 520px;
          height: 320px;
          img {
            display: block;
            width: 100%;
          }
        }
      }
      .yuncang-fun {
        display: flex;
        width: 1300px;
        margin: 0 auto;
        margin-top: 42px;
        .fun-item {
          width: 200px;
          height: 440px;
          display: flex;
          box-sizing: border-box;
          padding: 122px 0px 0px 76px;
          background-position: center top;
          background-repeat: no-repeat;
          cursor: pointer;
          transition: width .5s ease, padding-left 0.5s ease;
          overflow: hidden;
          &.active {
            width: 500px;
            padding-left: 56px;
            box-shadow: 2px -2px 15px 5px rgba(38, 22, 109, 0.4);
            .fun-text {
              visibility: visible;
            }
          }
          .fun-title {
            width: 41px;
            font-size: 34px;
            line-height: 44px;
            font-family: 'YouSheBiaoTiHei';
            color: #fff;
            text-shadow: 4px 4px 10px rgba(38, 22, 109, 0.4), 0px 5px 20px #29075F;
          }
          .fun-text {
            visibility: hidden;
            padding-left: 45px;
            flex-basis: 310px;
            flex-shrink: 0;
            font-size: 22px;
            font-family: 'PingFangSC-Regular, PingFang SC';
            font-weight: 300;
            color: #fff;
            line-height: 40px;
            text-shadow: 4px 4px 10px rgba(38, 22, 109, 0.6);
            text-align: left;
          }
        }
      }
    }
    &.app {
      background: url(../../assets/images/serviceCenter/app_bg.jpg) center top no-repeat;
      background-size: cover;
      padding-top: 108px;
      display: flex;
      justify-content: center;
      .app-phone {
        width: 558px;
        height: 606px;
        position: relative;
        margin-right: 128px;
        .phone-icon1 {
          position: absolute;
          left: 0;
          top: 98px;
          width: 345px;
          height: 508px;
          img {
            display: block;
            width: 100%;
          }
        }
        .phone-icon2 {
          position: absolute;
          right: 0;
          top: 0;
          width: 346px;
          height: 507px;
          img {
            display: block;
            width: 100%;
          }
        }
      }
      .app-intro {
        padding-top: 41px;
        width: 553px;
        word-break: break-all;
        text-align: left;
        .intro-title {
          font-size: 54px;
          font-family: 'SourceHanSansCN-Heavy, SourceHanSansCN';
          font-weight: 800;
          color: #130450;
          line-height: 54px;
        }
        .intro-remark {
          margin-top: 27px;
          font-size: 24px;
          font-family: 'PingFangSC-Thin, PingFang SC';
          font-weight: 300;
          color: #1F0974;
          line-height: 32px;
        }
        .intro-list {
          margin-top: 75px;
          .list-item {
            display: flex;
            .img-wrap {
              margin-right: 36px;
              padding-top: 11px;
              img {
                display: block;
                width: 81px;
              }
            }
            .item-detail {
              margin-bottom: 48px;
              .detail-title {
                font-size: 22px;
                font-family: 'PingFangSC-Medium, PingFang SC';
                font-weight: 600;
                color: #1F0974;
                line-height: 30px;
              }
              .detail-text {
                margin-top: 10px;
                font-size: 16px;
                font-family: 'PingFangSC-Thin, PingFang SC';
                font-weight: 300;
                color: #1F0974;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
    &.screen {
      background: url(../../assets/images/serviceCenter/screen_bg.png) center top no-repeat;
      background-size: cover;
      .screen-title {
        padding-top: 132px;
        text-align: center;
        line-height: 54px;
        font-size: 54px;
        font-family: 'SourceHanSansCN-Heavy, SourceHanSansCN';
        font-weight: 800;
        color: #FFFFFF;
        text-shadow: 0px 6px 20px rgba(57, 7, 95, 0.16);
      }
      .screen-intro {
        margin-top: 23px;
        text-align: center;
        font-size: 24px;
        font-family: 'PingFangSC-Thin, PingFang SC';
        font-weight: 300;
        color: #FFFFFF;
        line-height: 40px;
        text-shadow: 0px 6px 11px rgba(92, 7, 95, 0.62);
      }
      .screen-fun {
        margin-top: 26px;
        display: flex;
        justify-content: center;
        .fun-desc {
          width: 241px;
          margin-right: 15px;
          height: 40px;
          border-radius: 20px;
          background: #B2479B;
          font-size: 18px;
          font-family: 'PingFangSC-Medium, PingFang SC';
          font-weight: 500;
          color: #FFFFFF;
          line-height: 40px;
          text-shadow: 0px 6px 11px rgba(92, 7, 95, 0.23);
        }
      }
      .screen-img {
        text-align: center;
        margin-top: 45px;
        img {
          width: 951px;
        }
      }
    }
    &.cockpit {
      background: url(../../assets/images/serviceCenter/cockpit_bg.jpg) center top no-repeat;
      background-size: cover;
      padding-top: 119px;
      display: flex;
      justify-content: center;
      .cockpit-intro {
        width: 553px;
        word-break: break-all;
        text-align: left;
        .cockpit-title {
          font-size: 54px;
          font-family: 'SourceHanSansCN-Heavy, SourceHanSansCN';
          font-weight: 800;
          color: #130450;
          line-height: 54px;
        }
        .cockpit-remark {
          margin-top: 27px;
          font-size: 24px;
          font-family: 'PingFangSC-Thin, PingFang SC';
          font-weight: 300;
          color: #1F0974;
          line-height: 32px;
        }
        .cockpit-list {
          margin-top: 50px;
          .list-item {
            display: flex;
            .img-wrap {
              margin-right: 36px;
              padding-top: 11px;
              img {
                display: block;
                width: 81px;
              }
            }
            .item-detail {
              margin-bottom: 38px;
              .detail-title {
                font-size: 22px;
                font-family: 'PingFangSC-Medium, PingFang SC';
                font-weight: 600;
                color: #1F0974;
                line-height: 30px;
              }
              .detail-text {
                word-break: break-all;
                width: 419px;
                margin-top: 10px;
                font-size: 16px;
                font-family: 'PingFangSC-Thin, PingFang SC';
                font-weight: 300;
                color: #1F0974;
                line-height: 26px;
              }
            }
          }
        }
      }
      .cockpit-img {
        margin-left: 90px;
        width: 603px;
        img {
          display: block;
          width: 603px;
        }
      }
    }
    &.transport {
      background: url(../../assets/images/serviceCenter/transport_bg.jpg) center top no-repeat;
      background-size: cover;
      text-align: center;
      .transport-title {
        padding-top: 133px;
        line-height: 54px;
        font-size: 54px;
        font-family: 'SourceHanSansCN-Heavy, SourceHanSansCN';
        font-weight: 800;
        color: #FFFFFF;
        text-shadow: 0px 6px 20px rgba(57, 7, 95, 0.16);
      }
      .transport-desc {
        margin-top: 28px;
        font-size: 24px;
        font-family: 'PingFangSC-Thin, PingFang SC';
        font-weight: 300;
        color: #FFFFFF;
        line-height: 30px;
        text-shadow: 0px 6px 11px rgba(92, 7, 95, 0.62);
      }
      .transport-fun {
        display: flex;
        justify-content: center;
        margin-top: 113px;
        .fun-item {
          box-sizing: border-box;
          padding-top: 116px;
          width: 360px;
          height: 360px;
          background-position: center top;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          transition: all 0.5s ease;
          box-shadow: 2px 4px 20px 0 rgba(19, 16, 73, 0.4);
          cursor: pointer;
          &:hover {
            background-size: 150% 150%;
          }
          &:nth-child(2) {
            margin: 0 60px;
          }
          .item-title {
            height: 36px;
            font-size: 36px;
            font-family: 'YouSheBiaoTiHei';
            color: #FFFFFF;
            text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
          }
          .item-text {
            width: 243px;
            margin: 0 auto;
            margin-top: 28px;
            font-size: 20px;
            font-family: 'PingFangSC-Regular, PingFang SC';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 36px;
          }
        }
      }
    }
  }
</style>
