<template>
    <div class="contact">
      <div class="contact-banner">
        <img src="@/assets/images/contact/contact.png" alt="">
      </div>
      <div class="contact-content">
        <div class="content-title">联系我们</div>
        <div class="content-infor">
          <div class="infor-img">
            <img src="@/assets/images/contact/map_location.png" alt="">
          </div>
          <div class="infor-list">
            <div class="list-item" v-for="(item, index) in inforList" :key="index" :style="{ backgroundImage: 'url(' + item.img + ')'}">{{item.text}}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import contactIcon1 from '@/assets/images/contact/contact_icon1.png'
import contactIcon2 from '@/assets/images/contact/contact_icon2.png'
import contactIcon3 from '@/assets/images/contact/contact_icon3.png'
export default {
  data () {
    return {
      inforList: [
        { text: '+86-13816267646', img: contactIcon1 },
        { text: 'sunshasha@yto.net.cn', img: contactIcon2 },
        { text: '西安雁塔区软件新城软件研发基地2期C2栋', img: contactIcon3 }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .contact {
    text-align: left;
    padding-bottom: 76px;
    .contact-banner {
      height: 364px;
      img {
        max-width: 100%;
      }
    }
    .contact-content {
      width: 1200px;
      margin: 0 auto;
      margin-top: 60px;
      .content-title {
        padding-left: 60px;
        font-size: 26px;
        font-family: 'PingFangSC-Medium, PingFang SC';
        font-weight: 500;
        color: #020106;
        line-height: 37px;
        letter-spacing: 3px;
      }
      .content-infor {
        padding: 60px;
        margin-top: 42px;
        background: #F8FBFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .infor-img {
          width: 565px;
          height: 385px;
          img {
            display: block;
            width: 565px;
          }
        }
        .infor-list {
          .list-item {
            height: 44px;
            line-height: 44px;
            padding-left: 64px;
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 44px 44px;
            margin-bottom: 60px;
            font-size: 18px;
            font-family: 'PingFangSC-Regular, PingFang SC';
            font-weight: 400;
            color: #020106;
            letter-spacing: 2px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
</style>
