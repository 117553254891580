<template>
  <div id="app" class="main_app">
    <navbar v-if="isShowFooter"></navbar>
    <!-- <div class="main_body"> -->
    <router-view @footer='footer' @hideFooter="hideFooter"/>
     <footer-ele v-if="!isHideFooter"></footer-ele>
    <!-- </div> -->
  </div>
</template>

<script>
import navbar from '@/components/navbar.vue'
import footerEle from '@/components/footer.vue'

export default {
  components: {
    navbar,
    footerEle
  },
  data () {
    return {
      isShowFooter: true,
      isHideFooter: false
    }
  },
  methods: {
    footer (bool) {
      this.isShowFooter = bool
    },
    hideFooter (bool) {
      this.isHideFooter = bool
    }
  },
  mounted () {
    window.addEventListener('hashchange', () => {
      var currentPath = window.location.hash.slice(1) // 获取输入的路由
      if (this.$router.path !== currentPath) {
        this.$router.push(currentPath) // 动态跳转
      }
    }, false)
  }
}
</script>

<style lang="scss">
  @import "assets/css/base.scss";
  @import "assets/css/font.css";
@media screen and (min-width: 960px){
  #app {
    min-width: 1200px;
  }
}
#app {
  position: relative;
  /*font-family: Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
  // min-width: 1200px;
  overflow-x: hidden;
  // overflow-y: scroll;
  width: 100%;
}
.main_app {
  width: 100%;
  min-height: 100%;
  // overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
}
*{
    box-sizing: border-box;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #333333;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
ul{
  margin:0;
  padding:0;
  li{
    list-style: none;
  }
}
img{
    vertical-align: bottom;
}
.main_body {
  height: calc( 100vh - 72px );
  overflow: auto;
}
</style>
