<template>
  <div id="productService">
    <div class="product_title">
      <div class="title_img">
        <img style="width: 100%" src="../assets/images/productSevice/products_title.png"/>
      </div>
    </div>
    <div class="productContent">
      <el-tabs type="card">
        <el-tab-pane label="易拣通共配系统">
          <div class="tabs-content">
            <img src="../assets/images/productSevice/product_content.png"/>
            <div class="content-word">
              多家快递品牌人、车、货、场集成资源的快件物流综合服务系统平台，实现降本增效、数据统一管理与无缝回传、账号统一登录、一个把枪和APP完成扫描及派送。
            </div>
            <el-button @click="loadDelivery">进一步了解 ></el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="运力资源共享平台">
          <div class="tabs-content">
            <img src="../assets/images/productSevice/product_content.png"/>
            <div class="content-word">
              车辆资产线上管理、线上分配，动态地图实时监控车辆派件轨迹、派件数据、签收数据。
            </div>
            <el-button @click="loadResource">进一步了解 ></el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="易拣通数据大屏">
          <div class="tabs-content">
            <img src="../assets/images/productSevice/product_content.png"/>
            <div class="content-word">
              <p>集中监控/数据查看/直观展示/辅助管理 </p>
            </div>
            <el-button @click="loadProductData">进一步了解 ></el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'productService',
  data () {
    return {

    }
  },
  methods: {
    loadDelivery () {
      this.$router.push({
        path: '/deliverySystem'
      })
    },
    loadResource () {
      this.$router.push({
        path: '/productResource'
      })
    },
    loadProductData () {
      this.$router.push({
        path: '/productData'
      })
    }
  },
  mounted () {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  #productService {
    width: 100%;
    background-color: #ffffff;

    .product_title {
      .title_img {
        position: relative;
      }
    }

    .productContent {
      .tabs-content {
        width: 1100px;
        margin: 0 auto;
        padding: 50px 0px;
        img{
          width: 100%;
        }
        .content-word {
          text-align: left;
          padding: 30px 0px 50px 0px;
          font-size: 15px;
          font-weight: 400;
          color: #5f6464;
          line-height: 32px;
          letter-spacing: 3px;
        }
      }
    }
  }
</style>
<style rel="stylesheet/scss" lang="scss">
  #productService {
    background-color: #ffffff;
    .el-tabs__nav {
      float: none;
    }

    .el-tabs--card > .el-tabs__header {
      border: 0px;
      margin: 0px;
    }

    .el-tabs--card > .el-tabs__header .el-tabs__nav {
      border: 0px;
    }

    .el-tabs--card > .el-tabs__header .el-tabs__item {
      width: 20vw;
      height: 95px;
      border: 0px;
      line-height: 95px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 3px;
      color: rgba(2, 1, 6, 1);
    }

    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
      background-color: #FBFBFB;
      border-bottom-color: #FFF;
      color: rgba(0, 0, 0, 1);
      letter-spacing: 3px;
      font-weight: 600;
      font-size: 19px;
    }
    .el-tabs__active-bar {
      background-color: #409EFF;
    }

    .el-tabs__content {
      background-color: #FBFBFB;
    }

    .el-button {
      font-size:15px;
      font-weight: 400;
      color: rgba(18, 64, 192, 1);
      width:120px;
      height:38px;
      border-radius:24px;
      opacity:0.94;
      padding: 8px 16px;
    }
  }
</style>
